<template>
  <div id="main">
    <!-- <div class="card-header">
      <h1>Выберите интересующий вас раздел:</h1>
    </div> -->
    <CardShowNextSection :sections="siteSections" />
  </div>
</template>

<script>
import CardShowNextSection from "../components/AppCardShowNextSection.vue";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("ModuleSections", ["siteSections"]),
  },
  components: {
    CardShowNextSection,
  },
};
</script>

<style lang="scss">
#main {
  display: flex;
  flex-direction: column;
}
</style>
