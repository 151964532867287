<template>
  <button
    class="navigation-button"
    :class="{ expand: $store.state.isExpanded }"
    v-if="showNavigationButton"
    @click.prevent="goBack"
  >
    <inline-svg
      :src="require(`@/assets/arrow_back.svg`)"
      fill-opacity="1"
      color="white"
      width=" 22px"
      height="22px"
      stroke="30px"
    />
  </button>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },
  computed: {
    showNavigationButton() {
      let lastSubdirectory = this.$store.state.$routeFullPath.at(-1);
      let numberOfSubdirectories = this.$store.state.$routeFullPath.length;
      if (numberOfSubdirectories == 2 && lastSubdirectory == "") {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../theme.scss";

.navigation-button {
  position: fixed;
  top: calc($navbar-height + $dim-l + 10px);
  left: calc($sidebar-width + $dim-l + 10px);
  border: none;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  border-radius: 12px;
  border-top-left-radius: $dim-l;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  transition: all 0.25s ease, box-shadow 0.25s ease;
  box-shadow: none;
  border: none;
  z-index: 2;
  cursor: pointer;
  transform: translateY(0px);
  background-color: $primary;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  &:hover {
    box-shadow: 0 6px 10px -5px rgb(0 0 0 / 10%);
    transition: transform 0.25s ease-in-out;
    transform: translateY(-1.5px);
    background-color: $primary-hover;
  }
}
.navigation-button.expand {
  left: calc($sidebar-width-expand + $dim-l + 10px);
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
@media screen and (max-width: 1000px) {
  .navigation-button {
    display: none;
  }
}
</style>
