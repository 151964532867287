/* eslint-disable */
var assetsLocation = `diseases/позвоночник/`;
export default {
  location: "Позвоночник",
  propsForCardDoctor: {
    h: 'Болезни позвоночника',
    p: "Позвоночный столб, или позвоночник — основная часть осевого скелета человека, состоящая из 33-34 позвонков, межпозвоночных дисков, суставов, связочно-мышечного аппарата. Это стержень, уникальная эволюционно отлаженная система опоры, балансировки и амортизации, работа которой влияет на функционирование всех остальных систем организма.",
    visualContent: `${assetsLocation}Болезни позвоночника.jpg`

  },
  group: "Болезни позвоночника",
  imgPath: "spine.png",
  pointCoord: { left: "205px", top: "156px" },
  listOfDiseases: [
    {
      name: `Миофасциальные боли шеи и спины`,
      propsForCardDoctor: {
        h: 'Миофасциальные боли шеи и спины',
        p: "",
        visualContent: `${assetsLocation}Миофасциальные боли шеи и спины.jpg`

      },
      description:
        `
**Миофасциальные боли шеи и спины** (миофасциальный синдром) – патологическое состояние, которое сопровождается болевыми ощущениями в отдельных группах мышц и характеризуется возникновением мышечных уплотнений – **триггерных точек** и чувством мышечного спазма.

### Клиническая картина

*   боль в области шеи и спины при движении,
*   боль при надавливании на триггерные точки,
*   боль ноющего характера в покое
*   чувство жжения, покалывания
*   потливость, слабость,утомляемость
*   раздражительность, головокружения, ощущение нехватки воздуха
*   вынужденная асимметрия положения тела

### Лечение

Лечение подбирается врачом в зависимости от локализации и числа триггерных точек, степени патологии и наличия сопутствующих заболеваний.

#### Консервативное

##### Коррекция образа жизни

*   ограничение нагрузки на верхнюю часть тела,
*   коррекция осанки
*   организация эргономики рабочего места
*   использование корсета,
*   витаминизированная диета с ограничением поваренной соли,
*   ограничение курения и алкоголя.

##### Медикаментозное лечение

*   анальгетики,
*   противовоспалительная терапия,
*   «блокада» триггерных точек лидокаином,
*   миорелаксанты,
*   венотоники,
*   седативные средства,
*   витамины группы В.

##### Физиотерапевтические методы

*   скэнар-терапия,
*   массаж,
*   магнитотерапия
*   фонофорез с гидрокортизоном,
*   ударно-волновая терапия,
*   кинезиотерапия, ЛФК,
*   мануальная терапия,
*   постизометрическая релаксация,
*   миофасциальный релиз.

#### Оперативное

При хронических упорных выраженных болях и отсутствии эффекта от консервативной терапии показана хирургическая операция - высокочастотная денервация фасеточных суставов.
`
    },
    {
      name: `Цервикалгия`,
      propsForCardDoctor: {
        h: 'Цервикалгия',
        p: "",
        visualContent: `${assetsLocation}Цервикалгия.jpg`

      },
      description:
        `
**Цервикалгия** – патологическое состояние, которое сопровождается болями и спазмом шейных мышц.

### Клиническая картина

*   острая боль в области шеи при поворотах и наклонах головы,
*   боль при пальпации,
*   ограничение подвижности,
*   иррадиация боли в затылочную область, лопатки, плечи, руки,
*   головная боль, повышение АД, гипертермия,
*   потливость, слабость, утомляемость,
*   раздражительность, головокружения, тошнота,
*   вынужденная асимметрия положения тела.

### Лечение

Лечение подбирается врачом в зависимости от причин заболевания, степени патологии и наличия сопутствующих заболеваний.

#### Консервативное

##### Коррекция образа жизни

*   ограничение нагрузки на верхнюю часть тела,
*   коррекция осанки,
*   организация эргономики рабочего места,
*   использование бандажа на шейный отдел,
*   витаминизированная диета с ограничением поваренной соли,
*   ограничение курения и алкоголя.

##### Медикаментозное лечение

*   анальгетики,
*   противовоспалительная терапия,
*   «блокада» с глюкокортикостероидами,
*   миорелаксанты,
*   противоотечная терапия,
*   венотоники,
*   витамины группы В.
*   антидепрессанты.

##### Физиотерапевтические методы

*   скэнар-терапия,
*   массаж,
*   магнитотерапия
*   фонофорез с гидрокортизоном,
*   ударно-волновая терапия,
*   кинезиотерапия, ЛФК,
*   мануальная терапия.

#### Оперативное

Хирургическое лечение показано при хронических заболеваниях позвоночного столба, приводящих к избыточному давлению на нервные корешки и образованию грыж. В этих случаях хирурги проводят фораминотомию и ламинэктомию. Дегенеративные поражения позвоночника лечатся посредством спондилодеза.
`
    },
    {
      name: `Остеохондроз`,
      propsForCardDoctor: {
        h: 'Остеохондроз',
        p: "",
        visualContent: `${assetsLocation}Остеохондроз.jpg`

      },
      description:
        `
**Остеохондроз** – это сложное полиэтиологичное заболевание, относящееся к дегенеративным поражениям позвоночника, в основе которого лежит дегенерация диска с последующим вовлечением в процесс тел смежных позвонков, а также изменения в межпозвонковых суставах и связочном аппарате.

### Клиническая картина

#### общие симптомы:

*   боль,
*   ограничение движения,
*   онемение в конечностях.

при компрессионных формах (выраженность болевого синдрома и неврологического дефицита зависит от степени и длительности компрессии)  
боль,

*   онемение,мышечная слабость в зоне иннервируемой спинномозговым корешком,
*   иногда скованность движений,
*   нарушение функций тазовых органов.

  
при некомпрессионных формах:

*   локальный болевой синдром,
*   рефлекторный болевой синдром,
*   ангиодистонический синдром,
*   висцеральный синдром и др.

### Лечение

Лечение подбирается врачом в зависимости от причин заболевания, степени патологии и наличия сопутствующих заболеваний. Является частью комплексной терапии той патологии позвоночника, которая вызвала соответствующую симптоматику и заключается в устранении болевого синдрома и нарушений функции спинномозговых корешков, снятия рефлекторного мышечного спазма, предупреждения прогресса дистрофических изменений в структурах пораженного отдела позвоночника. Немедикаментозная терапия является обязательным условием достижения стойкой ремиссии заболевания.

#### Консервативное

##### Коррекция образа жизни

*   ограничение нагрузки на позвоночник, иногда постельный режим,
*   исключение тепловых процедур в период обострения,
*   соблюдение специального ортопедического режима, направленного на разгрузку пораженного отдела позвоночника,
*   коррекция осанки, организация эргономики рабочего места,
*   использование специального корсета,
*   коррекция питания (нормализация ИМТ, ограничение продуктов, усиливающих отеки, снижение соли),
*   ограничение курения и алкоголя.

##### Медикаментозное лечение

*   анальгетики,
*   противовоспалительная терапия,
*   паравертебральная «блокада» с глюкокортикостероидами,
*   миорелаксанты,
*   противоотечная терапия,
*   венотоники,
*   витамины группы В,
*   хондропротекторы,
*   антидепрессанты.

##### Физиотерапевтические методы

*   скэнар-терапия,
*   массаж,
*   магнитотерапия,
*   фонофорез с гидрокортизоном,
*   ударно-волновая терапия,
*   кинезиотерапия, ЛФК,
*   мануальная терапия.

##### Ортобиологические методы

*   PRP-терапия.

#### Оперативное

Хирургическое лечение показано при хронических заболеваниях позвоночного столба, приводящих к избыточному давлению на нервные корешки и образованию грыж. В этих случаях хирурги проводят фораминотомию и ламинэктомию. Дегенеративные поражения позвоночника лечатся посредством спондилодеза.
`
    },
    {
      name: `Сколиоз`,
      propsForCardDoctor: {
        h: 'Сколиоз',
        p: "",
        visualContent: `${assetsLocation}Сколиоз.jpg`

      },
      description:
        `
**Сколиоз (сколиотическая болезнь)** — искривление позвоночника 3-х плоскостях (во фронтальной, сагиттальной и горизонтальной) с обязательной ротацией тел позвонков (торсией), характерной особенностью которого является прогрессирование деформации, связанное с возрастом и ростом ребенка. Вследствие деформации грудной клетки нарушается нормальное взаиморасположение внутренних органов. При сколиозе нарушается работа несколько систем организма (опорно-двигательная, сердечнососудистая, половая, дыхательная, желудочно-кишечная и нервная).

### Клиническая картина

*   визуально определяемое искривление оси позвоночника (особенно в наклоне),
*   боли в спине,
*   быстрая утомляемость,
*   скованность движений, особенно после долгого пребывания в положении сидя,
*   миалгии,
*   нарушение работы внутренних органов (затруднение дыхания, пищеварения и т.д.).

Лечение сколиоза заключается в постоянной коррекции изменений в структурах позвоночника, укреплении мышечного корсета, стимуляции ростковых зон и складывается из немедикаментозного, медикаментозного и хирургического.

#### Консервативное

##### Коррекция образа жизни

*   ограничение нагрузки на позвоночник, иногда постельный режим,
*   соблюдение специального ортопедического режима, направленного на разгрузку пораженного отдела позвоночника,
*   коррекция осанки, организация эргономики рабочего места,
*   гипсовые кроватки, использование специального корсета (типа Шено),
*   сбалансированное питание.

##### Медикаментозное лечение

*   препараты кальция,
*   витаминотерапия,
*   миорелаксанты,
*   противоотечная терапия, венотоники,
*   корректоры метаболизма хрящевой ткани.

##### Физиотерапевтические методы

*   массаж,
*   скэнар-терапия,
*   магнитотерапия,
*   фонофорез с гидрокортизоном,
*   ударно-волновая терапия,
*   кинезиотерапия, антисколиозная ЛФК,
*   мануальная терапия,
*   термотерапия (парафинолечение, озекерит),
*   душ Шарко.

Санаторно-курортное лечение

*   пелоидотерапия - грязевые ванны оз. Тамбукан, г. Саки, Кавминводы, Старая Русса,
*   сероводородные ванны,
*   радоновые ванны.

#### Оперативное

Операция (коррекция и хирургическая фиксация позвоночника) показана при кифосколиозах III и IV степени, а также при сколиозе II степени в случае неэффективной длительной консервативной терапии на фоне прогрессирования деформации.
    `
    },
    {
      name: `Люмбоишиалгия`,
      propsForCardDoctor: {
        h: 'Люмбоишиалгия',
        p: "",
        visualContent: `${assetsLocation}Люмбоишиалгия.jpg`

      },
      description:
        `
**Люмбоишиалгия (поясничный радикулит)** – острый приступ боли в пояснице и по задней поверхности бедра, возникающий, как правило, после резкого наклона или поворота на фоне продолжительного сидения в неправильной или неудобной позе, а так же при подъеме тяжестей.

### Клиническая картина

*   острая боль в области поясницы,
*   иррадиация боли в нижнюю конечность,
*   боли приступообразные, обычно жгучие, колющие, стреляющие.
*   ограничение подвижности,
*   головная боль, повышение АД, гипертермия,
*   потливость, слабость, утомляемость,
*   раздражительность, головокружения, тошнота,
*   вынужденная асимметрия положения тела.

### Лечение

Лечение подбирается врачом в зависимости от причин заболевания, степени патологии и наличия сопутствующих заболеваний. Является частью комплексной терапии той патологии позвоночника, которая вызвала соответствующую симптоматику и заключается в устранении болевого синдрома и нарушений функции спинномозговых корешков, снятия рефлекторного мышечного спазма, предупреждения прогресса дистрофических изменений в структурах поясничного отдела позвоночника. Немедикаментозная терапия является обязательным условием достижения стойкой ремиссии заболевания.

#### Консервативное

##### Коррекция образа жизни

*   ограничение нагрузки на позвоночник, иногда постельный режим,
*   исключение тепловых процедур в период обострения,
*   соблюдение специального ортопедического режима, направленного на разгрузку пояснично-крестцового отдела позвоночника,
*   коррекция осанки, организация эргономики рабочего места,
*   использование корсета на поясничный отдел,
*   коррекция питания (нормализация ИМТ, ограничение продуктов, усиливающих отеки, снижение соли),
*   ограничение курения и алкоголя.

##### Медикаментозное лечение

*   анальгетики,
*   противовоспалительная терапия,
*   паравертебральная «блокада» с глюкокортикостероидами,
*   миорелаксанты,
*   противоотечная терапия,
*   венотоники,
*   витамины группы В,
*   антидепрессанты.

##### Физиотерапевтические методы

*   скэнар-терапия,
*   массаж,
*   магнитотерапия,
*   фонофорез с гидрокортизоном,
*   ударно-волновая терапия,
*   кинезиотерапия, ЛФК,
*   мануальная терапия.

#### Оперативное

Хирургическое лечение показано при хронических заболеваниях позвоночного столба, приводящих к избыточному давлению на нервные корешки и образованию грыж. В этих случаях хирурги проводят фораминотомию и ламинэктомию. Дегенеративные поражения позвоночника лечатся посредством спондилодеза.
    `
    },
    //     {
    //       name: ``,
    //       propsForCardDoctor: {
    //         h: '',
    //         p: "",
    // visualContent:`${assetsLocation}.jpg`
    //       },
    //       description:
    //         `
    // `
    //     },
  ]
};