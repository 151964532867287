<template>
  <div class="expand-button" title="Увеличить размер" @click="expand = true">
    <inline-svg
      :src="require(`@/assets/fullscreen.svg`)"
      fill-opacity="1"
      opacity="0.8"
      color="#000"
      width=" 24"
      height="24"
      stroke="30px"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "../theme.scss";
.expand-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 48px;
  height: 48px;
  background-color: white;
  transform: translate(2px, -2px) scale(1);
  border-top-right-radius: $rad-m;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 30%);
  z-index: 2;
  transition: 0.3s ease;
  &:hover svg {
    transition: 0.3s ease;
    transform: scale(1.1);
    opacity: 1;
  }
}
</style>
