/* eslint-disable */
var assetsLocation = `diseases/локоть/`;
export default {
  location: "Локоть",
  group: "Болезни локтевого сустава",
  imgPath: "arm.png",
  pointCoord: { left: "320px", top: "215px" },
  propsForCardDoctor: {
    h: 'Болезни локтевого сустава',
    p: "Локтевой сустав – один из самых сложных суставов человека. В нем сочленяются три кости: дистальный конец плечевой кости, и проксимальные концы локтевой и лучевой костей, образуя три сустава, заключенные в общую капсулу: плечелоктевой, плечелучевой и проксимальный лучелоктевой. Такие особенности позволяют выполнять 4 вида движений: сгибание и разгибание, пронацию и супинацию, делая сустав важным узлом функциональной активности верхней конечности.  ",
    visualContent: `${assetsLocation}Болезни локтевого сустава.jpg`

  },
  listOfDiseases: [
    {
      name: `Травмы локтевого сустава`,
      propsForCardDoctor: {
        h: `Травмы локтевого сустава `,
        p: "",
        visualContent: `${assetsLocation}Травмы локтевого сустава.jpg`
      },
      description:
        `
**Травмы локтевого сустава -** в основном происходят в результате удара или падения на область сустава. Локтевой сустав представляет собой уникальное соединение трех костей: плечевой, лучевой и локтевой. Это сложный комбинированный сустав, в состав которого входят три простых сустава: плечелоктевой, плечелучевой, проксимальный лучелоктевой. Все они объединены одной общей капсулой и суставной сумкой (полостью). Из-за своих анатомических и биомеханических особенностей локтевой сустав часто подвергается травмированию.

### Виды травм

*   ушибы локтевого отростка, околосуставных тканей, мыщелков плеча и локтевого нерва
*   растяжения связочного аппарата
*   вывихи:

*   изолированный вывих и пронационный подвывих головки лучевой кости
*   вывихи предплечья кзади, кпереди, кнутри, кнаружи
*   дивергирующие вывихи с разрывом проксимального сочленения и расхождением костей предплечья в стороны

*   переломы костей локтевого сустава:

*   внутрисуставные
*   околосуставные
*   закрытые
*   открытые
*   без смещения
*   со смещением отломков

При травмировании локтевого сустава в ближайшее время необходимо показаться врачу травматологу-ортопеду или хирургу, чтобы точно определить степень повреждения и необходимый объем и срочность медицинского вмешательства. 

### Клиническая картина

*   пронзительная боль в момент получения травмы
*   отечность и гематома в области локтевого сустава
*   деформация локтя
*   ограничения в движениях рукой,
*   патологическая подвижность и возможность нетипичных для локтя движений
*   онемение или покалывание в районе предплечья, запястья, кисти руки
*   скрип, хруст или щелчок при движении локтем
*   изменение цвета кожа в области повреждения
*   ощутимое выступание отломков кости под поверхностью кожи

### Лечение

Лечение подбирается врачом в зависимости от характера и вида травмы, степени повреждения и наличия сопутствующих заболеваний.

#### Консервативное

##### Коррекция образа жизни

*   максимальное ограничение нагрузки на сустав
*   использование бандажа или ортеза для сустава
*   при переломе – иммобилизация, гипс
*   витаминизированная диета с ограничением поваренной соли
*   ограничение курения и алкоголя

##### Медикаментозное лечение

*   анальгетики при болях
*   местнораздражающие средства
*   противовоспалительная терапия
*   противоотечная терапия
*   «блокады» с глюкокортикостероидами
*   препараты, укрепляющие суставной хрящ
*   витаминотерапия.

##### Физиотерапевтические методы

*   криотерапия
*   СКЭНАР-терапия,
*   фонофорез с гидрокортизоном,
*   массаж
*   МЛТ
*   ударно-волновая терапия
*   кинезиотерапия, ЛФК

##### Ортобиологические методы

*   PRP-терапия
*   SVF-терапия

#### Оперативное

При наличии обширного повреждения мягких тканей, разрыва кровеносных сосудов и кровоизлияния в полость локтевого сустава, некроза поврежденных тканей и позднего обращения к врачу, может потребоваться пункция сустава, в более запущенных случаях – артроскопия или серъезное хирургическое вмешательство.

При несвоевременном обращении к врачу и слишком поздно начатом лечении после травмы может развиться гемартроз, бурсит, артроз, не поддающийся терапии.

При переломах со смещением необходимо хирургическое вмешательство: репозиция (открытая или закрытая), фиксация отломков, комплекс реабилитационных мероприятий.
`
    },
    {
      name: `Эпикондилит`,
      propsForCardDoctor: {
        h: `Эпикондилит`,
        p: "",
        visualContent: `${assetsLocation}Эпикондилит.png`
      },
      description:
        `
**Эпикондилит** - это дегенеративно-воспалительное поражение сухожилий в области их прикрепления к наружному (латеральный эпикондилит или «локоть теннисиста») или внутреннему (медиальный эпикондилит или «локоть гольфиста») мыщелкам плечевой кости. Причиной развития является перегрузка мышц предплечья и кисти.

### Клиническая картина

*   боль в области мыщелка при движении, при надавливании, иногда в покое
*   отек в области сустава заметен по сравнению со здоровым локтем
*   затруднение при движении отведения, приведения, сгибания

### Лечение

Лечение подбирается врачом в зависимости от стадии заболевания и наличия сопутствующих заболеваний.

#### Консервативное

##### Коррекция образа жизни

*   ограничение нагрузки на сустав,
*   использование бандажа или ортеза для сустава,
*   витаминизированная диета с ограничением поваренной соли,
*   ограничение курения и алкоголя

##### Медикаментозное лечение

*   анальгетики при болях,
*   местные согревающие средства
*   противовоспалительная терапия
*   противоотечная терапия,
*   «блокады» с глюкокортикостероидами
*   препараты, укрепляющие суставной хрящ
*   витаминотерапия.

##### Физиотерапевтические методы

*   СКЭНАР-терапия,
*   фонофорез с гидрокортизоном,
*   массаж
*   МЛТ
*   ударно-волновая терапия
*   кинезиотерапия, ЛФК

##### Ортобиологические методы

*   PRP-терапия
*   SVF-терапия

#### Оперативное

Показанием к оперативному вмешательству служит отсутствие положительной динамики в течение 6 месяцев консервативного лечения. Проводят несколько видов операций:

*   тенолиз - иссечение рубцовой ткани сухожилий и восстановления их свободного хода
*   остеоперфорация кости в области надмыщелка, способствует миграции клеток костного мозга в очаг воспаления и уменьшению симптомов заболевания
*   тенопластика - удлинение сухожилий разгибателей при выраженном спаечном процессе и невозможности восстановления работоспособности мышц предплечья
*   артроскопия - для очищения ткани сухожилия от участков фиброза и спаек

При несвоевременном обращении к врачу и слишком поздно начатом лечении может развиться бурсит, артроз, не поддающиеся терапии.
`
    },
    {
      name: `Локтевой бурсит`,
      propsForCardDoctor: {
        h: `Локтевой бурсит`,
        p: "",
        visualContent: `${assetsLocation}Локтевой бурсит.jpg`
      },
      description:
        `
**Локтевой бурсит** - воспаление подкожной сумки (бурсы) локтевого отростка. В области локтевого сустава есть несколько сумок: подкожная - в области локтевого отростка (олекранона); внутрисухожильная - находится в толще сухожилия трехглавой мышцы плеча; подсухожильная - расположена между сухожилием трицепса и капсулой сустава. В норме слизистая сумка содержит небольшое количество “смазки”- синовиальной жидкости, выполняет роль защитного амортизатора, а также необходима для скольжения мягких тканей и кожи вдоль кости, во время сгибания и разгибания сустава. Травмы, инфекция или перегрузки вызывают воспалительный процесс, что ведет к увеличению секреции жидкости внутри бурсы и отеку окружающих тканей.

### Клиническая картина

*   ноющая боль в области локтя усиливается при движении, надавливании
*   отек, припухлость в области сустава по сравнению со здоровым локтем
*   гиперемия, покраснение кожи, особенно при гнойном бурсите
*   затруднение при движении отведения, приведения, сгибания

### Лечение

Лечение подбирается врачом в зависимости от стадии заболевания и наличия сопутствующих патологий.

#### Консервативное

##### Коррекция образа жизни

*   ограничение нагрузки на сустав
*   использование бандажа или ортеза для сустава
*   витаминизированная диета с ограничением поваренной соли
*   ограничение курения и алкоголя

##### Медикаментозное лечение

*   анальгетики при болях
*   местные согревающие средства
*   НПВС
*   лечебные «блокады»
*   противоотечная терапия, венотоники
*   препараты, укрепляющие суставной хрящ
*   витаминотерапия.

##### Физиотерапевтические методы

*   СКЭНАР-терапия
*   фонофорез с гидрокортизоном
*   массаж
*   МЛТ
*   ударно-волновая терапия
*   кинезиотерапия, ЛФК

##### Ортобиологические методы

*   PRP-терапия
*   SVF-терапия

#### Оперативное

При наличии гнойных очагов в синовиальной сумке проводится дренирование – локтевой сустав вскрывается с помощью небольшого разреза, через который выделяется гнойное сожержимое, капсула сустава промывается антибиотиками, проводится активный дренаж. При необходимости проводится бурсэктомия и ушивание раны с дренажем.
`
    },
    //     {
    //       name: ``,
    //       propsForCardDoctor: {
    //         h: ``,
    //         p: "I am a text",
    // visualContent:`${assetsLocation}`
    //       },
    //       description:
    // `
    // `
    //     },

  ],
};