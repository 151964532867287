/* eslint-disable */
var assetsLocation = `diseases/плечо/`;

export default {
  location: "Плечо",
  propsForCardDoctor: {
    h: 'Болезни плечевого сустава',
    p: "Плечевой сустав является простым, наиболее свободным суставом организма, что обеспечивает верхнюю конечность наибольшей свободой движения, необходимой для функции руки как органа труда. Эта же особенность делает плечевой сустав наименее стабильным и подверженным вывихам, растяжениям связок, нарушению его целостности и работоспособности.",
    visualContent: `${assetsLocation}Болезни плечевого сустава.jpg`
  },
  group: "Болезни плечевого сустава",
  imgPath: "shoulder.png",
  pointCoord: { left: "100px", top: "90px" },
  listOfDiseases: [
    {
      name: `Травмы, повреждения сухожильно-капсульного аппарата`,
      propsForCardDoctor: {
        h: 'Травмы, повреждения ',
        p: "",
        visualContent: `${assetsLocation}Травмы, повреждения сухожильно-капсульного аппарата.jpg`
      },
      description:
        `
**К повреждениям капсульно-связочного аппарата суставов относят** растяжения, частичные и полные разрывы.

Падение или удар могут стать причиной повреждения сухожилий и мягких тканей.

Как правило, травмируется не одна структура. В большинстве случаев у пациентов диагностируются растяжения или разрывы сухожилий, синовиальной сумки, суставной капсулы и мышц, окружающих плечевой сустав. Кроме того, травма может повлечь за собой повреждения костных структур. Перелом головки плечевой кости или разрыв акромиально-ключичного сочленения может иметь длительные последствия и вызвать артроз плечевого сустава.

### Клиническая картина:

*   Боль в области сустава, усиливающаяся при движении
*   Область сустава увеличена в объёме (кровоизлияние, отёк)
*   Нарушение функции.

### Лечение

#### Консервативное

*   Покой — кратковременная иммобилизация гипсовой лонгетой или мягкой фиксирующей повязкой.
*   Холод — в первые сутки, затем УВЧ, фонофорез с гидрокортизоном, ЛФК, массаж.
*   Обезболивающие «блокады» для купирования болевого синдрома (новокаин, лидокаин, ГКС).
*   При повреждениях связок суставов с нарушением их целости — иммобилизация гипсовой лонгетой в положении гиперкоррекции на 4–6 нед.

#### Оперативное:

*   Часто при неэффективности консервативного лечения — хирургическое восстановление целостности связок.

Очень важно как можно быстрее после травмы обратиться к специалисту, чтобы правильно оценить тяжесть повреждения и сразу начать лечение.
`
    },
    {
      name: `Остеоартроз плечевого сустава`,
      propsForCardDoctor: {
        h: 'Остеоартроз плечевого сустава',
        p: "",
        visualContent: `${assetsLocation}Остеоартроз плечевого сустава.jpg`
      },
      description:
        `
**Плечелопаточный периартрит** (адгезивный капсулит, синдром «замороженного плача») — заболевание, характеризующееся дегенеративно-воспалительным поражением околосуставных мягких тканей (мышц, связок, сухожилий и синовиальных сумок). 
### Клиническая картина: 
- Острая или ноющая боль в области плеча и лопатки, усиливающаяся ночью. 
- Затруднение движений верхней конечностью — отведение руки назад, поднятие ее вверх, движение в сторону. 
- На стадии острого воспаления местно отмечается небольшая отечность, повышение температуры и резкая боль. 
### Лечение:
#### Консервативное
Методы лечения включают в себя местные обезболивающие инъекции анальгетиков, глюкокортикостероидов, физиотерапию (УВТ, фонофорез, скэнар-трапия и т.д), лечебну. гимнастику. Акцент делается на пассивном растяжении в соответствии с индивидуальной программой упражнений в домашних условиях.
#### Оперативное
Если консервативные методы неэффективны, особенно когда диапазон подвижности ограничивается до <90˚ при фронтальном поднятии конечности или до 0˚ при наружной ротации, помимо 3-6 месяцев физиотерапии, может потребоваться артроскопическая чистка, проводимая под анестезией.
`
    },
    {
      name: `Плечелопаточный периартрит`,
      propsForCardDoctor: {
        h: 'Плечелопаточный периартрит',
        p: "",
        visualContent: `${assetsLocation}Плечелопаточный периартрит.jpg`
      },
      description:
        `
**Плечелопаточный периартрит** (адгезивный капсулит, синдром «замороженного плача») — заболевание, характеризующееся дегенеративно-воспалительным поражением околосуставных мягких тканей (мышц, связок, сухожилий и синовиальных сумок).

### Клиническая картина:

*   Острая или ноющая боль в области плеча и лопатки, усиливающуюся ночью.
*   Затруднение движений верхней конечностью — отведение руки назад, поднятие ее вверх, движение в сторону.
*   На стадии острого воспаления местно отмечается небольшая отечность, повышение температуры и резкая боль.

### Лечение:

#### Консервативное:

Методы лечения включают в себя местные обезболивающие инъекции анальгетиков, глюкокортикостероидов, физиотерапию (УВТ, фонофорез, скэнар-трапия и т.д), лечебная гимнастика. Акцент делается на пассивном растяжении в соответствии с индивидуальной программой упражнений в домашних условиях.

#### Оперативное:

Если консервативные методы неэффективны, особенно когда диапазон подвижности ограничивается до <90˚ при фронтальном поднятии конечности или до 0˚ при наружной ротации, помимо 3-6 месяцев физиотерапии, может потребоваться артроскопическая чистка, проводимая под анестезией.
`
    },
        {
          name: `Оперативное лечение Солитарной костной кисты.`,
          operation: true,
          propsForCardDoctor: {
            h: 'Оперативное лечение солитарной костной кисты.',
            p: "Солитарная костная киста — одна из наиболее часто встречающихся патологий скелета детского возраста и составляет, по данным разных авторов, от 21 до 57 % от всех доброкачественных опухолей и опухолеподобных процессов костей у детей.",
            visualContent:`${assetsLocation}solitarnoy_kostnoy_kisty0.png`
          },
          description:
    `
Солитарная костная киста — одна из наиболее часто встречающихся патологий скелета детского возраста и составляет, по данным разных авторов, от 21 до 57 % от всех доброкачественных опухолей и опухолеподобных процессов костей у детей. Солитарные костные кисты чаще встречаются у лиц мужского пола, чем женского (в соотношении 3: 1). В среднем в 65 % случаев заболевание диагностируют в подростковом возрасте и еще в 20 % — в первом десятилетии жизни. Подавляющее большинство патологических очагов локализуется в проксимальных метафизах плечевой и бедренной костей. Клинические исследования показали, что солитарные костные кисты в большинстве своем не проявляются в виде каких-нибудь симптомов, и в 75 % случаев обнаруживаются при патологическом переломе*. 

На сегодняшний день при лечении детей с солитарной костной кистой превалируют два основных направления: консервативное и хирургическое. 

#### *Консервативное лечение*
К консервативным методам лечения относятся: 
* пункционный метод без/с введением различных препаратов, ускоряющих репарацию костной ткани в патологическом очаге; 
* инъекции костного мозга; 
* инъекции стероидных препаратов; 
* инъекции деминерализованной аллокости
* использование этих методов по отдельности и в сочетании. 

Эффективность консервативного лечения остается под вопросом, так как есть высокий риск рецидива.

#### *Хирургическое лечение*
К хирургическим методам лечения можно отнести: 
* выскабливание содержимого кисты, 
* различные варианты резекций кости (краевая, сегментарная, субпериостальная/тотальная резекции) с пластикой дефекта костными ауто- или аллотрансплантатами;
* армирование полости кисты гибкими интрамедуллярными стержнями без/с заполнением полости аллотрансплантатами; 
* декомпрессия полости кисты канюлированными винтами.

В настоящее время нет однозначного решения в лечении данной патологии, выбор способа зависит от разных факторов. Мы предпочитаем радикально решить проблему посредством одномоментного криохирургического вмешательства с костной пластикой. 

#### Клинический случай.
Пациент Г. 12 лет, обратился с жалобами на боли в области правой плечевой кости и нарушение функции правой руки, которые появилось после травмы во время прыжков на батуте. 

После осмотра и рентгенографии поставлен диагноз: патологический перелом в/3  правой плечевой кости  на фоне солитарной кисты в-с/3  правой плечевой кости. 

![img](/solitarnoy_kostnoy_kisty1.jpeg)
Рентгенография до операции.

До обращения в нашу клинику пациент лечился консервативно. Проводилась иммобилизация гипсовой лонгетой. Оценив ситуацию, мы предложили оперативное лечение. 

#### *Оперативное лечение*

Этапы операции: 
* внутриочаговая  резекция проксимального метадиафиза правой плечевой кости,
* адъювантная криодеструкция, 
* костная аллопластика.

**Внутриочаговая резекция** выполнена посредством переднего доступа к патологическому очагу в верхней трети правого плеча. Выделен участок измененной, булавовидно вздутой кости. Обнаружено, что кортикальный слой истончен, линия перелома в области кисты с формирующийся костной мозолью. Цвет кости синюшный, диафиз баллонообразно расширен.

При помощи остеотома удалена кортикальная пластинка в области измененного  кортикального слоя. Обнаружена патологическая полость, заполненная кровью. После удаления крови: стенки костной полости склерозированные с гребнями из кости. Оболочки кисты рыхлые, бурого цвета. Патологический срастающийся перелом на уровне кисты, минимальное смещение отломков. Удаленные костные ткани и оболочка отправлены отдельными препаратами на гистологическое исследование. Обработка сферической электрической фрезой. Размер резекционного дефекта: 9 см х 1,5 см х 2,5 см. 

**Адъювантная криодеструкция** проводилась посредством инстилляции жидкого азота при помощи «Криоаппликатора медицинского Криоиней». Проведена тремя циклами последовательного замораживания по 1 мин. с принудительным оттаиванием по 2 мин между циклами. Во время оттаивания в костную полость и операционную рану заливали водный раствор хлоргексидина комнатной температуры.

**Пластика резекционного дефекта** плечевой кости проводилась кортикальными аллотрансплантатами в виде «штабелей». Обработка антисептиками. Гемостаз. Послойные швы. Асептическая повязка, иммобилизация гипсовой лонгетой по Турнеру. Макропрепараты отправлены на гистологическое исследование. 

![img](/solitarnoy_kostnoy_kisty2.jpeg)
Рентгенография через 2 дня после операции.

Послеоперационный период протекал без осложнений. Получал: перевязки, анальгетики, антибактериальную терапию, иммобилизацию гипсовой лонгетой по Турнеру. 

![img](/solitarnoy_kostnoy_kisty3.jpeg)
Через 6 недель после операции

![img](/solitarnoy_kostnoy_kisty4.jpeg)
Через 6 месяцев после операции.

При рентгенологическом исследовании через 3, 6, после операции данных за рецидив опухоли нет. Наблюдается перестройка аллотрансплантата.

*Поздеев Александр Павлович, Белоусова Екатерина Анатольевна Солитарные костные кисты у детей // Ортопедия, травматология и восстановительная хирургия детского возраста. 2017. №2.   
`
        },
    //     {
    //       name: ``,
    //       propsForCardDoctor: {
    //         h: '',
    //         p: "",
    //         visualContent:`${assetsLocation}.jpg`
    //       },
    //       description:
    // `

    // `
    //     },
  ]
};