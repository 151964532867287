<template>
  <ListOfDiseases v-if="getTheDiseaseGroup && !showAboutDisease" />
  <AboutDiseases v-else-if="showAboutDisease" />
  <HumanBody v-else />
</template>

<script>
import HumanBody from "../components/DISEASES_humanBody.vue";
import ListOfDiseases from "../components/DISEASES_listOfDiseases.vue";
import AboutDiseases from "../components/DISEASES_aboutDisease.vue";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("ModuleDiseases", ["getTheDiseaseGroup"]),
    ...mapGetters("ModuleDiseases", ["showAboutDisease"]),
  },
  components: {
    HumanBody,
    ListOfDiseases,
    AboutDiseases,
  },
};
</script>

<style scoped></style>
