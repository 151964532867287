<template>
  <TheNavbar />
  <main>
    <the-side-bar :doctorCardContent="getDoctorCardContent"></the-side-bar>
    <article :class="{ expand: $store.state.isExpanded }">
      <app-navigation-button></app-navigation-button>
      <router-view></router-view>
    </article>
  </main>
</template>

<script>
import TheNavbar from "./components/TheNavbar.vue";
import TheSideBar from "./components/TheSideBar.vue";
import AppNavigationButton from "./components/AppButtonNavigation.vue";
import { mapGetters } from "vuex";

export default {
  components: { TheNavbar, TheSideBar, AppNavigationButton },
  computed: {
    ...mapGetters("ModuleDoctorCard", ["getDoctorCardContent"]),
  },
};
</script>

<style lang="scss">
@import "./theme.scss";

main {
  position: relative;
  width: 100%;
  height: calc(100vh - $navbar-height);
  margin-top: $navbar-height;
  display: flex;
  max-width: 1500px !important;
  margin-left: auto;
  margin-right: auto;
}

article {
  width: $article-width;
  margin-left: auto;
  right: 0;
  padding: $dim-m;
  padding-top: $dim-l;
  position: relative;
  min-height: 100%;
  height: fit-content;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 2000px;
  background: white;

  img {
    width: 100%;
  }
}

article.expand {
  width: calc(100% - $sidebar-width-expand);
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

// - Mobile
@media screen and (max-width: 1000px) {
  html {
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
  }

  main {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: $navbar-height-mobile;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background-color: $bgc-bright; //$The color should be the same as in the SideBar
  }

  article {
    width: 100%;
    position: relative;
    height: auto;
    display: flex;
    flex-direction: column;
  }

  article {
    margin: 0;
    position: relative;
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 2000px;
    padding: $dim-m;
    margin-top: $dim-m;
    padding-top: $dim-xl;
    border-top-right-radius: $rad-xl;
    border-top-left-radius: $rad-xl;
  }

  article.expand {
    width: 100%;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&family=Signika:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800&display=swap");

main {
  font-family: "Inter", sans-serif;

  strong,
  a,
  span,
  input,
  label,
  textarea,
  p,
  li,
  .fs-s {
    color: $paragraph;
    font-size: 19px;
    font-weight: 300;
    line-height: 1.3;
  }

  strong {
    font-weight: 700;
  }

  small,
  label {
    font-weight: 400;
    font-size: medium;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $header;
  }

  h3 {
    font-size: x-larger;
    margin-top: $dim-m;
    margin-bottom: $dim-s;
  }

  h4,
  .fs-m {
    font-size: larger;
    line-height: 1.6;
    margin-top: $dim-m;
    margin-bottom: $dim-xs;
  }

  h5 {
    font-size: large;
  }

  h2,
  h1 {
    font-size: xx-large;
    margin-bottom: $dim-m;
  }
}
</style>
