<template>
  <div class="cards-container">
    <a v-for="card in sections" :key="card" class="card" @mouseleave="runAnimation(card)" href="#"
      @click.prevent="$router.push(`/${card.sectionPath}`)">
      <img class="img-card_show_next_section" :src="require(`@/assets/${card.imageForCardOnMenu}`)"
        :alt="card.altForCardOnMenu" />
      <h3>{{ card.sectionName }}</h3>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    sections: [],
  },
  methods: {
    runAnimation(id) {
      id.mouseleave = true;
      setTimeout(() => {
        id.mouseleave = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../theme.scss";

.img-card_show_next_section {
  width: auto !important;
}

a {
  text-decoration: none;
  position: relative;
  overflow: hidden;
  border-bottom: none;
  line-height: 48px;

  &:hover {
    text-decoration: none;
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1000px) {
  .cards-container {
    width: 100%;
    max-width: calc((470px * 2) + ($dim-l));
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    img {
      margin-top: $m-s;
      height: 65%;
      object-fit: cover;

      object-fit: cover;
      object-position: 50% 50%;
      -webkit-transition: all 0.25s ease;
      transition: all 0.25s ease;
      border-radius: $rad-m;
    }
  }

  .card {
    flex: 0 1 50%;
    flex: 1;
    height: 230px;
    min-width: 300px;
    width: 100%;
    max-width: 100%;
    margin: $dim-m 0;
    text-align: center;
    margin: $dim-m;
    border-radius: $rad-m;
    background-color: $bgc-bright;
    transition: background 200ms ease 0s, box-shadow 200ms ease 0s;

    &:hover {
      box-shadow: $shadow-s;
      background-color: $bgc-complementary;
      transition: background 200ms ease 0s, box-shadow 200ms ease 0s;
    }
  }

  .card {
    img {
      margin: 0;
      top: $dim-s;
    }

    h3 {
      color: white;
      background-color: $primary !important;
      width: auto;
      height: fit-content;
      min-width: 280px;
      line-height: 45px;
      height: 45px;
      padding: 0 $dim-l;
      margin: $dim-s 0;
      border-radius: $rad-s;
      font-size: $fs-s !important;
      background-color: transparent;

      &:hover {
        background-color: $primary-hover !important;
      }
    }
  }
}

//- Mobile
@media screen and (max-width: 1000px) {
  .cards-container {
    width: 100%;
    max-width: calc((475px * 2) + ($dim-l));
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: $dim-s;
    column-gap: $dim-s;
  }

  .card {
    flex: 0 1 50%;
    flex: 1;
    height: calc(245px + ($dim-m) * 2);
    min-width: 275px;
    width: 100%;
    max-width: 100%;
    margin: $dim-m 0;
    text-align: center;
    border-radius: $rad-m;
    box-shadow: $shadow-xs;
    padding: $dim-s;

    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-evenly;
    background-color: $bgc-complementary;

    img {
      height: 75%;
      width: auto;
      max-width: 400px;
      object-fit: contain;
      -webkit-transition: all 0.25s ease;
      border-radius: $dim-xs;
      transition: all 0.25s ease;
    }

    h3 {
      width: auto;
      height: fit-content;
      min-width: 280px;
      line-height: 1.1;
      border-radius: $rad-s;
    }
  }
}
</style>
