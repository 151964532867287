/* eslint-disable */

var assetsLocation = `diseases/кисти/`;

export default {
  location: "Кисть",
  group: "Болезни лучезапястного сустава и кисти",
  pointCoord: { left: "60px", top: "330px" },
  imgPath: "diseases/hand.png",
  propsForCardDoctor: {
    h: 'Болезни лучезапястного сустава и кисти',
    p: "Кисть человека – это центр активности верхней конечности. Она является не просто средством для манипулирования объектами, а чрезвычайно сложным инструментом межчеловеческого общения и получения информации об окружающем мире. Наличие у человека такого уникального и высокоспециализированного органа идентифицируется с его физической полноценностью и социальной адаптацией.",
    visualContent: `${assetsLocation}Болезни лучезапястного сустава и кисти.jpg`

  },
  listOfDiseases: [
    {
      name: `Травмы, растяжения`,
      propsForCardDoctor: {
        h: `Травмы, растяжения`,
        p: "",
        visualContent: `${assetsLocation}Травмы, растяжения.jpg`
      },
      description:
        `
**Травмы, растяжения лучезапястного сустава -** в основном происходят в результате удара, падения на область сустава, резкого движения, стереотипных нагрузок, в спорте и в быту. Кости запястья прочно скреплены связками, благодаря чему энергия удара, нанесённого по одной из кости, равномерно распределяется по всему запястью. Это служит своеобразной системой защиты, в первую очередь — от переломов. Вместе с тем, если перелом или вывих запястья всё-таки случился, то из-за большой скученности костей и, соответственно, связок, травма всегда получается более обширной чем, например, при простом переломе лучевой кости.

#### Виды травм

*   перелом — нарушение целостности кости;
*   вывих — смещение концов костей сустава относительно друг друга;
*   растяжение и разрыв связок — частичное или полное нарушение целостности связок;
*   ушиб — повреждение мягких тканей без нарушения целостности кожного покрова;
*   рана — повреждение мягких тканей с нарушением целостности кожного покрова;
*   размозжение — разрушение тканей, приведшее к полной потере их жизнеспособности.

При травмировании запястья или кисти в ближайшее время необходимо показаться врачу травматологу-ортопеду или хирургу, чтобы точно определить степень повреждения и необходимый объем и срочность медицинского вмешательства. 

### Клиническая картина

*   пронзительная боль в момент получения травмы
*   боль при надавливании
*   отечность и гематома в области травмы
*   ограничения в движениях кистью
*   изменение цвета кожа в области травмы
*   локальное повышение температуры
*   ощутимое выступание отломков кости под поверхностью кожи

### Лечение

Лечение подбирается врачом в зависимости от характера и вида травмы, степени повреждения и наличия сопутствующих заболеваний.

#### Консервативное

##### Коррекция образа жизни

*   максимальное ограничение нагрузки на сустав
*   ортезирование лучезапястного сустава
*   при переломе – иммобилизация, гипс
*   витаминизированная диета с ограничением поваренной соли
*   ограничение курения и алкоголя

##### Медикаментозное лечение

*   анальгетики при болях
*   противовоспалительная терапия
*   противоотечная терапия
*   хондропротекторы
*   витаминотерапия.

##### Физиотерапевтические методы

*   криотерапия
*   СКЭНАР-терапия,
*   фонофорез с гидрокортизоном,
*   массаж
*   МЛТ
*   ударно-волновая терапия
*   кинезиотерапия, ЛФК

##### Ортобиологические методы 

*   PRP-терапия
*   SVF-терапия

#### Оперативное

При наличии обширного повреждения мягких тканей, разрыва кровеносных сосудов и кровоизлияния в область лучезапястного сустава, некроза поврежденных тканей и позднего обращения к врачу, может потребоваться пункция сустава, в более запущенных случаях – артроскопия или серьезное хирургическое вмешательство.

При несвоевременном обращении к врачу и слишком поздно начатом лечении после травмы может развиться гемартроз, бурсит, артроз, не поддающийся терапии.

При переломах со смещением необходимо хирургическое вмешательство: репозиция (открытая или закрытая), фиксация отломков, комплекс реабилитационных мероприятий.
`,
    },
    {
      name: `Щелкающий палец`,
      propsForCardDoctor: {
        h: `Щелкающий палец`,
        p: "",
        visualContent: `${assetsLocation}Щелкающий палец.png`

      },
      description:
        `
**Щелкающий палец (стенозирующий лигаментит, болезнь Нотта)** – это заболевание сухожильно-связочного аппарата кисти, обычно развивается при однообразных повторяющихся движениях, например – однотипная работа руками, включающая хватательные движения, как следствие, нарушаются движения пальца, что сопровождается щёлкающим звуком.

### Клиническая картина

*   звук щелчка при сгибании/разгибании пальца
*   уплотнение в области пораженной фаланги;
*   чувство скованности и онемения в пораженной области;
*   боль, усиливающаяся при надавливании на основание пораженной фаланги;
*   боль в кисти;
*   ухудшение подвижности и координации движений пораженной руки

### Лечение

Лечение подбирается врачом в зависимости от стадии заболевания и наличия сопутствующих заболеваний.

#### Консервативное

##### Коррекция образа жизни

*   ограничение нагрузки на сустав,
*   избегание стереотипных движений
*   использование ортеза для пальца
*   витаминизированная диета с ограничением поваренной соли,
*   ограничение курения и алкоголя

##### Медикаментозное лечение

*   местные согревающие средства
*   противовоспалительная терапия
*   рассасывающие препараты с гепарином
*   противоотечная терапия,
*   «блокады» с гидрокортизоном

##### Физиотерапевтические методы

*   СКЭНАР-терапия,
*   фонофорез с гидрокортизоном,
*   массаж
*   МЛТ
*   ударно-волновая терапия
*   кинезиотерапия, ЛФК

##### Ортобиологические методы 

*   PRP-терапия

#### Оперативное

Показанием к оперативному вмешательству служит отсутствие положительной динамики в течение 6 месяцев консервативного лечения.

*   лигаментотомия - выполняется небольшой разрез кожи ладонной поверхности кисти в проекции дистальной ладонной складки, и рассекается кольцевидная связка

При несвоевременном обращении к врачу и слишком поздно начатом лечении может развиться стойкая контрактура, не поддающиеся терапии.
`,
    },
    {
      name: `Атрофия или синдром Зудека`,
      propsForCardDoctor: {
        h: `Атрофия или синдром Зудека`,
        p: "",
        visualContent: `${assetsLocation}Атрофия или синдром Зудека.jpg`

      },
      description:
        `
Атрофия или синдром Зудека (алгонейродистрофия, рефлекторная симпатическая дистрофия) - это симптомокомплекс, который развивается после травм кисти как осложнение, проявляется болями, нарушением функции конечности, трофическими расстройствами.

### Клиническая картина

* ноющая боль в области кисти, пальцев
* отек подкожной клетчатки (тестообразный, затем «глянцевый, резиновый»)
* припухлость в области дистальных отделов кисти
* гиперемия, покраснение, затем посинение кожи
* затруднение при движении из-за отека
* спазм мышц

### Лечение

Лечение длительное, комплексное, в основном консервативное, подбирается врачом в зависимости от стадии заболевания и наличия сопутствующих патологий.

#### Консервативное

##### Коррекция образа жизни

*   ограничение нагрузки на кисть
*   иммобилизация кисти-до 2-х недель
*   витаминизированная диета с ограничением поваренной соли
*   ограничение курения и алкоголя

##### Медикаментозное лечение

*   анальгетики при болях
*   НПВС
*   лечебные «блокады»
*   мочегонные препараты
*   венотоники
*   витаминотерапия
*   антидепрессанты.

##### Физиотерапевтические методы

*   СКЭНАР-терапия
*   фонофорез с гидрокортизоном
*   массаж
*   МЛТ
*   кинезиотерапия, ЛФК

##### Ортобиологические методы 

*   PRP-терапия
*   SVF-терапия

#### Оперативное

При неэффективности консервативных мероприятий и необходимости коррекции отдаленных последствий, наблюдающихся в исходе синдрома Зудека проводят следующие вмешательства:

*   невротомия;
*   невролиз;
*   экстирпация звездчатого узла;
*   различные варианты симпатэктомии.

Для улучшения функции конечности в атрофической стадии по показаниям производят артродез в функционально выгодном положении, резекцию внесуставных сращений, артропластику.
          `,
    },
    {
      name: `Асептический некроз полулунной кости (болезнь Кинбека)`,
      propsForCardDoctor: {
        h: `Асептический некроз полулунной кости (болезнь Кинбека)`,
        p: "",
        visualContent: `${assetsLocation}Асептический некроз полулунной кости (болезнь Кинбека).jpg`

      },
      description:
        `
**Болезнь Кинбека (остеохондропатия полулунной кости, асептический или аваскулярный некроз полулунной кости)** – заболевание, сопровождающееся изолированным разрушением кости запястья, участвующей в образовании лучезапястного сустава. Встречается достаточно редко, обычно страдают люди 20-40 лет, занимающиеся ручным физическим трудом. Поражается преимущественно ведущая рука (у правшей – правая, у левшей – левая), в 10% случаев патология носит двухсторонний характер. Мужчины заболевают чаще женщин.

### Клиническая картина

*   неотчетливая боль в области сустава при нагрузке
*   боль нарастает постепенно
*   сильная боль при патологическом переломе
*   хруст при пальпации
*   боль и отек на тыльной стороне кисти
*   покраснение кожи и повышение температуры в области сустава
*   затруднение при движении
*   атрофия мышц предплечья

### Лечение

Лечение подбирается врачом в зависимости от стадии заболевания и наличия сопутствующих патологий.

#### Консервативное

##### Коррекция образа жизни

*   ограничение нагрузки на сустав,
*   иммобилизация на 1 месяц (бандаж, гипс, тутор),
*   витаминизированная диета с ограничением поваренной соли,
*   ограничение курения и алкоголя

##### Медикаментозное лечение

*   анальгетики при болях,
*   местные согревающие средства
*   противовоспалительная терапия
*   противоотечная терапия,
*   «блокады» с глюкокортикостероидами
*   препараты, укрепляющие суставной хрящ
*   витаминотерапия.

##### Физиотерапевтические методы

*   СКЭНАР-терапия,
*   фонофорез с гидрокортизоном,
*   массаж
*   МЛТ
*   ударно-волновая терапия
*   кинезиотерапия, ЛФК

##### Ортобиологические методы 

*   PRP-терапия
*   SVF-терапия

#### Оперативное

При неэффективности консервативного лечения с учетом стадии и выраженности патологического процесса могут применяться следующие оперативные вмешательства:

*   Реваскуляризация. Осуществляется путем перемещения небольшого васкуляризованного костного трансплантата. Рекомендована при отсутствии грубых нарушений формы полулунной кости, сохранении функциональности сустава.
*   Костная пластика. Производится при обширном разрушении кости с использованием аутотрансплантата и костной крошки.
*   Низведение фрагмента головчатой кости. Выполняется после удаления поврежденной полулунной кости, Г-образной остеотомии головчатой кости.
*   Эндопротезирование. Иногда разрушенную полулунную кость замещают эндопротезом, однако такая операция возможна только при последующем отказе от тяжелого физического труда.
`,
    },
    {
      name: `Контрактура Дюпюитрена`,
      propsForCardDoctor: {
        h: `Контрактура Дюпюитрена`,
        p: "",
        visualContent: `${assetsLocation}Контрактура Дюпюитрена.jpg`
      },
      description:
        `
**Контрактура Дюпюитрена (болезнь Дюпюитрена, болезнь викингов, «кельтская рука»)** - хроническое заболевание, характеризующееся поражением ладонного апоневроза, приводящее к сгибательной контрактуре пальцев кисти. Заболеванию подвержены преимущественно мужчины работоспособного возраста (92–96%), женщины болеют реже (4–8%).

### Клиническая картина

*   появление узлового образования и тяжей, расположенных под кожей ладони
*   нарушается процесс разгибания пальца
*   формируются тяжи, которые отходят к основной и средней фалангам поврежденного пальца
*   плотность кожи возле образования постепенно увеличивается, что сопровождается ее спаиванием с близлежащими тканями, а также появлением втяжений и выпуклостей.
*   тяжи и узел хорошо просматриваются во время разгибания пальца
*   затруднения разгибания пальцев кисти прогрессирует
*   боль отсутствует

### Лечение

Лечение подбирается врачом в зависимости от стадии заболевания и наличия сопутствующих заболеваний.

#### Консервативное

##### Коррекция образа жизни

*   витаминизированная диета с ограничением поваренной соли,
*   ограничение курения и алкоголя

##### Медикаментозное лечение

*   НПВС
*   аппликации с протеолитическими ферментами
*   рассасывающие препараты с коллагеназой,
*   «блокады» с глюкокортикостероидами
*   витаминотерапия.

##### Физиотерапевтические методы

*   СКЭНАР-терапия,
*   фонофорез с гидрокортизоном,
*   массаж
*   фототерапия
*   МЛТ
*   ударно-волновая терапия
*   кинезиотерапия, ЛФК

##### Ортобиологические методы 

*   PRP-терапия

#### Оперативное

При неэффективности консервативного лечения с учетом стадии и выраженности патологического процесса могут применяться следующие оперативные вмешательства:

*   Чрескожная игольчатая фасциотомия — это минимально инвазивная методика, при которой натяжение ослабляется в результате манипуляций, проводимых маленькой иглой. Тяж разделяется на максимально возможное количество сегментов. Хирург использует иглу 25 калибра, установленную на шприце объемом 10 мл. После проведения процедуры тяж можно порвать, вытянув вперед пальцы. В течение 24 часов показано ношение повязки.
*   Селективная фасциэктомия — обширное иссечение рубцово-измененных участков ладонного апоневроза.
*   Сегментарная фасциэктомия — иссечение части (или частей) укороченного тяжа таким образом, чтобы он больше не сгибал палец.
*   Дермофасциэктомия — хирургическое вмешательство, которое обычно назначается в случае повторных клинических проявлений заболевания. Данная операция также проводится пациентам с высоким риском рецидива. Как и при селективной фасциэктомии, при дермофасциэктомии производится удаление тяжей измененного ладонного апоневроза. При этом дополнительно осуществляется иссечение кожного покрова. Затем этот участок закрывается кожным аутотрансплантатом.
`,
    },
    {
      name: `Теносиновит шиловидного отростка лучевой кости (болезнь де Кервена)`,
      propsForCardDoctor: {
        h: `Болезнь де Кервена`,
        p: "",
        visualContent: `${assetsLocation}Теносиновит шиловидного отростка лучевой кости (болезнь де Кервена).png`

      },
      description:
        `
**Болезнь де Кервена (стенозирующий тендовагинит)** – своеобразная форма хронического тендовагинита, характеризующаяся поражением влагалища сухожилий короткого разгибателя и длинной отводящей мышцы большого пальца кисти. Это воспалительное заболевание сухожилия большого пальца руки, часто возникает в результате постоянной высокой нагрузки на кисть. 

### Клиническая картина

*   боль в области шиловидного отростка лучевой кости
*   боль усиливается при движении, надавливании
*   припухлость, гиперемия по ходу сухожилия
*   ухудшение подвижности большого пальца

### Лечение

Лечение подбирается врачом в зависимости от стадии заболевания и наличия сопутствующих заболеваний.

#### Консервативное

##### Коррекция образа жизни

*   ограничение нагрузки на сустав,
*   иммобилизация, использование ортеза для пальца
*   витаминизированная диета с ограничением поваренной соли,
*   ограничение курения и алкоголя

##### Медикаментозное лечение

*   анальгетики при болях
*   местнораздражающие средства
*   противовоспалительная терапия
*   противоотечная терапия, венотоники
*   «блокады» с глюкокортикостероидами
*   витаминотерапия

##### Физиотерапевтические методы

*   СКЭНАР-терапия,
*   фонофорез с гидрокортизоном,
*   массаж
*   МЛТ
*   ударно-волновая терапия

##### Ортобиологические методы 

*   PRP-терапия

#### Оперативное

Показанием к оперативному вмешательству служит отсутствие положительной динамики в течение 6 месяцев консервативного лечения. Операция направлена на устранение препятствия для нормального скольжения сухожилий путем рассечения поперечной связки и декомпрессии сухожилия.
`,
    },
    {
      name: `Артроз пальцев и кисти`,
      propsForCardDoctor: {
        h: `Артроз пальцев и кисти`,
        p: "",
        visualContent: `${assetsLocation}Артроз пальцев и кисти.jpg`

      },
      description:
        `
**Артроз кисти и пальцев** – является разновидностью деформирующего артроза. Данное дегенеративно-дистрофическое заболевание характеризуется прогрессирующим разрушением хрящевой ткани головок пястных костей, суставов фаланг пальцев кисти, сопровождающимся нарушением функционирования сустава, а также повреждением околосуставных структур, таких как синовиальная мембрана, суставные связки, околосуставные мышцы и их сухожилия.

### Клиническая картина

*   появление болей при нагрузке на кисть;
*   уменьшение или полное исчезновение болей после отдыха
*   утренняя скованность в больном суставе менее 30 мин
*   уменьшение функциональности больного сустава
*   трудности при сжимании и разжимании кисти
*   дискомфорт при пальпации пястья или фаланг
*   плотные припухлости по краям пораженного сустава за счет краевых остеофитов и утолщения головок сустава
*   характерный хруст при пассивных и активных движениях в суставе
*   увеличение объема больного сустава за счет реактивного увеличения количества внутрисуставной жидкости
*   снижение амплитуды движений в суставе
*   заклинивание сустава в определенном положении

### Лечение

Лечение подбирается врачом в зависимости от стадии заболевания и наличия сопутствующих заболеваний.

#### Консервативное

##### Коррекция образа жизни

*   ограничение нагрузки на сустав
*   иммобилизация, использование ортеза для кисти
*   витаминизированная диета с ограничением поваренной соли,
*   ограничение курения и алкоголя

##### Медикаментозное лечение

*   анальгетики при болях
*   местнораздражающие средства
*   противовоспалительная терапия
*   противоотечная терапия, венотоники
*   «блокады» с глюкокортикостероидами
*   хондропротекторы
*   витаминотерапия

##### Физиотерапевтические методы

*   СКЭНАР-терапия,
*   фонофорез с гидрокортизоном,
*   массаж
*   фототерапия
*   МЛТ
*   ЛФК, кинезиотерапия

##### Ортобиологические методы 

*   PRP-терапия

#### Оперативное

Показанием к оперативному вмешательству служит отсутствие положительной динамики в течение 12 месяцев консервативного лечения и значительное ухудшение качества жизни. При ригидности суставов пользование кистью становится почти невозможным. Положение ухудшается, если неподвижность наступает в функционально невыгодном положении, так как она нарушает функцию всей кисти.

Для устранения деформации суставов кисти применяются следующие виды оперативных вмешательств:

*   эксцизия сухожилия поверхностного сгибателя пальцев дает хорошие результаты в том случае, если ограничение движения обусловлено сращением сухожилия с подлежащими тканями.
*   капсулотомия является одним из методов исправления тугоподвижности, вызванной сморщиванием суставной капсулы, если причина ригидности сустава заключается в поражении периартикулярных тканей.
*   артропластика может быть произведена на средних суставах пальцев в том случае, если на той же кисти поражены средние суставы нескольких пальцев, приводит к наилучшим результатам на пястнофаланговом суставе указательного пальца у молодых людей.
*   остеотомия производится на фаланге, расположенной проксимально от патологически измененного сустава.
*   артродез является наиболее целесообразной восстановительной операцией на суставах и рекомендуется на межфаланговых суставах и в пястнофаланговом суставе большого пальца.
*   пересадка сустава – для замещения среднего сустава пальцев иногда применяется пересадка сустава пальца стопы.
*   эндопротезирование.  
`,
    },

  ],
};