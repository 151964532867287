<template>
  <app-expanded-item
    v-if="expandedItem != null"
    :expand="expand"
    @close="expand = false"
    :expandedImagePath="`aboutMe/carrousel/${expandedItem.name}.jpg`"
  >
  </app-expanded-item>
  <div id="list-of-diseases">
    <div class="card-header">
      <h4>Андрей Иванович Горбатенко</h4>
    </div>
    <Markdown
      source="Меня зовут **Андрей Иванович Горбатенко**.

Я кандидат медицинских наук,  доцент кафедры травматологии и ортопедии Ростовского медицинского государственного университета, врач травматолог-ортопед высшей категории, хирург, член международных, всероссийских, региональных медицинских ассоциаций, таких как  «АСТАОР» – Ассоциация спортивных травматологов, артроскопических и ортопедических хирургов, реабилитологов, AOTrauma - международное сообщество травматологов, ортопедов, хирургов и исследователей опорно-двигательного аппарата, Ростовское областное общество травматологов и ортопедов, Ассоциация травматологов-ортопедов России, Ассоциация специалистов хирургического профиля Ростовской области, Российская ассоциация хирургов стопы и голеностопного сустава. Также я занимаюсь научно-исследовательской и изобретательской деятельностью, являюсь автором более 80 научных публикаций и 17 патентов на изобретения и полезные модели.
"
    />
    <button class="button-read_more" v-if="!showMore" @click="showMore = true">
      <p>Читать подробнее</p>
    </button>

    <Transition name="slide-fade">
      <Markdown
        v-if="showMore"
        source="
### Больше обо мне
Почти 40 лет я специализируюсь на диагностике, лечении и реабилитации заболеваний и травм опорно-двигательной системы. В сферу моих профессиональных интересов входит консервативное лечение заболеваний патологий опорно-двигательного аппарата даже в самых запущенных случаях, использование новейших  методик и рекомендаций по спасению суставов. Я применяю различные инновационные методы лечения остеоартроза, в том числе PRP-терапию, SVF-терапию, внутрисуставное введение препаратов гиалуроновой кислоты, ударно-волновую терапию, метод биологической обратной связи и многое другое. 
Также я применяю самые последние разработки в малоинвазивном лечении коленных суставов – диагностическую артроскопию, санационную артроскопию коленного сустава, артроскопическое удаление менисков, шов мениска, артроскопическую пластику передней и задней крестообразной связки, замещение дефектов хряща, удаление хондромных тел и многое другое.
Большое внимание в моей хирургической практике уделяется самым актуальным методикам хирургии стопы и голеностопного сустава при патологии у взрослых и детей, современным техникам пластики при плоскостопии и Hallux valgus, ортопедической косметологии (коррекция формы ног, увеличение роста). 
Также я занимаюсь криохирургическом лечением опухолей костей, лечением несросшихся переломов и ложных суставов костей методом ЧКДО по Илизарову, применяю современные методы остеосинтеза, костной пластикой и эндопротезирования суставов. 
В течение всей моей профессиональной карьеры я постоянно учусь у своих коллег, посещая конгрессы, конференции, мастер-классы, вебинары, курсы повышения квалификации, скрупулезно изучая новые рекомендации и методики, тщательно осваивая новые технологии, так как считаю, что настоящий врач, а тем более хирург, должен быть перфекционистом во всем.
"
      />
    </Transition>
    <br />
    <button
      class="button-read_more"
      v-if="!showBibliography"
      @click="showBibliography = true"
    >
      <p>Читать биографию</p>
    </button>
    <Transition name="slide-fade"
      ><Markdown
        v-if="showBibliography"
        source='
### Биография
Родился 22.02.1963 в пос. Веселое Неклиновского р-на Ростовской области.

#### Образование:

В 1986 году окончил с отличием Астраханский государственный медицинский институт по специальности «Лечебное дело».

Работал врачом интерном, а затем травматологом-ортопедом в Пензенской областной больнице им. Н.Н.Бурденко.

С 1990 по 1996 годы работал ассистентом кафедры травматологии, ортопедии и военно-полевой хирургии Астраханской государственной медицинской академии.

В 1998 году проходил стажировку в США.

С 2002 года по настоящее время работает травматологом-ортопедом в хирургическом отделении Детской многопрофильной больницы г. Таганрога.

В 2003 году защитил кандидатскую диссертацию на тему «Криохирургическое лечение доброкачественных опухолей и опухолевидных поражений костей нижних конечностей».

С 2004 по 2013 годы работал ассистентом кафедры травматологии и ортопедии Ростовского государственного медицинского университета.

С 2013 года по настоящее время работает доцентом кафедры травматологии и ортопедии Ростовского государственного медицинского университета.

В 2019 году получил звание доцента травматологии и ортопедии.

В 2016 году прошел профессиональную переподготовку по программе "Экспертиза временной нетрудоспособности".

В 2018 году прошел профессиональную переподготовку по программе "Медицинская реабилитация".

В 2019 году прошел профессиональную переподготовку по программе "Физическая и реабилитационная медицина".

В 2020 году прошел профессиональную переподготовку по программе "Организация здравоохранения и общественное здоровье".

В 2017 году открыл медицинский центр «АРТРОЛИГА»

В 2019 году открыл медицинский центр реабилитации «Медиана»

Участник межрегиональных, всероссийских и международных конгрессов в области травматологии и ортопедии, реконструктивной хирургии, спортивной и реабилитационной медицины, клеточных технологий и регенеративной медицины.

В 2012 году - участник курса "Knee and shoulder arthroscopy workshop" (Gross-Gerau, Germany), в Германии.

В 2014 году представлял постерный доклад "Basic Principles of Pathological Fractures Treatment in Cases of Benign Tumors and Tumor-like Lesions of Bone" на 3rd FFN Global Congress в Мадриде, Испания.

В 2015 году участник World Congress on Osteoporosis, Osteoarthritis and Musculoskeletal Diseases в Милане, Италия.

В 2016 году принимал участие в 11th Central European Orthopaedic Congress (CEOC) в Праге, Чехия.

В 2016 участник Workshop. IV. interventional cadaver course (CME accredited).. Institute of Anatomy University Basel в Базеле, Швейцария.

В 2016 принимал участие в 37th SICOT Orthopaedic World Congress году в Риме, Италия.

В 2017 году принимал участие в ARTHREX KNEE and SHOULDER WORKSHOP, в Мюнхене, Германия.

#### Изобретения и полезные модели в области медицины:
Горбатенко Андрей Иванович **является автором или соавтором 17 патентов на изобретения и полезные модели в области медицины и медицинской техники, таких как:**

*   патент РФ на полезную модель № 130835 "Криоаппликатор медицинский"
*   патент РФ на полезную модель № 99952, "Устройство для осуществления остеосинтеза"
*   патент РФ на полезную модель № 112616 "Устройство для малоинвазивной репозиции отломков пяточной кости"
*   патент РФ на полезную модель № 109959 "Ортопедическая обувь для лечения плоскостопия"
*   патент РФ на полезную модель № 157443 "Устройство для смещения надколенника"
*   патент РФ на полезную модель № 158195 "Пластина для накостного остеосинтеза"
*   патент РФ № 2484785 "Способ лечения внутрисуставных импрессионных переломов пяточной кости"
*   патент РФ № 2571838 "Способ лечения опухолей и опухолеподобных поражений костей"
*   патент РФ № 2599202 "Способ лечения болезни Осгуда-Шлаттера"
*   патент РФ № 2620495 "Способ лечения остеоартроза коленных суставов".
*   патент РФ № 2674872 «Способ оценки топической локализации и интенсивности болевого синдрома в коленном суставе»
*   патент РФ на полезную модель № 201331 «Устройство для измерения мышечного тонуса и порога болевой чувствительности»
*   патент РФ № 2760542 «Способ лечения и профилактики продольного плоскостопия у детей и стелька-супинатор для его осуществления (варианты)»
*   патент РФ на полезную модель № 161681 «Игла для пункций и инъекций в малые вены»

Является автором более 80-ти научных медицинских статей, 11 из которых в изданиях, включенных в текущий перечень ВАК.
'
    /></Transition>

    <Carousel
      :settings="settings"
      :wrapAround="true"
      :breakpoints="breakpoints"
    >
      <Slide
        v-for="carrouselItem in aboutMe.carrousel"
        :key="carrouselItem.name"
      >
        <div class="carousel__item">
          <AppButtonExpand @click="expandItem(carrouselItem)" />
          <img
            :src="
              require(`@/assets/aboutMe/carrousel/${carrouselItem.name}.jpg`)
            "
            alt=""
          />
        </div>
      </Slide>

      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>

<script>
import Markdown from "vue3-markdown-it";
import AppButtonExpand from "../components/AppButtonExpand.vue";
// import AppButton from "../components/AppButton.vue";
import "vue3-carousel/dist/carousel.css";
import AppExpandedItem from "../components/AppExpandedItem.vue";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { mapState } from "vuex";

export default {
  data: () => ({
    expandedItem: null,
    expand: false,
    showMore: false,
    showBibliography: false,
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    breakpoints: {
      1000: {
        itemsToShow: 1.5,
        snapAlign: "center",
      },
      1250: {
        itemsToShow: 2.5,
        snapAlign: "center",
      },
    },
  }),
  computed: {
    ...mapState("ModuleAboutMe", ["aboutMe"]),
  },
  methods: {
    expandItem(item) {
      this.expand = true;
      this.expandedItem = item;
    },
  },
  components: {
    Markdown,
    // Carousel:
    Carousel,
    Slide,
    Pagination,
    Navigation,
    // \end
    AppExpandedItem,
    AppButtonExpand,
    // AppButton,
  },
};
</script>
<style lang="scss">
@import "../theme.scss";
#list-of-diseases {
  height: 100%;
  width: 100%;
  max-width: 800px;
  text-align: justify;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
}

// Carousel:
.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: transparent;
  color: white;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: $dim-l;
  position: relative;

  img {
    border-radius: $rad-m;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
// - Active Classes
.carousel__slide > .carousel__item {
  transform: scale(1);
  opacity: 0.5;
  transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
  transform: scale(1.1);
}
.button-read_more {
  border: none;
  width: fit-content;
  border-radius: $rad-m;
  height: 75px;
  margin: 0 $dim-m;
  padding: 0 $dim-m;
  margin-left: 0;
  background-color: $neutral-darker;
  color: $header;
  text-decoration: none;
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
  cursor: pointer;
  p {
    font-weight: 600;
  }
  &:hover p {
    transform: translateY(-2px);
    transition: all 200ms ease;
    color: $primary-hover;
  }
}
// - Mobile
@media screen and (max-width: 1000px) {
  #list-of-diseases {
    text-align: left;
  }
  .expand-button {
    display: none !important;
  }
  .button-read_more {
    margin-left: 0;
    height: 55px;
    width: 100%;
    p {
      font-weight: 600;
      color: $primary-hover;
    }
    &:hover p {
      transform: translateY(0px);
      transition: all 200ms ease;
      color: $primary-hover;
    }
  }
}
</style>
