<template >
  <Transition name="translateY">
    <div v-if="this.$store.state.isMenuOpen" id="menu">
      <button
        v-if="this.$store.state.isMenuOpen"
        id="menu-button"
        @click="this.$store.state.isMenuOpen = false"
      >
        <inline-svg
          :src="require(`@/assets/close.svg`)"
          fill-opacity="1"
          color="#100635"
          width=" 24px"
          height="24px"
          stroke="30px"
        />
        Выйти
      </button>
      <Logotip @click.prevent="navigateThroughMenu('')" />
      <ul>
        <li>
          <a href="#" @click="navigateThroughMenu('')"
            >На главную страницу 👋</a
          >
        </li>
        <li v-for="section in siteSections" :key="section.sectionPath">
          <a href="#" @click="navigateThroughMenu(section.sectionPath)">
            {{ section.sectionName }}
          </a>
        </li>
      </ul>
    </div>
  </Transition>
  <nav>
    <a class="gorbatenko" href="#" @click.prevent="$router.push(`/`)">
      <Logotip @click.prevent="navigateThroughMenu('')" />
    </a>
    <button id="menu-button" @click="this.$store.state.isMenuOpen = true">
      <inline-svg
        :src="require(`@/assets/menu.svg`)"
        fill-opacity="1"
        color="#100635"
        width=" 22px"
        height="22px"
        stroke="30px"
      />
      Меню
    </button>

    <a
      class="fs-m"
      href="#"
      v-for="path in getFullPath"
      @click.prevent="navLinkTo(decodeURI(path))"
      :key="path"
      v-show="path"
      v-text="showPathInRussian(path)"
    ></a>
  </nav>
</template>
<script>
import { mapState } from "vuex";
import Logotip from "./logotip.vue";
export default {
  data() {},
  components: {
    Logotip,
  },
  methods: {
    navLinkTo(path) {
      var decodedArray = [];

      this.getFullPath.forEach((item) => {
        const decodedItem = decodeURI(item);
        decodedArray.push(decodedItem);
      });
      let index = decodedArray.findIndex((item) => item === path);
      decodedArray.length = index + 1;
      let finalPath = decodedArray.join("/");
      this.$router.push(finalPath);
    },
    showPathInRussian(path) {
      let decodedPath = decodeURI(path);
      switch (decodedPath) {
        case this.siteSections.rostov.sectionPath:
          return this.siteSections.rostov.sectionName;
        case this.siteSections.taganrog.sectionPath:
          return this.siteSections.taganrog.sectionName;
        case this.siteSections.diseases.sectionPath:
          return this.siteSections.diseases.sectionName;
        case this.siteSections.aboutMe.sectionPath:
          return this.siteSections.aboutMe.sectionName;
      }
      return decodedPath;
    },
    navigateThroughMenu(path) {
      this.$store.state.isMenuOpen = false;
      this.$router.push(`/${path}`);
    },
  },
  computed: {
    ...mapState("ModuleSections", ["siteSections"]),
    getFullPath() {
      var originalArrayOfPaths = this.$route.fullPath.split("/");
      return originalArrayOfPaths;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../theme.scss";
nav {
  position: fixed;
  height: 70px;
  z-index: 3;
  background-color: $bgc-bright;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  // text-transform: capitalize;
  width: 100%;
  top: 0;
  a {
    font-size: large;
    &:last-child {
      background-color: transparent;
      color: $primary-hover;
      pointer-events: none;
      &:hover {
        background-color: $primary;
      }
    }
    border-radius: $rad-s;
    padding: $dim-s;
    margin: 0 $dim-m;
    background-color: $ghost-complementary;
    color: $ghost-primary;
    text-decoration: none;
    -webkit-transition: all 0.12s ease;
    transition: all 0.12s ease;
    &:hover {
      background-color: $primary-hover;
      color: white;
    }
    &:first-child {
      background-color: transparent;
    }

    &:nth-child(3) {
      display: none;
    }
  }
}
#menu-button {
  display: none;
}
#menu {
  display: none;
}
// - Mobile
@media screen and (max-width: 1000px) {
  #menu-button {
    display: flex;
    width: 110px;
    height: 40px;
    position: absolute;
    right: $dim-s;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border: none;
    border-radius: $dim-xs;
    font-weight: 600;
    color: #100635;
    background-color: #d0f0fd;
    cursor: pointer;
    font-size: large;
  }
  #menu {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 4;
    top: 0;
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: #0205d3;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    padding: $dim-l;
    ul {
      max-width: 350px;

      li,
      a {
        margin: $dim-l 0;
        list-style-type: none;
        font-size: x-large;
        font-weight: 600;
        color: #d9daf8;
        &:hover {
          color: #fff;
        }
      }
    }
  }
  nav {
    height: $navbar-height-mobile;
    background-color: #100635;
    box-shadow: rgb(17 18 38 / 15%) 0px 0px 20px 0px;
    a {
      display: none;
      &:last-child {
        color: $primary-hover;
      }
      &:hover {
        color: $primary-hover;
        text-decoration: none;
      }
      &:nth-child(2) {
        display: none;
      }
    }
  }
  .gorbatenko {
    display: flex !important;
    color: white;
    margin: $dim-xs;
    text-decoration: none;
    border-bottom: none;
    &:hover {
      color: white;
      border-bottom: none;
      text-decoration: none;
    }
  }
  // &:nth-child(n + 2)::before {
  //   content: "../assets/close.svg";
  //   // margin-right: $m-m;
  // }
}
.translateY-enter-from,
.translateY-leave-to {
  transform: translateY(-100%);
}
.translateY-enter-active {
  transition: transform, opacity 0.5s ease;
}
</style>
