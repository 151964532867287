<template>
  <div id="gallery">
    <expanded-item
      v-if="expandedItemPath != null"
      :expand="expand"
      @close="expand = false"
      :expandedImagePath="expandedItemPath"
    >
    </expanded-item>
    <div class="image_plane-container">
      <div
        v-for="coord in clinic.pointCoord"
        :key="coord.name"
        class="point"
        :style="{ left: coord.left, top: coord.top }"
        @click="expandItem(coord.img)"
      >
        <img :src="require(`@/assets/${coord.img}`)" alt="" />
        <div class="label">
          <h5>{{ coord.name }}</h5>
          <p>{{ coord.description }}</p>
        </div>
      </div>

      <img
        class="plane"
        :src="require(`@/assets/${clinic.plane}`)"
        :alt="clinic.name.fullName"
      />
    </div>
  </div>
  <Carousel
    class="carousel"
    :settings="mySettings"
    :wrapAround="true"
    :breakpoints="myBreakpoints"
  >
    <Slide v-for="carouselItem in clinic.pointCoord" :key="carouselItem.img">
      <div class="carousel__item">
        <AppButtonExpand @click="expandItem(carouselItem.img)" />

        <img
          :src="require(`@/assets/${carouselItem.img}`)"
          :alt="carouselItem.name"
        />
      </div>
    </Slide>

    <template #addons>
      <Navigation />
      <Pagination />
    </template>
  </Carousel>
</template>

<script>
import ExpandedItem from "../components/AppExpandedItem.vue";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    ExpandedItem,
  },
  props: ["clinic"],

  data() {
    return {
      expandedItemPath: null,
      expand: false,
      // Carousel:
      mySettings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      myBreakpoints: {
        1000: {
          itemsToShow: 1.5,
          snapAlign: "center",
        },
      },
    };
  },
  methods: {
    expandItem(item) {
      this.expand = true;
      this.expandedItemPath = item;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../theme.scss";
#gallery {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;
  margin-top: $dim-xl;
}
.image_plane-container {
  height: 100%;
  width: fit-content;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-height: 600px;
  .plane {
    width: 100%;
    height: 100%;
    max-height: 600px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.point {
  position: absolute;
  width: calc(96px + 2.5vw);
  height: calc(96px + 2.5vw);
  background-color: transparent;
  border-radius: 50%;
  border: solid $dim-s rgba(255, 255, 255, 0.2);
  transform: translate(-50%, -50%);
  will-change: width, heigth, z-index, box-shadow;
  transition: all 200ms ease;
  box-shadow: $shadow-xs;
  z-index: 1;
  img {
    border-radius: 50%;
    cursor: pointer;
  }
  &:hover {
    z-index: 2;
    width: calc(200px + 2.5vw);
    height: calc(200px + 2.5vw);
    box-shadow: $shadow-m;
    transition: all 200ms ease;
    // .label {
    //   opacity: 1;
    // }
  }
  .label {
    display: none; //! For the moment display none, because there is no information
    position: absolute;
    bottom: -55px;
    background-color: $dark;
    border-radius: $rad-m;
    width: calc(120px + 0.5vw);
    height: fit-content;
    padding: $dim-xs;
    box-shadow: $shadow-m;
    pointer-events: none;
    opacity: 0;
  }
}

//- Mobile
@media (max-width: 1000px) {
  .image_plane-container {
    display: none;
  }
}
@media (min-width: 1000px) {
  .carousel {
    display: none;
  }
}
// Carousel:

// Carousel:
.carousel__item {
  min-height: 200px;
  max-height: 500px;
  width: 100%;
  background-color: transparent;
  color: white;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: $dim-l;
  position: relative;

  img {
    border-radius: $rad-m;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
// - Active Classes
.carousel__slide > .carousel__item {
  transform: scale(1);
  opacity: 0.5;
  transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
  transform: scale(1.1);
}
</style>
