/* eslint-disable */
import { createStore } from "vuex";
import ModuleSections from "./modules/sections";
import ModuleDiseases from "./modules/diseases/index";
import ModuleDoctorCard from "./modules/doctorCard";
import ModuleClinics from "./modules/clinics"
import ModuleAboutMe from "./modules/aboutMe"

export default createStore({
  state: {
    $route: null,
    $routeFullPath: [],
    $routeParams: null,
    //Variable for expanding the slide card and the <article>
    isExpanded: false,
    isMenuOpen: false,
  },
  mutations: {
    // We send the $route from router.js in the second parameter, "route"
    SET_ROUTE(state, route) {
      state.$route = route;
      state.$routeFullPath = decodeURI(route.fullPath).split('/');
      state.$routeParams = route.params;
    },
    // The second parameter "routes" is the array routes from router.js 
    //From here you can loop and get the paths
    //? TODO-> IS it necessary?
    GET_PATHS_FROM_ROUTER(state, routes) {
      // console.log('GET_PATHS_FROM_ROUTER');
      // console.log(state);
      // console.log(routes);
    }
  },
  modules: { ModuleSections, ModuleDiseases, ModuleDoctorCard, ModuleClinics, ModuleAboutMe },
});
