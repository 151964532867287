/* eslint-disable */
var assetsLocation = `diseases/таз/`;
export default {
  location: "Таз",
  group: "Болезни таза и тазобедренных суставов",
  imgPath: "coxis.png",
  pointCoord: { left: "210px", top: "275px" },
  propsForCardDoctor: {
    h: 'Болезни таза и тазобедренных суставов',
    p: "Таз – сложное анатомическое образование из костей, мышц и связок, выполняет ряд важнейших функций: поддерживает основание позвоночника, позволяет позвоночнику удерживать равновесие, обеспечивает распределение массы тела на нижние конечности, защищает от повреждений внутренние органы, обеспечивая крепление и движение нижних конечностей.",
    visualContent: `${assetsLocation}Болезни таза и тазобедренных суставов.jpg`
  },
  listOfDiseases: [
    {
      name: `Травмы и их последствия`,
      propsForCardDoctor: {
        h: `Травмы и их последствия`,
        p: "",
        visualContent: `${assetsLocation}.jpg`
      },
      description:
        `
Повреждения таза достаточно многообразны и относятся к категории наиболее тяжелых травм. Общее для них — как правило, приложение значительной силы для получения подобной травмы. Область таза богато иннервируется и кровоснабжается, поэтому при травмах высока вероятность острой кровопотери и развития геморрагического шока (таз является «шокогенной зоной»). При травмировании таза и тазобедренных суставов в ближайшее время необходимо показаться врачу травматологу-ортопеду или хирургу, чтобы точно определить степень повреждения.  
### Клиническая картина
При травме таза повреждения каждой локализации имеют свои клинические проявления. Общая клиническая картина и, соответственно диагноз, определяются на основании совокупности признаков
- Боль в области таза при движении, при надавливании, иногда в покое
- Локальная припухлость
- Асимметрия гребней подвздошных костей
- Функциональная «блокада» - невозможность выполнить определенные движения
### Лечение
Лечение подбирается врачом в зависимости от тяжести травмы,  степени повреждения и наличия сопутствующих заболеваний.
#### ***Консервативное*** 
При легких, не осложненных травмах, ушибах, стабильных переломах.
##### Коррекция образа жизни 
- ограничение подвижности, постельный режим
- ортезирование
- витаминизированная диета с ограничением поваренной соли, 
- ограничение курения и алкоголя.
##### Медикаментозное лечение 
- «блокада» с глюкокортикостероидами
- ректальные свечи с НПВС
- анальгетики, НПВС
- противоотечная терапия, 
##### Физиотерапевтические методы (на реабилитационном этапе) 
- скэнар-терапия, 
- фонофорез с гидрокортизоном, 
- ударно-волновая терапия,
- мануальная терапия 
##### Ортобиологические методы (на реабилитационном этапе)  
- PRP-терапия, 
- SVF-терапия.
#### *Оперативное*
Нестабильные переломы и переломы со значительным кровотечением, как правило, требуют госпитализации и серьезного оперативного вмешательства с применением внешней фиксацией или открытой репозицией с внутренней фиксацией. 
      `
    },
    {
      name: `Синдром грушевидной мышцы`,
      propsForCardDoctor: {
        h: `Синдром грушевидной мышцы`,
        p: "",
        visualContent: `${assetsLocation}Синдром грушевидной мышцы.jpg`
      },
      description:
        `
**Синдром грушевидной мышцы** - это ущемление седалищного нерва в ягодичной области, сопровождающееся клинической симптоматикой. При патологических изменениях в грушевидной мышце (рубцовое перерождение, спазм, воспаление) происходит ее укорочение. В результате может развиться компрессия (сдавливание) седалищного нерва, который прижимается грушевидной мышцей к мощной крестцово-спинальной связке таза. Спазм грушевидной мышцы - наиболее частое состояние, имеет рефлекторный характер и развивается на фоне заболеваний позвоночника или патологии тазобедренного сустава.
### Клиническая картина
- боль в ягодичной области с распространением вниз по ноге
- снижение чувствительности в ноге
- слабость мышц стопы
- усиление боли при закидывании больной ноги на здоровую
### Лечение
Лечение подбирается врачом в зависимости от тяжести травмы,  степени повреждения и наличия сопутствующих заболеваний.
#### *Консервативное* 
Направлено на декомпрессию седалищного нерва.
##### Коррекция образа жизни 
- исключение нагрузки, вызывающей боль, 
- витаминизированная диета с ограничением поваренной соли, 
- ограничение курения и алкоголя.
##### Медикаментозное лечение 
- «блокада» с глюкокортикостероидами
- анальгетики, спазмолитики, миорелаксанты
- противовоспалительная терапия
- противоотечная терапия, венотоники

##### Физиотерапевтические методы 
- скэнар-терапия, 
- фонофорез, 
- МЛТ,
- массаж, 
- остеопатическая коррекция
##### Кинезиотерапевтические методы 
- ЛФК, 
- гидрокинезиотерапия.

#### *Оперативное*
Хирургическое лечение показано в самых тяжелых случаях при развитии грубого пареза стоп (слабости в стопах). Проводится рассечение измененной грушевидной мышцы и освобождение (невролиз и декомпрессия) седалищного нерва.
      `
    },
    {
      name: `Сакроилеит`,
      propsForCardDoctor: {
        h: `Сакроилеит`,
        p: "",
        visualContent: `${assetsLocation}Сакроилеит.jpg`
      },
      description:
        `
**Сакроилеит -**  воспаление крестцово-подвздошный сустав. Воспалительный процесс может распространяться на синовиальную оболочку, затрагивать суставные поверхности  или весь сустав.

### Клиническая картина
- Боль в области крестцово-подвздошного сустава при движении, при надавливании, иногда в покое
- Иногда повышение температуры в области сустава
- Затруднение при движении, вынужденное положение с согнутыми ногам
### Лечение
Лечение подбирается врачом в зависимости от этиологии, патогенеза, степени поражения сустава и наличия сопутствующих заболеваний.
#### *Консервативное* 
##### Коррекция образа жизни 
- ограничение нагрузки на сустав, 
- витаминизированная диета с ограничением поваренной соли, 
- ограничение курения и алкоголя.
##### Медикаментозное лечение 
- анальгетики при болях,
- антибиотикотерапия (при бактериальном сакроилеите)
- противовоспалительная терапия («блокады», НПВС,)
- противоотечная терапия, венотоники,
- препараты, укрепляющие суставной хрящ. 
##### Физиотерапевтические методы 
- скэнар-терапия, 
- фонофорез,
- МЛТ 
- ударно-волновая терапия. 
##### Ортобиологические методы  
- PRP-терапия, 
- SVF-терапия.
#### *Оперативное*
В более запущенных случаях, а также при неэффективности консервативной терапии показано хирургическое вмешательство.
`
    },

    {
      name: `Симфизит`,
      propsForCardDoctor: {
        h: `Симфизит`,
        p: "",
        visualContent: `${assetsLocation}Симфизит.jpg`
      },
      description:
        `
**Симфизит** – это патология костей таза, заключающаяся в увеличении щели лонного сочленения, развитии болевого синдрома в симфизе и воспалительного процесса в суставе. Чаще всего аномалия появляется в I триместр беременности, и ее выраженность нарастает по мере приближения родов. Она отмечается у 50% беременных. У некоторых женщин тазовая боль сохраняется и после родов на протяжении 4-6 месяцев. В 25% случаев дисфункция симфиза требует лечения.

### Клиническая картина

*   боль в области лобка и бедер
*   крепитацию и боль при прикосновении к лобку
*   иррадиация боли в крестец,
*   ограниченная подвижность,
*   скованность при разведении ног в стороны, приседании, наклонах,
*   трудности при подъеме по лестнице,
*   нарушение подъема выпрямленных ног из положения лежа,
*   быстрая утомляемость при ходьбе,
*   «утиная походка».

### Лечение

Лечение подбирается врачом в зависимости от тяжести заболевания, степени повреждения и наличия сопутствующих заболеваний.

#### Консервативное

##### Коррекция образа жизни

*   ограничение нагрузки,
*   ограничить подъемы по лестницам,
*   не сидеть дольше одного часа,
*   не сидеть, положив ногу на ногу,
*   в положении стоя распределять вес на обе ноги равномерно,
*   витаминизированная диета, продукты, богатые кальцием,
*   нормализация веса,
*   бандаж,

*   Медикаментозное лечение
*   препараты кальция,
*   витамин Д,
*   анальгетики при болях,
*   венотоники,
*   препараты, укрепляющие суставной хрящ.

##### Физиотерапевтические методы

*   скэнар-терапия,
*   фонофорез,
*   МЛТ,
*   механотерапия,
*   массаж,
*   ЛФК.

#### Оперативное

Хирургическое лечение лонных расхождений показано при ширине диастаза более 30-50 мм, наличии разрывов крестцово-подвздошных сочленений, открытых повреждений, отсутствии эффекта от консервативной терапии, наличии урологических расстройств. Предпочтение отдается внутренней фиксации с использованием пластин и винтов.
`
    },

    {
      name: `Кокцигодиния`,
      propsForCardDoctor: {
        h: `Кокцигодиния`,
        p: "",
        visualContent: `${assetsLocation}Кокцигодиния.jpg`
      },
      description:
        `
**Кокцигодиния -**  боль в копчике, усиливающаяся при надавливании на него. Боли могут длиться годами, не поддаваясь никакому лечению.
### Клиническая картина
- упорный болевой синдром, усиливающийся при надавливании на область копчика
  функциональные расстройства внутренних органов, в первую очередь малого таза, затем брюшной полости,
- асимметрия опороспособности при сидении, влекущая за собой сколиотические изменения позвоночника, что приводит к изменениям опорно-двигательного стереотипа,
- возможно проявление астено-невротических и депрессивных реакций: бессонницы, раздражительности, утомляемости, снижение работоспособности и эмоционального тонуса, формируются тревожно фобические черты личности (страх «неизлечимого» заболевания)
### Лечение
Лечение подбирается врачом в зависимости от этиологии, патогенеза, степени поражения сустава и наличия сопутствующих заболеваний.
#### *Консервативное* 
##### Коррекция образа жизни 
- избегать «сидячего» образа жизни
- избегать физических нагрузок, особенно подъема тяжестей
- уменьшить механического раздражения копчика и структур, его окружающих 
- коррекция питания для нормализации «стула» - избегать запоров
##### Медикаментозное лечение 
- анальгетики при болях,
- пресакральная новокаиновая или лидокаиновая «блокада»
- противовоспалительная терапия НПВС
- противоотечная терапия, венотоники,
##### Физиотерапевтические методы 
- ректальный массаж спастически сокращенных глубоких мышц тазового дна,
- скэнар-терапия области крестца,
- ультразвук,
- лазеротерапия,
- магнитотерапия,
- электрофорез новокаином,
- мануальная терапия. 
#### *Оперативное*
В более запущенных случаях, а также при неэффективности консервативной терапии показано хирургическое вмешательство.

- экономная резекция копчика,
- кокцигоэктомия.
      `
    },

    {
      name: `Тендиноз задней группы мышц бедра`,
      propsForCardDoctor: {
        h: `Тендиноз задней группы мышц бедра`,
        p: "",
        visualContent: `${assetsLocation}Тендиноз задней группы мышц бедра.jpg`
      },
      description:
        `
**Тендиноз задней группы мышц бедра (хамстринг-синдром, ARS-синдром)** - заболевание, характеризующееся воспалительным процессом в связках задней группы мышц бедра и в тканях вокруг них. Очаг воспаления иногда распространяется по всему сухожилию. При постоянных травмах развивается хронический тендинит. Обычно наблюдается у спортсменов (бегуны, футболисты, хоккеисты, легкоатлеты) при неправильно организованных тренировках.

### Клиническая картина

*   боль в области ягодицы и задней поверхности бедра,
*   боль при пальпации седалищного бугра в положении максимального разгибания т/бедренного и коленного суставов,
*   хруст и отек после выраженной травмы
*   затруднение при движении.

### Лечение

Лечение подбирается врачом в зависимости от тяжести травмы, степени повреждения и наличия сопутствующих заболеваний.

#### Консервативное

##### Коррекция образа жизни

*   ограничение нагрузки,
*   аппликации холодом,
*   витаминизированная диета с ограничением поваренной соли.

##### Медикаментозное лечение

*   анальгетики,
*   противовоспалительная терапия,
*   «блокады» с глюкокортикостероидами,
*   противоотечная терапия, венотоники,
*   миорелаксанты,
*   препараты, укрепляющие суставной хрящ, связки, сухожилия.

##### Физиотерапевтические методы

*   скэнар-терапия,
*   фонофорез с гидрокортизоном,
*   ударно-волновая терапия,
*   мануальная терапия.

##### Ортобиологические методы

*   PRP-терапия.

#### Оперативное

Хирургическое лечение показано в случае полного разрыва связок, отрыва мышцы с костным фрагментом, обширной гематомы при травме, а также при отсутствии эффекта от консервативного лечения.

При несвоевременном обращении к врачу и запоздалом лечении может развиться хронический тендинит, фиброз сухожильного пространства, невропатия седалищного нерва, трудно поддающиеся лечению.
`
    },
    {
      name: `Вертельный бурсит (трохантерит, тенденит большого вертела)`,
      propsForCardDoctor: {
        h: ``,
        p: "",
        visualContent: `${assetsLocation}Вертельный бурсит (трохантерит, тенденит большого вертела).jpg`
      },
      description:
        `
**Трохантерит (вертельный бурсит, тенденит большого вертела) -**  это дегенеративно-воспалительное заболевание бедренных сухожилий, при котором они поражаются в месте соединения их с бедренной костью. Это место называется большим вертелом, или трохантером (от латинского trochanter). Встречается заболевание чаще у женщин старше 45 лет, так как в период постменопаузы происходит ослабление связочного аппарата.

### Клиническая картина
- боль в области большого вертела, 
- боль появляется в положении на боку;
- усиление боли при надавливании на зону большого вертела, 
- иррадиация боли по внешней поверхности бедра,
- усиление боли при активном движении,
- в покое болевые ощущения проходят,
- диапазон движения в суставе не нарушен;
### Лечение
Лечение подбирается врачом в зависимости от этиологии, патогенеза, степени поражения сустава и наличия сопутствующих заболеваний.
#### *Консервативное* 
##### Коррекция образа жизни 
- избегать физических нагрузок,
- ходить с тростью 
##### Медикаментозное лечение 
- анальгетики при болях 
- противовоспалительная терапия НПВС
- противоотечная терапия, венотоники,
- корректоры метаболизма хрящевой ткани 
- «блокада» с глюкокортикостероидами при упорном болевом синдроме
##### Физиотерапевтические методы 
- скэнар-терапия области большого вертела,
- фонофорез с гидрокортизоном,
- МЛТ,
- массаж,
- мануальная терапия.
- постизометрическая релаксация,
- ЛФК 
#### *Оперативное*
Хирургическое вмешательство показаго в случае гнойного воспалительного процесса. В этом случае вскрывают сухожильную сумку, содержащую гнойный экссудат, очищают ее и проводят тщательное промывание антисептиком.
      `
    },
    {
      name: `Коксартроз`,
      propsForCardDoctor: {
        h: `Коксартроз`,
        p: "",
        visualContent: `${assetsLocation}Коксартроз.jpg`
      },
      description:
        `
**Коксартроз -** дегенеративно-дистрофическое заболевание тазобедренного сустава, при котором в патологический процесс вовлекаются синовиальная оболочка сустава, костно-хрящевой компонент, сухожилия, мышцы; часто поражаются оба сустава.
### Клиническая картина
- боль в суставе при движении, в покое, по ночам,
- иррадиация боли в колено, бедро, паховую область,
- ограниченная подвижность,
- нарушения ходьбы, хромота,
- атрофия мышц бедра,
- укорочение поврежденной конечности.
### Лечение
Лечение подбирается врачом в зависимости от этиологии, патогенеза, степени поражения сустава и наличия сопутствующих заболеваний.
#### *Консервативное* 
##### Коррекция образа жизни 
- ограничение нагрузки на сустав, 
- использование трости,
- нормализация массы тела, 
- сбалансированная диета,
- нормализация физической активности,
- здоровый сон, 
- ограничение курения и алкоголя.
##### Медикаментозное лечение 
- анальгетики при болях,
- противовоспалительная терапия НПВС
- противоотечная терапия, венотоники, 
- «блокада» с глюкокортикостероидами, 
- корректоры метаболизма хрящевой ткани
- протезы синовиальной жидкости

##### Ортобиологические методы  
- PRP-терапия, 
- SVF-терапия.
##### Физиотерапевтические методы 
- массаж,
- скэнар-терапия,
- фонофорез,
- лазеротерапия,
- магнитотерапия,
- мануальная терапия,
- ЛФК. 
#### *Оперативное*
При упорном болевом синдроме, обширном поражении хрящевой ткани сустава и неэффективности консервативной терапии показано хирургическое вмешательство.

- эндопротезирование. 
`
    },
    {
      name: `Асептический некроз головки бедренной кости`,
      propsForCardDoctor: {
        h: `Асептический некроз головки бедренной кости`,
        p: "",
        visualContent: `${assetsLocation}Асептический некроз головки бедренной кости.jpg`
      },
      description:
        `
**Асептический некроз головки бедренной кости** – это разрушение головки бедренной кости, вызванное недостаточным (временным или постоянным) кровоснабжением кости, что приводит к гибели костной ткани.  
### Клиническая картина
- боль в суставе при движении, в покое, по ночам
- иррадиация боли в колено, бедро, пах,
- ограниченная подвижность,
- нарушения ходьбы, хромота,
- атрофия мышц бедра,
- укорочение поврежденной конечности.
### Лечение
Лечение подбирается врачом в зависимости от тяжести заболевания,  степени повреждения и наличия сопутствующих заболеваний.
#### *Консервативное* 
##### Коррекция образа жизни 
- ограничение нагрузки на сустав, 
- использование трости, ходунков, костылей, 
- нормализация веса,
- витаминизированная диета с ограничением поваренной соли, 
- ограничение курения и алкоголя.
##### Медикаментозное лечение 
- анальгетики, 
- противовоспалительная терапия,
- противоотечная терапия,
- венотоники, 
- препараты, укрепляющие суставной хрящ,
- препараты ремодуляции костной ткани
##### Физиотерапевтические методы 
- скэнар-терапия, 
- фонофорез, 
- МЛТ,
- ударно-волновая терапия, 
- ЛФК.
##### Ортобиологические методы  
- PRP-терапия, 
- SVF-терапия.
#### *Оперативное*
Хирургическое лечение направлено на декомпрессию некротизированного участка кости, а в тяжелых случаях показано эндопротезирование сустава.
`
    },
    {
      name: `Остеохондропатия головки бедренной кости (болезнь Легга-Кальве-Пертеса)`,
      propsForCardDoctor: {
        h: `Остеохондропатия головки бедренной кости (болезнь Легга-Кальве-Пертеса)`,
        p: "",
        visualContent: `${assetsLocation}Остеохондропатия головки бедренной кости (болезнь Легга-Кальве-Пертеса).jpg`
      },
      description:
        `
**Остеохондропатия головки бедренной кости (болезнь Легга-Кальве-Пертеса) –** (остеохондропатия головки бедренной кости, ювенильный идиопатический аваскулярный некроз) — заболевание бедренной кости и тазобедренного сустава, вызванное нарушением кровоснабжения головки бедренной кости с последующим некрозом и восстановлением костной структуры. Средний возраст пациентов: 4–14 лет.

### Клиническая картина

*   на ранних этапах незначительная боль в суставе
*   чаще процесс односторонний
*   иррадиация боли в колено,
*   ограниченная подвижность,
*   нарушение ротации, отведения
*   усталость при ходьбе, хромота,
*   атрофия мышц бедра,
*   укорочение поврежденной конечности.

### Лечение

Лечение подбирается врачом в зависимости от тяжести заболевания, степени повреждения и наличия сопутствующих заболеваний.

#### Консервативное

##### Коррекция образа жизни

*   полное ограничение нагрузки на сустав,
*   использование костылей с момента постановки диагноза,
*   витаминизированная диета,
*   ортезирование,
*   использование шин, гипсование.

##### Медикаментозное лечение

*   анальгетики при болях,
*   венотоники,
*   корректоры метаболизма хрящевой ткани,
*   протезы синовиальной жидкости,
*   препараты ремодуляции костной ткани

##### Физиотерапевтические методы

*   скэнар-терапия,
*   фонофорез с кокарбоксилазой,
*   МЛТ,
*   механотерапия,
*   ЛФК.

##### Ортобиологические методы

*   PRP-терапия,
*   SVF-терапия.

#### Оперативное

Хирургическое лечение показано при II–III стадии заболевания и направлено на улучшение кровоснабжения проксимального отдела бедра и устранение биомеханических нарушений в тазобедренном суставе, возникших вследствие деформации головки бедренной кости. Производят туннелизацию шейки бедра, введение в канал костного трансплантата, подвертельную остеотомию с центрацией головки бедра.
`
    },
    // {
    //   name: ``,
    //   propsForCardDoctor: {
    //     h: ``,
    //     p: "",
    // visualContent: `${assetsLocation}.jpg`
    //   },
    //   description: 
    // `
    // `
    // },
  ],
};