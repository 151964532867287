<template>
  <div id="the-human-body">
    <div class="card-header">
      <h4 class="big-text">Что у вас болит ?</h4>
      <p class="small-text">Щелкните мышью на картинке</p>
    </div>

    <div class="body">
      <picture>
        <source srcset="../assets/diseases/zBody.png 1x" type="image/webp" />
        <source srcset="../assets/diseases/zBody.png 1x" type="image/png" />
        <img src="../assets/diseases/zBody.png" />
      </picture>
      <a
        class="point"
        v-for="i in generalDiseases"
        :key="i.location"
        href="#"
        :style="{ left: i.pointCoord.left, top: i.pointCoord.top }"
        @click.prevent="
          $router.push(`/${siteSections.diseases.sectionPath}/${i.location}`)
        "
      >
        <p>{{ i.location }}</p>
        <img
          class="disease-img"
          :src="require(`@/assets/${i.imgPath}`)"
          :style="{ left: i.pointCoord.left, top: i.pointCoord.top }"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("ModuleSections", ["siteSections"]),
    ...mapState("ModuleDiseases", ["generalDiseases"]),
  },
};
</script>

<style lang="scss" scoped>
@import "../theme.scss";
#the-human-body {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;
}
.body {
  height: 680px;
  width: 350px;
  min-width: 350px;
  position: relative;
  picture img {
    position: absolute;
    transform: translate(30px, 0px);
    height: 100%;
  }
  .point {
    border: 4px solid $darker;
    box-shadow: 1px 1px 2px rgb(36, 36, 36);
    text-shadow: 1px 1px 2px rgb(29, 29, 29);
    background-color: rgba(49, 49, 49, 0.15);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    text-align: center;
    font-weight: bolder;
    position: absolute;
    text-decoration: none;
    transition: background-color, transform, color 0.3s $transition;
    text-transform: uppercase;
    p {
      transform: translate(0%, 50%);
      font-weight: 800;
      color: white;
    }
  }
}
// - Mobile
@media screen and (max-width: 700px) {
  .body {
    height: 680px;
    width: 350px;
    min-width: 350px;
    position: relative;
    picture img {
      margin: 0 auto;
      transform: translate(30px, 0px);
      height: 100%;
      object-fit: contain;
    }
  }
  .point {
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    .disease-img {
      display: none;
    }
    &:hover {
      transition: transform 0.3s $transition;
      background-color: rgba(138, 138, 138, 0.26); //$ Change button background
      color: white;
      // box-shadow: 0px 0px 1000px 2000px rgba(0, 0, 0, 0.774);
    }
  }
}
@media screen and (min-width: 700px) {
  .card-header {
    margin-bottom: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
  .point {
    width: 80px;
    height: 80px;
    line-height: 80px;
    transition: background-color, transform 0.6s;
    &:hover {
      border: none;
    }
    .disease-img {
      display: flex;
      transform: translateY(-20px) scale(1); //$Scale
      width: 100%;
      height: 100%;
      line-height: 80px;
      border-radius: 50%;
      opacity: 0;
    }

    &:hover .disease-img {
      opacity: 1;
      box-shadow: 0px 0px 17px 4px $danger;
      background: $light-tertiary;
      transform: translateY(-20px) scale(1.5);
      transition: background-color, transform, opacity 0.6s $transition;
      animation: animateHeart 1.8s infinite 1.2s;
    }
  }
}

$scale: 1.5;
$translate: translateY(-20px);
@keyframes animateHeart {
  // scale down and scale up faster in irregular intervals to get the throbbing effect
  0% {
    transform: translateY(-20px) scale($scale);
  }
  5% {
    transform: translateY(-20px) scale($scale + 0.2);
  }
  10% {
    transform: translateY(-20px) scale($scale);
  }
  15% {
    transform: translateY(-20px) scale($scale + 0.1);
  }
  50% {
    transform: translateY(-20px) scale($scale);
  }
  100% {
    transform: translateY(-20px) scale($scale);
  }
}
</style>
