/* eslint-disable */
export default {
  namespaced: true,
  state() {
    return {
      aboutMe: {
        carrousel: [
          //- The name is also the image name.
          //- Path @/assets/aboutMe/carrousel/ was pre added
          //- Extension .webp and jpg was pre-added
          {
            name: 'Сертификат организации здравоохранения 2020',
            year: '2019',
            description: "",
          },
          {
            name: 'Сертификат травматология и ортопедия 2019',
            year: '2019',
            description: "",
          },
          {
            name: 'Звание доцента',
            year: '2019',
            description: "",
          },
          {
            name: 'Диплом кандидата наук',
            year: '2019',
            description: "",
          },
          {
            name: 'Диплом мединститут',
            year: '2019',
            description: "",
          },
          {
            name: 'Диплом медицинская реавилитация',
            year: '2019',
            description: "",
          },
        ],
      },
    };
  },
};