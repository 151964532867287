<template>
  <div id="list-of-diseases">
    <div class="card-header">
      <h4 class="big-text">{{ getTheDiseaseGroup.group }}</h4>
      <p class="small-text">Прочитайте о своей болезни</p>
    </div>
    <ol>

      <li v-for="disease in getTheDiseaseGroup.listOfDiseases" :key="disease">
        <button href="#" :class="{ operation: disease.operation == true, minimallyInvasive: disease.minimallyInvasive == true }" @click.prevent="
                    $router.push(
        `/${siteSections.diseases.sectionPath}/${getTheDiseaseGroup.location}/${disease.name}`
        )
        " v-text="disease.name">
        </button>
      </li>
    </ol>

    <div class="image-container">
      <img src="" alt="" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapState("ModuleSections", ["siteSections"]),
    ...mapGetters("ModuleDiseases", ["getTheDiseaseGroup"]),
  },
};
</script>
<style lang="scss" scoped>
@import "../theme.scss";

#list-of-diseases {
  height: 100%;
  width: 100%;
  margin-bottom: auto;
}

ol {
  list-style: none;
  counter-reset: item;
  width: 100%;
}

li {
  display: block;

  border-bottom: 3px dotted rgb(208, 208, 209);
  list-style-type: none;
  counter-increment: item;
  margin-bottom: $dim-s;
  padding: $dim-s 0;

  button {
    width: calc(100% - $dim-s - $dim-m);
    height: fit-content !important;
    text-align: left;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: $fs-s;
    font-weight: 600;
    color: $light-text-color;
    padding: $dim-m 0;
    line-height: 20px;

    &.operation {
      &:hover {
        color: #ea411b;
      }

      &::before {
        margin-right: $dim-m;
        width: auto;
        content: "Оперативное лечение • ";
        color: #db3a16;
        text-align: center;
        display: inline-block;
        font-weight: 700;
        font-size: $fs-s;
      }
    }
    &.minimallyInvasive {
      &:hover {
        color: #ea861b;
      }

      &::before {
        margin-right: $dim-m;
        width: auto;
        content: "Малоинвазивное лечение • ";
        color: #ea861b;
        text-align: center;
        display: inline-block;
        font-weight: 700;
        font-size: $fs-s;
      }
    }


    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }


  &::before {
    margin-right: $dim-m;
    width: $dim-s;
    content: counter(item);
    color: $light-primary;
    text-align: center;
    display: inline-block;
    font-weight: 700;
    font-size: $fs-s;
  }
}
</style>
