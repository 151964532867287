/* eslint-disable */
export default {
  namespaced: true,
  state() {
    return {

    }
  },
  getters: {
    getDoctorCardContent(state, getters, rootState, rootGetters) {
      let lastSubdirectory = rootState.$routeFullPath.at(-1);
      let numberOfSubdirectories = rootState.$routeFullPath.length;

      const getGeneralDisease = () => {
        return rootState.ModuleDiseases.generalDiseases.find((item) => {
          return item.location == rootState.$routeFullPath[2];
        });
      }
      const getAboutDisease = () => {
        let generalDiseaseItem = getGeneralDisease();
        return generalDiseaseItem.listOfDiseases.find(
          (aboutDisease) => {
            return aboutDisease.name == rootState.$routeFullPath[3];
          }
        );
      }

      let firstSubdirectory = rootState.$routeFullPath[1];
      let sections = {
        diseases: {
          path: rootState.ModuleSections.siteSections.diseases.sectionPath,
          subdirectory1: {
            h: '',
            p: `Я много лет специализируюсь на лечении травм и заболеваний опорно-двигательной системы как консервативным, так и оперативным путем. 
            Цель моей работы – улучшить Ваше здоровье и повысить качество жизни даже в самом тяжелом случае.`,
            visualContent: 'горбатенко.jpg'
          },
          subdirectory2: getGeneralDisease,
          subdirectory3: getAboutDisease
        },
        rostov: {
          path: rootState.ModuleSections.siteSections.rostov.sectionPath,
          subdirectory1: {
            h: 'Прием в Ростове',
            // h: 'Медцентр «АртроЛига»',
            p: "АртроЛига — медицинский центр для всей семьи в г. Ростове-на-Дону был открыт в 2018 году. Этот центр предлагает своим пациентам услуги по направлениям: травматология и ортопедия, ревматология, неврология, кардиология и др. Я собрал команду классных специалистов для лечения самых разных пациентов, взрослых и детей. Мы работаем с постоянным отслеживанием результатов, применяя самые прогрессивные методики лечения.",
            visualContent: 'rostov/sidebar.jpg'
          },
        },
        taganrog: {
          path: rootState.ModuleSections.siteSections.taganrog.sectionPath,
          subdirectory1: {
            // h: 'Реабилитационный центр «Медиана»',
            h: 'Прием в Таганроге',
            p: `Я - главный врач и травматолог-ортопед медицинского центра реабилитации "Медиана" в г.  Таганроге. Вы можете получить консультацию, лечение, физиопроцедуры и кинезиотерапию у меня или других специалистов, и пройти индивидуальную программу реабилитации, а также записаться ко мне на операцию.`,
            visualContent: 'taganrog/sidebar.jpg'
          },
        },
        aboutMe: {
          path: rootState.ModuleSections.siteSections.aboutMe.sectionPath,
          subdirectory1: {
            h: 'Обо мне',
            p: `Травматолог ортопед высшей категории, к.м.н., доцент кафедры травматологии и ортопедии Ростовского государственного медицинского университета`,
            visualContent: 'aboutMe/sideBar.jpg'
          },
        },
      }
      const getCurrentSection = (obj) => Object.keys(obj).find((i) => obj[i].path === firstSubdirectory);
      let currentSection = getCurrentSection(sections);
      switch (numberOfSubdirectories) {
        case 2:
          if (lastSubdirectory == '') {
            return {
              h: 'Gorbatenko.doctor 👋',
              p: "Горбатенко Андрей Иванович, врач хирург, травматолог-ортопед высшей категории, кандидат медицинских наук, доцент кафедры травматологии и ортопедии Ростовского государственного медицинского университета",
              visualContent: 'горбатенко.jpg'
            };
          }
          return sections[currentSection].subdirectory1;
        case 3:
          return sections[currentSection].subdirectory2().propsForCardDoctor;
        case 4:
          return sections[currentSection].subdirectory3().propsForCardDoctor;
        default:
          return 'This is the Home page'
      }
    }
  }
}
