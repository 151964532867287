<template>
  <div id="list-of-diseases">
    <div class="card-header">
      <h1 class="big-text">{{ showAboutDisease.name }}</h1>
    </div>
    <Markdown :source="showAboutDisease.description" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Markdown from "vue3-markdown-it";

export default {
  computed: {
    ...mapGetters("ModuleDiseases", ["showAboutDisease"]),
    ...mapGetters("ModuleDiseases", ["getTheDiseaseGroup"]),
  },
  components: {
    Markdown,
  },
};
</script>
<style lang="scss">
@import "../theme.scss";

#list-of-diseases {
  height: 100%;
  width: 100%;
  max-width: 800px;
  text-align: justify;
  margin-bottom: auto;
}

.card-header {
  margin-bottom: 16px;
}

p,
li,
a,
span {
  color: $paragraph;
}

a {
  &:hover {
    text-decoration: underline;
  }
}

ul {
  list-style: square;
  margin-left: $dim-l; //- l - for desktop; m - for mobile;
  width: 100%;

  & * {
    list-style-type: square;
    margin-bottom: $dim-xs;
  }
}

@media screen and (max-width: 1000px) {
  ul {
    margin-bottom: $dim-xs;
    list-style-type: disc;
  }
}

#list-of-diseases p {
  margin-top: 16px;
  margin-bottom: 4px;
  text-align: left;
}

#list-of-diseases h3 {
  //Клиническая картина, Лечение
  margin-top: 28px;
  margin-bottom: 8px;
  font-size: 26px;
  border-bottom: solid $dim-s $bgc-complementary;
}

#list-of-diseases h4 {
  //Консервативное, Оперативное
  margin-bottom: 4px;
  font-size: 24px;
  border-bottom: solid $dim-s $bgc-complementary;
}

#list-of-diseases h5 {
  //Диагноз основного заболевания: |  Задачи:
  margin-top: 16px;
  font-size: 20px;
}

#list-of-diseases em {
  margin-top: 8px;
  margin-bottom: 24px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font: red;
}

#list-of-diseases img {
  margin-top: 32px;
  margin-bottom: 8px;
  width: 90% !important;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 8px;
}
</style>
