<template>
  <Transition name="bounce">
    <div
      class="item-expanded-container"
      :class="{ expand: $store.state.isExpanded }"
      v-if="expand"
    >
      <img :src="require(`@/assets/${expandedImagePath}`)" alt="" />
      <button class="close-button" @click="close">
        <inline-svg
          :src="require(`@/assets/close.svg`)"
          fill-opacity="1"
          color="white"
          width=" 22px"
          height="22px"
          stroke="30px"
        />
      </button>
    </div>
  </Transition>
</template>

<script>
export default {
  props: ["expand", "expandedImagePath"],
  data() {
    return {
      expand_copy: this.expand,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../theme.scss";
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: translate(8px, $dim-l) scale(0);
  }
  50% {
    transform: translate(8px, $dim-l) scale(1.1);
  }
  100% {
    transform: translate(8px, $dim-l) scale(1);
  }
}
// - Expanded Carrousel Item - Patent
.item-expanded-container {
  top: 0;
  width: calc(95% - $sidebar-width);
  margin-top: $navbar-height;
  height: calc(
    100vh - $navbar-height - ($dim-l * 2)
  ); //$ minus margin on top and bottom (then translate to the same value /2 )
  transform: translate(
    8px,
    //$Keeps the window perfectly centered on X
    $dim-l //$Keep the window perfectly centered on Y
  );
  position: fixed;
  border-radius: $rad-l;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $dark;
  z-index: 3;
  img {
    height: 100%;
    max-width: 100%;
    padding: $dim-s;
  }
}
.item-expanded-container.expand {
  width: calc(95% - $sidebar-width-expand);
  img {
    height: 100%;
    max-width: 100%;
    padding: $dim-s;
  }
}

// - Close button
.close-button {
  position: absolute;
  top: $dim-l + 10px;
  right: $dim-l;
  border: none;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  border-radius: 12px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  transition: all 0.25s ease, box-shadow 0.25s ease;
  box-shadow: none;
  border: none;
  z-index: 2;
  cursor: pointer;
  transition: transform 0.25s ease-in-out;
  transform: translateY(0px);
  background-color: $primary;
  &:hover {
    box-shadow: 0 6px 10px -5px rgb(0 0 0 / 10%);
    transition: transform 0.25s ease-in-out;
    transform: translateY(-1.5px);
    background-color: $primary-hover;
  }
  svg {
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  &:hover svg {
    transform: rotate(90deg);
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
}
</style>