<template>
  <div class="section_about-container">
    <div class="card_about-container item">
      <div class="card-about">
        <strong>Запись на прием:</strong>

        <a
          v-for="number in clinic.number.number"
          :key="number"
          :href="`tel:${number}`"
          >{{ formatPhone(number) }}</a
        >

        <a :href="`mailto:${clinic.email}`">{{ clinic.email }}</a>

        <div class="social-media-buttons">
          <a
            target="_blank"
            :href="`https://wa.me/${clinic.number.whatsapp}`"
            title="Написать в WhatsApp"
          >
            <app-button type="whatsapp"></app-button>
          </a>
          <a
            target="_blank"
            title="Написать в Telegram"
            :href="`https://t.me/${clinic.number.telegram}`"
          >
            <app-button type="telegram"></app-button>
          </a>
        </div>
      </div>
      <div class="card-about">
        <strong>Часы работы:</strong>
        <p>
          <span>{{ clinic.weekdays.days }}</span
          >{{ clinic.weekdays.time }}
        </p>
        <p>
          <span>{{ clinic.weekend.days }}</span
          >{{ clinic.weekend.time }}
        </p>
      </div>
      <div class="card-about">
        <strong>Переход на сайт:</strong>
        <a :href="clinic.site.url" target="_blank"
          ><img
            id="preview"
            :src="require(`@/assets/${clinic.site.sitePreview}`)"
            :alt="clinic.name.fullName"
        /></a>
        <a :href="clinic.site.url" target="_blank">{{ clinic.site.url }}</a>
      </div>
    </div>

    <GoogleMap
      class="map"
      api-key="AIzaSyBcadc5HbMF6QLzCnN2pPNMcVUtYf6EIB8"
      :center="clinic.coordinates"
      :zoom="15"
      :mapTypeControl="false"
      :streetViewControl="false"
      gestureHandling="cooperative"
    >
      <InfoWindow
        :options="{
          position: {
            lat: clinic.coordinates.lat,
            lng: clinic.coordinates.lng,
          },
        }"
      >
        <div class="clinic-info_window">
          <img
            class="clinic-logo"
            :src="require(`@/assets/${clinic.logo}`)"
            :alt="clinic.name.fullName"
          />
          <h5>{{ clinic.name.shortName }}</h5>
          <small>{{ clinic.address.city }}</small>
          <small>{{ clinic.address.street }}</small>
          <a target="_blank" :href="clinic.address.route"
            ><small>Создать маршрут</small></a
          >
        </div>
      </InfoWindow>
      <Marker
        class="marker-position"
        :options="{
          position: {
            lat: clinic.coordinates.lat,
            lng: clinic.coordinates.lng,
          },
          label: clinic.name.shortName,
        }"
      >
        <InfoWindow
          :options="{
            position: {
              lat: clinic.coordinates.lat,
              lng: clinic.coordinates.lng,
            },
          }"
        >
          <div class="clinic-info_window">
            <img
              class="clinic-logo"
              :src="require(`@/assets/${clinic.logo}`)"
              :alt="clinic.name.fullName"
            />
            <h5>{{ clinic.name.shortName }}</h5>
            <small>{{ clinic.address.city }}</small>
            <small>{{ clinic.address.street }}</small>
            <a target="_blank" :href="clinic.address.route"
              ><small>Создать маршрут</small></a
            >
          </div>
        </InfoWindow>
      </Marker>
    </GoogleMap>
  </div>
</template>

<script>
import { GoogleMap, Marker, InfoWindow } from "vue3-google-map";
import AppButton from "../components/AppButton.vue";

export default {
  components: { GoogleMap, Marker, InfoWindow, AppButton },
  props: ["clinic"],
  methods: {
    formatPhone(phone) {
      if (phone.length === 12) {
        var countryCodeRu = "7";
        var operatorCode = phone.slice(2, 5);
        var triade = phone.slice(5, 8);
        var pair1 = phone.slice(8, 10);
        var pair2 = phone.slice(10, 12);
        return (
          "+" +
          countryCodeRu +
          "(" +
          operatorCode +
          ")" +
          triade +
          "-" +
          pair1 +
          "-" +
          pair2
        );
      }
      return phone;
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "../theme.scss";
.section_about-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.card-about {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: $dim-s $dim-m;

  strong {
    color: $header;
  }
  span {
    color: #a0a0a0;
  }
  a {
    color: $primary;
    &:hover {
      text-decoration: underline;
    }
  }
}
.clinic-info_window {
  display: flex;
  flex-direction: column;
  .clinic-logo {
    width: 96px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  a {
    color: $primary;
    &:hover {
      text-decoration: underline;
    }
  }
}
// - Wide monitor
@media screen and (min-width: 1300px) {
  #preview {
    width: 100%;
    height: 185px;
    transition: all 200ms ease;
    box-shadow: rgb(17 18 38 / 0%) 0px 0px 4px 0px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $dim-s;
    transform: scale(1);
    &:hover {
      box-shadow: rgba(125, 156, 241, 0.15) 0px 4px 20px 0px;
      transform: scale(1.1);
    }
  }
  .map {
    width: 100%;
    height: 500px;
    box-shadow: $shadow-s;
  }
  .card_about-container {
    position: absolute;
    height: calc(100% - ($dim-s * 2)); //I add a margin to the top and bottom
    width: 350px;
    transform: translateY($dim-s); //I center it, size must be equal the margin
    margin-left: $dim-s;
    z-index: 1;
    border-radius: 5px;
    background-color: #f8f9faed;

    box-shadow: $shadow-xs;
    padding: $dim-xs;
    border-radius: $rad-l;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .social-media-buttons {
      display: flex;
      flex-wrap: wrap;
      a:not(:first-child) {
        margin: 0 $dim-s;
      }
    }
  }
}
// - Mobile
@media screen and (max-width: 1300px) {
  #preview {
    display: none;
  }
  .card-about {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 $dim-m;
    padding-bottom: $dim-s;
  }
  .map {
    width: 100%;
    height: 500px;
    padding: $dim-m;
    background-color: $neutral;
  }
  .item {
    position: relative;
    flex: 0 1 50%;
    flex: 1;
    min-height: 125px;
    height: fit-content;
    min-width: 290px;
    width: 100%;
    max-width: 100%;
    margin-top: $dim-s;
    padding-top: $dim-l;
    padding-bottom: $dim-xs;
    padding-left: $dim-xs;
    padding-right: $dim-xs;

    border-top-left-radius: $rad-m;
    border-top-right-radius: $rad-m;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: $neutral;

    .social-media-buttons {
      margin-top: $dim-s;
      display: flex;
      flex-wrap: wrap;
      & * {
        margin: 0 $dim-xs;
      }
    }
  }
}
</style>
