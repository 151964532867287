/* eslint-disable */
import плечо from "./плечо";
import кисти from "./кисти";
import колено from "./колено";
import позвоночник from "./позвоночник";
import локоть from "./локоть";
import таз from "./таз";
import голеностоп from "./голеностоп";
export default {
  namespaced: true,
  getters: {
    getTheDiseaseGroup(state, getters, globalState, globalGetters) {
      return state.generalDiseases.find(
        (generalDisease) =>
          generalDisease.location === globalState.$routeFullPath[2]
      );
    },
    showAboutDisease(state, getters, globalState, globalGetters) {
      let diseaseGroup = getters.getTheDiseaseGroup;
      if (diseaseGroup != undefined) {
        return diseaseGroup.listOfDiseases.find(
          (e) => e.name === globalState.$routeParams.aboutDisease
        );
      }
      else return false
    }
  },
  state() {
    return {
      generalDiseases: [
        кисти,
        плечо,
        позвоночник,
        локоть,
        таз,
        колено,
        голеностоп,
      ],
    };
  },
};
