<template>
  <div class="gorbatenko_doctor-icon noselect">
    <h2>Gorbatenko</h2>
    <inline-svg
      :src="require(`@/assets/dot.svg`)"
      fill-opacity="1"
      color="white"
      width=" 16px"
      height="16px"
      stroke="0px"
    />
    <small>doctor</small>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../theme.scss";
.gorbatenko_doctor-icon {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 186px;

  h2 {
    width: auto;
    font-size: xx-large;
    font-weight: 600;
    color: $primary;
    cursor: pointer;
    transform: translateX(-1px) translateY(1px);
    -webkit-transform: translateX(-1px) translateY(5px);
    display: flex !important;
    margin: $dim-xs;
    text-decoration: none;
    border-bottom: none;
  }
  svg {
    position: absolute;
    width: 15px;
    color: $primary;
    cursor: pointer;
    font-weight: 500;
    vertical-align: middle;
    font-size: 15px;
    display: inline;
    writing-mode: vertical-rl;
    right: 0;
    bottom: 3px;
    transform: translateX(-4px);
    -webkit-transform: translateX(-4px) translateY(0px);
  }
  small {
    color: $primary;
    font-weight: 600;
    vertical-align: middle;
    font-size: 13px;
    display: inline;
    writing-mode: vertical-rl;
    transform: translateX(-7px) translateY(-15px) rotateZ(180deg);
    -webkit-transform: translateX(-7px) translateY(-15px) rotateZ(180deg);
  }
}
@media screen and (max-width: 1000px) {
  .gorbatenko_doctor-icon {
    h2 {
      font-size: xx-large;
      color: white;
    }
    svg {
      width: 11px;
      color: white;
      font-size: 11px;
      transform: translateX(-7px) translateY(2px);
      -webkit-transform: translateX(-7px) translateY(2px);
    }
    small {
      color: white;
      font-weight: 600;
      vertical-align: middle;
      font-size: 12px;
      display: inline;
      writing-mode: vertical-rl;
      transform: translateX(-7px) translateY(-15px) rotateZ(180deg);
      -webkit-transform: translateX(-9px) translateY(-14px) rotateZ(180deg);
    }
  }
}
</style>
