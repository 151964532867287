<template>
  <div :class="{ 'side-bar': true, expand: $store.state.isExpanded }">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="160"
      height="160"
      viewBox="0 0 160 160"
      class="effect-top-config"
    >
      <path
        id="Trazado_200"
        data-name="Trazado 200"
        d="M0-10,150,0l10,150S137.643,80.734,100.143,43.234,0-10,0-10Z"
        transform="translate(0 10)"
      ></path>
    </svg>
    <div class="content">
      <h4 class="section-title">{{ doctorCardContent.h }}</h4>
      <div class="video-container">
        <div
          class="expand-button"
          @click="$store.state.isExpanded = !$store.state.isExpanded"
        >
          <inline-svg
            v-if="!$store.state.isExpanded"
            :src="require(`@/assets/fullscreen.svg`)"
            title="Увеличить размер"
            fill-opacity="0.8"
            color="#000"
            width=" 24"
            height="24"
            stroke="30px"
          />
          <inline-svg
            v-else
            :src="require(`@/assets/fullscreen_exit.svg`)"
            title="Уменьшить размер"
            fill-opacity="0.8"
            color="#1A5CFF"
            width=" 24"
            height="24"
            stroke="30px"
          />
        </div>
        <picture>
          <!-- <source
            srcset="../assets/aboutMe/горбатенко.jpg 1x"
            type="image/webp"
          />
          <source
            srcset="../assets/aboutMe/горбатенко.jpg 1x"
            type="image/png"
          /> -->
          <img
            :class="{
              noselect: true,
              fullOpacity: $store.state.isExpanded,
            }"
            :src="require(`@/assets/${doctorCardContent.visualContent}`)"
            @click.prevent="$store.state.isExpanded = !$store.state.isExpanded"
          />
        </picture>
        <!-- <iframe
          width="100%"
          height="100%"
          :src="'https://www.youtube.com/embed/tgbNymZ7vqY'"
          allowfullscreen
          allow="accelerometer"
          gyroscope
        >
        </iframe> -->
      </div>
      <div class="description-container">
        <div class="text">
          <div class="social-media-buttons">
            <a href="https://wa.me/79286003105" target="_blank">
              <app-button
                type="whatsapp"
                title="Написать в WhatsApp"
              ></app-button>
            </a>
            <a
              href="https://www.instagram.com/doctor.gorbatenko/"
              target="_blank"
            >
              <app-button
                type="instagram"
                title="Моя страница Instagram"
              ></app-button>
            </a>
            <a href="https://www.facebook.com/gorbatenkophmd" target="_blank">
              <app-button
                type="facebook"
                title="Моя страница Facebook"
              ></app-button>
            </a>
            <a href="http://travmatolog.info/" target="_blank">
              <app-button
                title="Ссылка на старый сайт"
                :bgc="'#253855'"
                :bgcHover="'#466aa1'"
                :icon="'site'"
              ></app-button>
            </a>
          </div>
          <h4>{{ doctorCardContent.h }}</h4>
          <Markdown :source="doctorCardContent.p" />
          <div class="consultation-price">
            <div class="consultation-price doctor-consultation-info">
              <h6>Ростов</h6>
              <p>Стоимость консультации: <span>3500₽</span></p>
              <p>
                Контакт:
                <a href="https://wa.me/79613015171">+7(961)301-51-71</a>
              </p>
            </div>
            <div class="consultation-price doctor-consultation-info">
              <h6>Таганрог</h6>
              <p>Стоимость консультации: <span>2500₽</span></p>
              <p>
                Контакт:
                <a href="https://wa.me/79034025151">+7(903)402-51-51</a>
              </p>
            </div>
          </div>
          <!-- <p>{{ doctorCardContent.p }}</p> -->
        </div>
      </div>
    </div>
    <div class="config" :class="{ expand: $store.state.isExpanded }">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="160"
        height="160"
        viewBox="0 0 160 160"
        class="effect-bottom-config"
      >
        <path
          id="Trazado_200"
          data-name="Trazado 200"
          d="M0-10,150,0l10,150S137.643,80.734,100.143,43.234,0-10,0-10Z"
          transform="translate(0 10)"
        ></path>
      </svg>
      <a href="https://wa.me/89286003105" target="_blank">
        <app-button type="whatsapp" title="Написать в WhatsApp"></app-button>
      </a>
      <a href="https://www.instagram.com/doctor.gorbatenko/" target="_blank">
        <app-button
          type="instagram"
          title="Моя страница Instagram"
        ></app-button>
      </a>
      <a href="https://www.facebook.com/gorbatenkophmd" target="_blank">
        <app-button type="facebook" title="Моя страница Facebook"></app-button>
      </a>
      <a href="http://travmatolog.info/" target="_blank">
        <app-button
          title="Ссылка на старый сайт"
          :bgc="'#253855'"
          :bgcHover="'#466aa1'"
          :icon="'site'"
        ></app-button>
      </a>
    </div>
  </div>
</template>

<script>
import Markdown from "vue3-markdown-it";
import AppButton from "./AppButton.vue";
export default {
  //! Import the doctorCardContent as a property for TheSideBar! You for the image, you receive a undefined from Vuex
  props: ["doctorCardContent"],
  data() {
    return {
      expand: false,
    };
  },
  components: { AppButton, Markdown },
};
</script>

<style scoped lang="scss">
@import "../theme.scss";
.price {
  margin-top: 4px;
  line-height: 1;
  font-weight: 400;
  color: #0115d3;
}
.side-bar {
  background-color: $bgc-bright;
  height: 100%;
  //The standard video width resolution + the side-bar-content padding + the padding fot the video and text container
  width: $sidebar-width;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: $dim-m;
  padding-top: 0;
  position: fixed;
  left: 0;
  z-index: 3;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  .effect-top-config {
    width: 40px;
    height: 40px;
    top: -3px;
    right: calc((-15px / 2) - $dim-l);
    transform: rotate(270deg);
    fill: $bgc-bright;
    position: absolute;
  }
  .content {
    width: 100%;
    height: auto;
    padding: $dim-l $dim-s;
    background-color: $bgc-complementary;
    border-radius: $rad-m;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    //For desktop
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // color: white !important;
    border-bottom-left-radius: $rad-xl;
    border-top-right-radius: $rad-xl;
    .section-title {
      display: none; //! Display none for desktop and flex for mobile
    }
    .video-container {
      -webkit-transition: all 0.25s ease;
      transition: all 0.25s ease;
      position: relative;
      margin-bottom: $dim-s;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
      width: 100%;

      .expand-button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 0;
        width: 48px;
        height: 48px;
        background-color: white;
        transform: translate(2px, -2px) scale(1);
        border-top-right-radius: $rad-m;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 30%);
        z-index: 2;
        transition: 0.3s ease;
        &:hover svg {
          transition: 0.3s ease;
          transform: scale(1.1);
          opacity: 1;
        }
      }
      picture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000000;
        border-radius: $rad-m;
        img {
          border-radius: $rad-m;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          object-fit: cover;
          object-position: 50% 50%;
          opacity: 0.9;
          -webkit-transition: all 0.25s ease;
          transition: all 0.25s ease;
          cursor: pointer;
        }
        img.fullOpacity {
          opacity: 1;
        }
      }
      &:hover img {
        opacity: 1;
        -webkit-transition: all 0.25s ease;
        transition: all 0.25s ease;
      }
      video,
      iframe {
        width: 100%;
        border-radius: $rad-m;
        height: 100%;
        border: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .description-container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: calc(100% - ($dim-m * 2));
      overflow-y: auto;
      .text h4 {
        line-height: 1.1;
        margin-bottom: $dim-xs;
        font-style: normal;
        color: $header;
      }
      .social-media-buttons {
        right: $dim-l + $dim-s;
        display: flex;
        gap: $dim-xs;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        display: none; //$ Only for desktop
      }
    }
  }
}
.side-bar .config {
  height: 48px;
  width: fit-content;
  padding: 10px;
  margin-top: auto;
  position: fixed;
  bottom: 0;
  z-index: 2;
  background-color: $bgc-bright;
  border-top-right-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: $sidebar-width;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  transform: translateX(50%);
  .effect-bottom-config {
    width: 40px;
    height: 40px;
    top: -36px;
    left: -3px;
    position: absolute;
    transform: rotate(179deg);
    fill: $bgc-bright;
  }
}
.config button {
  margin: 0 2px;
}
.config.expand {
  margin-left: $sidebar-width-expand;
}
.side-bar.expand {
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  width: $sidebar-width-expand;
  .video-container {
    width: 100%;
  }
}
.side-bar.expand {
  .config {
    transition: all 0.25s ease;
  }
}
@media screen and (max-height: 715px) and (min-width: 1000px) {
  .description-container {
    max-height: 225px;

    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: $bgc-bright;
      border-radius: $rad-s;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: $rad-s;
      background: $label;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: $primary;
    }
  }
}

// - Mobile
@media screen and (max-width: 1000px) {
  .side-bar {
    background-color: none;
    height: fit-content;
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    z-index: 2;
    // padding: $dim-s; //$ the padding must be also on the article and not in main!, because it affects the background of the sidebar for mobile
    background-color: $bgc-bright;

    .effect-top-config {
      display: none;
    }
    .content {
      color: $text;
      width: 100%;
      border-radius: 0;
      background-color: transparent;
      padding: 0;
      max-width: 600px;
      &:hover {
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
      }
      .video-container {
        -webkit-transition: all 0.25s ease;
        transition: all 0.25s ease;
        position: relative;
        margin-bottom: $dim-s;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
        width: 100%;

        .expand-button {
          display: none;
        }
        picture {
          border-radius: $rad-l;
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          img {
            cursor: default;
            opacity: 1;
            border-radius: $rad-l;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
          }
        }
        video,
        iframe {
          width: 100%;
          border-radius: $rad-m;
          height: 100%;
          border: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .description-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        padding: $dim-l;
        padding-top: $dim-m;
        .text h4 {
          display: flex; //! Display none for desktop and flex for mobile
          line-height: 1.1;
          text-align: center;
          font-size: x-large;
          margin-top: $dim-m;
          margin-bottom: $dim-s;
        }
        .social-media-buttons {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
        .social-media-buttons button {
          height: 48px;
          width: 48px;
          margin-left: $dim-s;
          margin-right: $dim-s;
        }
      }
    }
  }
  .effect-bottom-config {
    display: none;
  }
  .side-bar .config {
    display: none;
  }
  .side-bar.expand {
    width: 100%;
  }
}

.doctor-consultation-info {
  margin-top: 16px;
}
.doctor-consultation-info h6 {
  font-size: 20px;
  margin-bottom: 2px;
}
.consultation-price span,
a {
  font-weight: 400;
  color: #0115d3;
}
</style>
