/* eslint-disable */
var assetsLocation = `diseases/голеностоп/`;
export default {
  location: "Голеностоп",
  group: 'Болезни голеностопных суставов и стоп',
  imgPath: "foot.png",
  pointCoord: { left: "250px", top: "645px" },
  propsForCardDoctor: {
    h: 'Болезни голеностопных суставов и стоп',
    p: "Стопа — сложная и значимая часть организма, выполняющая важнейшие функции опоры, амортизации, балансировки. Нарушения в строении и функции стопы влияют не только на биомеханику движений в нижней конечности, но и на общее состояние здоровья и качество жизни человека.",
    visualContent: `${assetsLocation}Болезни голеностопных суставов и стоп.jpg`

  },
  listOfDiseases: [
    {
      name: `Травмы`,
      propsForCardDoctor: {
        // h: `Травмы`,
        p: `
##### Травмы
*Стоимость операции, от 45 000 ₽*
`,
        visualContent: `${assetsLocation}Травмы.jpg`

      },
      description:
        `
К травмам голеностопного сустава и стопы относятся ушибы мягких тканей, гематомы, частичные повреждения связочного аппарата, переломы и вывихи в голеностопном суставе.

При травмировании голеностопного сустава в ближайшее время необходимо показаться врачу травматологу-ортопеду или хирургу, чтобы точно определить степень повреждения.  
### Клиническая картина
- Боль в области голеностопного сустава при движении, при надавливании, иногда в покое
- Отек в области сустава
- Покраснение кожи и повышение температуры в области сустава
- Затруднение при движении (невозможно наступить на ногу)
### Лечение
Лечение подбирается врачом в зависимости от тяжести травмы,  степени повреждения и наличия сопутствующих заболеваний.
#### *Консервативное*
##### В первые минуты после травмы 
- обездвижить конечность, 
- наложить фиксирующую повязку, 
- холод к месту ушиба. 
##### Коррекция образа жизни 
- ограничение нагрузки на сустав, 
- использование бандажа или ортеза для голеностопного сустава, 
- витаминизированная диета с ограничением поваренной соли, 
- ограничение курения и алкоголя.
##### Медикаментозное лечение 
- анальгетики, 
- противовоспалительная терапия
- противоотечная терапия, 
- препараты, укрепляющие суставной хрящ. 
##### Физиотерапевтические методы 
- скэнар-терапию, 
- фонофорез, 
- ударно-волновую терапию. 
##### Ортобиологические методы  
- PRP-терапия, 
- SVF-терапия.
#### *Оперативное*
При наличии обширного повреждения мягких тканей, разрыва кровеносных сосудов и кровоизлияния в сустав, некроза поврежденных тканей и позднего обращения к врачу, может потребоваться пункция сустава, в более запущенных случаях – артроскопия или серъезное хирургическое вмешательство.

При несвоевременном обращении к врачу и слишком поздно начатом лечении после травмы может развиться гемартроз, бурсит, артроз голеностопного сустава, не поддающийся терапии.
      `,
    },
    {
      name: `Повреждения связок`,
      propsForCardDoctor: {
        // h: `Травмы`,
        p: `
##### Повреждения связок
*Стоимость операции, от 45 000 ₽*
`,
        visualContent: `${assetsLocation}Повреждения связок.jpg`

      },
      description:
        `
**Повреждения связок** — наиболее частая травма области голеностопного сустава. Обычно повреждение отмечается при подворачивании стопы внутрь. При этом происходит растяжение,  частичный либо полный разрыв наружных боковых связок голеностопного сустава или отрыв их от мест прикрепления.
При подозрении на растяжение или разрыв связки в ближайшее время необходимо показаться врачу травматологу-ортопеду или хирургу, чтобы точно определить степень повреждения.  
### Клиническая картина
- Припухлость мягких тканей в области наружной лодыжки. 
- Боль в голеностопном суставе и в области наружной лодыжки, усиливающаяся при движении, особенно в моменты приведения и супинации стопы.
- При пальпации болезненность максимальна по нижнему краю наружной лодыжки и спереди от нее, особенно при приведении и супинации стопы.
- Пассивные движения выявляют максимальную болезненность во время супинации стопы.

Показана рентгенография голеностопного сустава для исключения перелома.
### Лечение
Лечение подбирается врачом в зависимости от тяжести травмы,  степени повреждения и наличия сопутствующих заболеваний.
#### *Консервативное* 
##### Доврачебная помощь:
- обеспечить максимальную неподвижность конечность, 
- наложить 8-образную фиксирующую повязку на сустав, 
- сухой холод к месту ушиба, 
- незамедлительное обращение к врачу травматологу или хирургу. 
##### Коррекция образа жизни: 
- ограничение нагрузки на сустав, 
- при частичном разрыве - использование бандажа или ортеза для голеностопного сустава, при полном разрыве связок – лонгета и/или гипование на 4-8 недель, 
- фиксирующая голеностоп обувь, 
- витаминизированная диета с ограничением поваренной соли, 
- ограничение курения и алкоголя.
##### Медикаментозное лечение 
- анальгетики, 
- НПВС, 
- венотоники, 
- препараты, укрепляющие хрящ.
##### Физиотерапевтические методы начинают применять на 4-5 сутки: 
- скэнар-терапия, 
- фонофорез, 
- ударно-волновую терапия. 
##### Ортобиологические методы активно применяют для восстановления суставного хряща на этапе реабилитации.
- PRP-терапия, 
- SVF-терапия 

#### *Оперативное*

При тяжелых функциональных нарушениях назначают постоянное ношение ортопедической обуви или осуществляют хирургическое восстановление таранно-малоберцовой связки. 
        `
      ,
    },
    {
      name: `Остеоартроз`,
      propsForCardDoctor: {
        // h: `Остеоартроз`,
        p: "",
        visualContent: `${assetsLocation}Остеоартроз.jpg`

      },
      description:
        `
**Остеортроз голеностопного сустава** – дегенеративно-дистрофическое заболевание сустава, при котором поражаются практически все его структуры и прилежащие ткани: субхондральная кость, капсула, синовиальная оболочка, мышечно-связочный аппарат, окружающий сустав. Однако наибольшие изменения происходят в хрящевой ткани.
### Клиническая картина
- боль и дискомфорт локализованы в области голеностопа.
- боль усиливается при пальпации 
- боль при ходьбе, при сгибании, разгибании. 
- в тяжелых случаях – боль в покое.
- отек, чувство распирания в области голеностопа
- нарушение функции сустава: разгибание, сгибание, повороты стопы затруднены или невозможны.
- иногда – локальная гипертермия.

### Лечение:
#### *Консервативное:*
##### Коррекция образа жизни:
- ограничение нагрузки на стопы
- применение ортезов, индивидуальных стелек-супинаторов
- нормализация массы тела (ИМТ менее 25)
- ограничение острой, жирной, соленой пищи
- отказ от вредных привычек
- исключение алкоголя

##### Физиолечение:
- фонофорез с гидрокортизоном
- скэнар-терапия
- МЛТ-терапия
- УВТ-терапия
- массаж стоп
- ЛФК
- бальнеологические процедуры
- озекерит

##### Медикаментозная терапия:
- топические НПВС, капсаицин
- анальгетики, НПВС
- глюкокортикостероиды
- венотоники
- препараты, улучшающие кровообращение
- хондропротекторы
- витаминотерапия
- препараты гиалуроновой кислоты

##### Ортобиологическая терапия
- PRP-терапия

#### *Хирургическое (при неэффективности консервативного лечения)*:

Оперативные вмешательства показаны при длительном течении остеоартроза голеностопных суставов без значимого улучшения

- артроскопия
- эндопротезироание (в тяжелых случаях) 

Так как **остеоартроз голеностопного сустава** является серьезным полиэтиологичным заболеванием (может иметь самые разнообразные причины), для точной постановки диагноза и подбора правильного лечения необходима срочная консультация  врача травматолога-ортопеда или хирурга.
        `
      ,
    },
    {
      name: `Плоскостопие`,
      propsForCardDoctor: {
        // h: `Плоскостопие`,
        p: `
##### Плоскостопие
*Стоимость операции, от 45 000 ₽*
`,
        visualContent: `${assetsLocation}Плоскостопие.jpg`

      },
      description:
        `
**Плоскостопием** называют изменение формы стопы, которое характеризуется опущением ее продольного и поперечного сводов. Существуют различные виды заболевания: продольное и поперечное плоскостопие, врожденное и приобретенное. 
### Клиническая картина
- внутренняя поверхность обуви стирается сильнее;
- в обуви привычного размера становится некомфортно;
- трудно удерживать равновесие, особенно при приседании;
- появляется ощущение тяжести в ногах, 
- меняется походка, появляется косолапость, неуклюжесть
- появляется быстрая утомляемость при ходьбе
- появляется боль в стопах, голенях, позвоночнике

### Лечение:
#### *Консервативное (эффективно в детском возрасте на ранних стадиях):*
##### Коррекция образа жизни:
- ограничение нагрузки 
- применение ортезов, индивидуальных стелек-супинаторов
- подбор ортопедической обуви
- нормализация веса
- хождение босиком

##### Физиолечение:
- скэнар-терапия
- магнитотерапия
- массаж стоп
- УВТ-терапия
- ЛФК
- бальнеологические процедуры
- озекерит

##### Медикаментозная терапия:
- анальгетики, НПВС
- витаминотерапия
- венотоники

#### *Хирургическое:* 
- на мягких тканях - коррекция сухожилий, фасций
- на костях при  необходимости изменить форму и положение деформированных костей
- протезирование имплантами, которые удерживают стопу в правильном положении

        `
      ,
    },
    {
      name: `Плантарный фасциит, пяточная шпора`,
      propsForCardDoctor: {
        // h: `Плантарный фасциит, пяточная шпора`,
        p: `
##### лантарный фасциит, пяточная шпора
*Стоимость операции, от 45 000 ₽*
`,
        visualContent: `${assetsLocation}Плантарный фасциит, пяточная шпора.jpg`

      },
      description:
        `
**Плантарный фасциит** (подошвенная пяточная шпора, подошвенный фасциоз) — это заболевание, обусловленное воспалительно-дегенеративными изменениями плантарной (подошвенной) фасции и проявляющееся длительными упорными болями в области пятки. 
**Пяточная «шпора»** – симптом  хронического плантарного фасциита, разрастание костной ткани в месте хронического повреждения надкостницы при перерастяжении подошвенной фасции.

### Клиническая картина
- Болезнь начинается постепенно с нарастающего дискомфорта, а затем боли вдоль медиального пучка подошвенной фасции. 
- Болевые ощущения более выражены в утренние часы при нагрузке собственным весом и после длительного периода отдыха – при начале движения и нагрузке. 
- Иногда боль может иррадиировать в центр пятки или в плюснефаланговые суставы. 
- Боль может стать упорной и постоянной, особенно к концу дня. 
- Боль может усилиться при поднятии на носочки. 
- Часто бывает двусторонним.

### Лечение:
Оно складывается из **немедикаментозного** – направленного на коррекцию образа жизни, **медикаментозного** – направленного на купирование болевого синдрома, улучшение питания мягких тканей стопы, особенно подошвенной фасции, а также улучшение регенерации фасции; а при при неэффективности консервативного лечения – хирургического удаления «пяточной шпоры». 
#### *Консервативное:*
Немедикаментозная терапия является обязательным условием достижения стойкой ремиссии заболевания. 
##### Коррекция образа жизни:
- соблюдения правил здорового образа жизни, адекватные нагрузки 
- коррекция питания, направленная на нормализацию веса
- применение ортезов, стелек-супинаторов, 
- ношение обуви на невысоком каблуке
##### Физиолечение:
- скэнар-терапия
- магнитотерапия
- массаж стоп
- УВТ
- мануальная терапия
- ЛФК
- бальнеологические процедуры
##### Медикаментозная терапия:
- анальгетики, НПВС
- глюкокортикостероиды
- витаминотерапия
##### Ортобиологическая терапия
- PRP-терапия

#### *Хирургическое (при неэффективности консервативного лечения):* 

- иссечение фасции
- удаление костного выроста 
        `
      ,
    },
    {
      name: `Болезнь Хаглунда`,
      propsForCardDoctor: {
        // h: `Болезнь Хаглунда`,
        p: `
##### Болезнь Хаглунда
*Стоимость операции, от 45 000 ₽*
`,
        visualContent: `${assetsLocation}Болезнь Хаглунда.jpg`

      },
      description:
        `
**Болезнь Хаглунда** (синонимы: остеохондропатия бугра пяточной кости,  синдром Хаглунда-Шинца, синдром Золушки) - асептический некроз пяточного бугра в результате нарушения питания костного компонента, предположительно вызывается частыми, множественными микротравмами при постоянных нагрузках на задний отдел стопы, особенно при наличии генетической предрасположенности.
### Клиническая картина
Главный симптом – болевые ощущения типичной локализации в области пяточного бугра.

- Болезнь начинается постепенно с нарастающего дискомфорта, а затем боли, особенно после нагрузки. 
- Боли могут быть ноющие, стреляющие, жгучие. 
- Боли усиливаются при нагрузке (длительная ходьба, бег, прыжки)
- Отек и гиперемия кожных покровов в области пяточного бугра
- Возможна  кожная гиперестезия и повышенная тактильная чувствительность области пятки. 
- При пальпации области пяточного бугра боль усиливается. 
- Разгибание и сгибание стопы затруднены из-за боли.
### Лечение:
#### *Консервативное:*
##### Коррекция образа жизни:
- ограничение нагрузки на задний отдел стопы
- применение ортезов, индивидуальных стелек-супинаторов
- нормализация веса
- ношение обуви на невысоком широком каблуке

##### Физиолечение:
- фонофорез с гидрокортизоном
- скэнар-терапия 
- УВТ-терапия
- МЛТ-терапия
- массаж стоп
- ЛФК

##### Медикаментозная терапия:
- анальгетики, НПВС
- глюкокортикостероиды
- витаминотерапия
- венотоники
##### Ортобиологическая терапия
- PRP-терапия
#### *Хирургическое (при неэффективности консервативного лечения):*
- удаление экзостозов открытым способом
- эндоскопия

Диагностика и лечение болезни Хаглунда является задачей, требующей дифференцированного подхода, совместных усилий и терпения как травматолога-ортопеда, так и самого пациента.
        `
      ,
    },
    {
      name: `Ахиллобурсит`,
      propsForCardDoctor: {
        // h: `Ахиллобурсит`,
        p: "",
        visualContent: `${assetsLocation}Ахиллобурсит.jpg`

      },
      description:
        `
**Ахиллобурсит** – воспаление синовиальной сумки ахиллова сухожилия.
Заболевание вызывается микротравмами сухожилия при длительных нагрузках на задний отдел стопы и голень.
### Клиническая картина
- Болезнь начинается постепенно с нарастающего дискомфорта, а затем боли, особенно после нагрузки, часто в течении нескольких минут утром после пробуждения.
- Боли усиливаются при попытке встать «на носочки».
- Боли усиливаются при нагрузке (длительная ходьба, бег, прыжки)
- Покраснение кожных покровов в области пяточного бугра
- При пальпации области пяточного бугра боль усиливается
- Разгибание и сгибание стопы затруднены из-за боли.
- Усиление отека в области прикрепления ахиллова сухожилия к пяточной кости.

### Лечение:
#### *Консервативное:*
##### Коррекция образа жизни:
- ограничение нагрузки на задний отдел стопы
- применение ортезов, индивидуальных стелек-супинаторов

##### Физиолечение:
- фонофорез с гидрокортизоном
- УВТ-терапия
- скэнар-терапия
- магнитотерапия
- массаж стоп
- ЛФК
- бальнеологические процедуры
- озекерит

##### Медикаментозная терапия:
- анальгетики, НПВС
- глюкокортикостероиды
- витаминотерапия
- венотоники
- антибиотикотерапия (при гнойном воспалении)

##### Ортобиологическая терапия
- PRP-терапия
#### *Хирургическое (при неэффективности консервативного лечения):* 
Оперативные вмешательства показаны при гнойных ахиллобурситах, часто рецидивирующих хронических передних (глубоких) бурситах и поражениях задней (поверхностной) сумки, обусловленных деформацией Хаглунда. 

**Выполняются:**

- Вскрытие, дренирование. Производится при гнойных процессах. Включает широкие разрезы, удаление гноя, промывание полости асептическими растворами, установку дренажей.
- Иссечение передней бурсы. Для обеспечения доступа к сумке ахилл частично рассекают в продольном направлении, бурсу иссекают и удаляют, сухожилие ушивают, накладывают гипс на 3 недели.
- Для устранения причины воспаления (излишнего давления на сумку) проводят краевую резекцию или клиновидную остеотомию пяточной кости.
        `
      ,
    },
    {
      name: `Вальгусная деформация большого пальца стопы (или Hallux valgus)`,
      propsForCardDoctor: {
        // h: `Вальгусная деформация большого пальца стопы (или Hallux valgus)`,
        p: `
##### Вальгусная деформация большого пальца стопы
*Стоимость операции, от 45 000 ₽*
`,
        visualContent: `${assetsLocation}Вальгусная деформация большого пальца стопы (или Hallux valgus).jpg`

      },
      description:
        `
**Вальгусная деформация большого пальца стопы (или Hallux valgus) -** заболевание, при котором первый палец стопы деформируется на уровне плюснефалангового сустава и отклоняется кнаружи под углом к остальным. Вальгусная деформация первого пальца обусловлена длительным нарушением биомеханики первого плюснефалангового сустава, которое может быть связано с поперечным плоскостопием и слабостью связочного аппарата, некоторыми неврологическими нарушениями, врождёнными деформациями и рядом других причин, усугубляясь ношением неудобной обуви. Заболевание широко распространено в травматологии и ортопедии, выявляется у женщин в 10 раз чаще, чем у мужчин. Патология сопровождается прогрессирующим артрозоартритом первого плюснефалангового сустава.

### Клиническая картина

*   боль в области первого пальца стопы
*   отек, покраснение первого пальца стопы
*   боль в суставах стоп
*   быстрая утомляемость ног
*   отклонение большего пальца стопы наружу, постепенное увеличение «косточки»
*   молоткообразная деформация остальных пальцев стоп
*   трудности в подборе обуви
*   образование натоптышей, мозолей
*   нарушение походки

### Лечение

Лечение подбирается врачом в зависимости от стадии заболевания и наличия сопутствующих заболеваний.

#### Консервативное (на начальной стадии)

##### Коррекция образа жизни

*   ограничение нагрузки на сустав
*   использование ортопедических стелек
*   использование супинаторов, вкладышей
*   подбор ортопедической обуви
*   нормализация массы тела
*   витаминизированная диета с ограничением поваренной соли
*   ограничение курения и алкоголя
*   организация рабочего места

##### Медикаментозное лечение

*   анальгетики при болях,
*   местные согревающие средства
*   противовоспалительная терапия
*   противоотечная терапия,
*   «блокады» с глюкокортикостероидами
*   препараты, укрепляющие суставной хрящ
*   витаминотерапия
*   препараты кальция, витамин Д.
*   терапия остеопороза.

##### Физиотерапевтические методы

*   СКЭНАР-терапия
*   фонофорез с гидрокортизоном
*   электрофорез с новокаином
*   массаж стоп и голеней
*   МЛТ
*   ударно-волновая терапия
*   кинезиотерапия, ЛФК

#### Оперативное

При неэффективности консервативной терапии и прогрессировании заболевания, а также при ухудшении качества жизни показано хирургическое лечение. Существует более 300 вариантов операций при Hallux valgus. Все методики можно разделить на 3 группы:

##### На мягких тканях.

Эффективны только при I степени деформации.

*   методика Сильвера, при которой отсекается сухожилие приводящей мышцы I пальца
*   операция Мак-Брайда, при которой это сухожилие перемещается для восстановления равномерной тяги приводящей и отводящей мышц
*   операция Манна по дистальному высвобождению мягких тканей

##### На костных и мягкотканных структурах.

Нередко перечисленные хирургические операции выполняются в сочетании с иссечением костного нароста и подкожной слизистой сумки в области I плюснефалангового сустава (операция Шеде).

##### На костях.

Существует несколько наиболее распространенных методов:

*   **клиновидная остеотомия Акина (Akin**) - небольшая по объему и технически относительно простая операция. Производится клиновидная резекция в области проксимальной фаланги большого пальца.
*   **операция Шеде-Брандеса** - удаление внутренней части головки первой плюсневой кости.
*   **артродез плюснефалангового сустава** заключается в фиксации головки плюсневой кости о основной фаланги большого пальца, в результате чего сустав становится неподвижным соединением.
*   **шевронная остеотомия, или остеотомией Остина** представляет собой удаление небольшого V-образный фрагмента в дистальной части I плюсневой кости.
*   **операция Лудлоффа и операция Мау-Ревердена** (operation Mau) - это хирургическая техника, которая может быть применена при умеренной вальгусной деформации (hallux valgus). Методика основана на клиновидном распиле первой плюсневой кости и смещении дистальной части кости кнутри.
*   **остеотомия SCARF** включает Z-образный распил первой плюсневой кости, который затем может быть перемещен и закреплен в новом положении чтобы устранить угол между I и остальными плюсневыми костями. Фиксация осуществляется обычно двумя винтами. В последующем удаляется избыточная кость с внутренней поверхности.
*   **стабилизация костей по технике** Trightrope – метод малоинвазивной хирургии «через замочную скважину» (keyhole surgery). Коррекция проводится через 3-4 очень маленьких разреза, длиной 3-4 миллиметра. Через микроразрезы вводятся рабочие инструменты, в том числе микрофрезы для резки костей. Угол между костями уменьшается за счет специальной гибкой стяжки специальной системой Trightrope.
*   И некоторые другие.

Успех операции, включающий сокращение периода заживления, отсутствие осложнений, исключение рецидива и максимальное улучшение качества жизни, во многом зависит от опыта и квалификации врача, подобравшего именно для Вас адекватный способ лечения и выполняющего операцию.
`
      ,
    },
    {
      name: `Артрит подагрический`,
      propsForCardDoctor: {
        // h: `Артрит подагрический`,
        p: "",
        visualContent: `${assetsLocation}Артрит подагрический.jpg`

      },
      description:
        `
**Подагрический артрит \-** это системное заболевание обмена веществ, связанное с отложением в суставных и околосуставных тканях натриевой соли мочевой кислоты (уратов) с развитием рецидивирующего острого артрита и образованием подагрических узелков - тофусов, развивающихся в месте отложения кристаллов моноурата натрия у людей с гиперурикемией, обусловленной внешнесредовыми и/или генетическими факторами. Чаще поражается плюснефаланговый сустав большого пальца стопы, также нередко воспаляются суставы плюсны, голеностопный, коленный, лучезапястный и локтевой суставы. Болеют чаще мужчины после 40 лет. У женщин подагра встречается реже и начинается позже – в постменопаузальном периоде после 50-60 лет.

### Клиническая картина

*   первый приступ обычно ночью
*   сильная боль в области сустава
*   припухлость, отек, деформация сустава
*   гиперемия сустава
*   локальное повышение температуры
*   кожа над суставом натянута, горячая, блестящая, красная или пурпурная.
*   иногда бывает повышение температуры тела, озноб, тахикардия, общая слабость
*   образования подагрических гранулем и подагрических «шишек» — тофусов, обычно вокруг суставов
*   нарушение функции сустава

Подагрический артрит необходимо дифференцировать с такими заболеваниями как псориатический артрит, псевдоподагра - болезнь отложения кристаллов пирофосфата кальция, ревматоидный артрит, гнойный артрит и некоторые другие, может понадобиться консультация терапевта, ревматолога, нефролога или некоторых других врачей узкой специализации для более точной постановки диагноза

### Лечение

Лечение подбирается врачом в зависимости от стадии заболевания и наличия сопутствующих заболеваний.

#### Консервативное (на начальной стадии)

##### Коррекция образа жизни

*   ограничение нагрузки на сустав
*   использование ортопедических стелек
*   использование супинаторов, вкладышей
*   подбор ортопедической обуви
*   нормализация массы тела
*   диета №6
*   исключение курения и алкоголя

##### Медикаментозное лечение

*   колхицин при обострении
*   урикозурические препараты
*   моноклональные антитела к интерлейкину-1
*   противовоспалительная терапия
*   «блокады» с глюкокортикостероидами
*   препараты, укрепляющие суставной хрящ
*   витаминотерапия

##### Физиотерапевтические методы

*   СКЭНАР-терапия
*   фонофорез с гидрокортизоном
*   электрофорез с новокаином
*   массаж стоп и голеней
*   МЛТ
*   кинезиотерапия, ЛФК
*   радоновые ванны
*   грязелечение
*   бишофитотерапия

#### Оперативное

При неэффективности консервативной терапии и прогрессировании заболевания, а также при ухудшении качества жизни показано хирургическое лечение. Хирургические методы лечения подагрического артрита направлены, в основном на удаление крупных тофусов, расположенных в коже.
`
      ,
    },
    {
      name: `Оперативное лечение Hallux valgus и статической деформации стоп`,
      operation: true,
      propsForCardDoctor: {
        // h: `Оперативное лечение Hallux valgus`,
        p: `
##### Стоимость операции:
Hallux valgus (косточка на стопе), от **45.000 ₽**\n
Статическая деформация стоп, от **50.000 ₽**
`,

        visualContent: `${assetsLocation}hallux_valgus_0.png`

      },
      description:
        `
Вальгусная деформация стопы (Hallux valgus) — заболевание, которое проявляется искривлением первого пальца стопы наружу и образованием «шишки» у основания большого пальца. Эта проблема раздражает человека, помимо не эстетичного вида ноги, возникает боль, что значительно снижает качество жизни человека. Окончательное избавление от проблемы — операция.

Статическая деформация стоп - серьезное прогрессирующее заболевание нижних
конечностей, без своевременного и адекватного лечения приводящее к:
 - необратимым изменениям в связочном аппарате стопы,
 - патологическому смещению костей стопы,
 - «распластыванию»,
 - увеличению нагрузки на мышцы голени,
 - нарушению походки,
 - артрозу голеностопного сустава
 - снижению амортизационных свойств стопы как следствие
 - к повышению ударных нагрузок на позвоночник
 - развитию остеохондроза,
 - образованию грыж и протрузий.
Пациенты отмечают быстрое появление усталости в стопах и икроножных областях при
ходьбе и длительном статичном положении. Возникает спастическая, ноющая боль,
проходящая после отдыха в горизонтальном положении.
Поэтому очень важно своевременно обратиться к врачу, специализирующемуся на лечении
стоп, для получения адекватного комплексного лечения, которое включает в себя:
 - коррекцию образа жизни,
 - применение ортезов,
 - медикаментозное лечение, направленное на снятие воспаления и улучшение трофики
тканей, а также ЛФК, физиопроцедуры
 - и зачастую – филигранное хирургическое вмешательство.
### Клинический пример.
Пациентка Б., 67 года. Обратилась с жалобами на деформацию стоп, боли при ходьбе,
особенно в правой стопе, быструю утомляемость, невозможность подобрать удобную
обувь.

![img](/hallux_valgus_1.png)

После осмотра и рентгенографии был поставлен диагноз:
Статическая деформация стоп:
Hallux valgus справа -3 ст., слева - 3ст.;
Вальгусная установка стоп справа - 1ст., слева - 1ст.
Молоткообразная деформация вторых и третьих пальцев обеих стоп.
Остеоартроз первых плюснефаланговых суставов 2-3 ст.
Сопутствующие заболевания: АИТ, Гипотиреоз, Принимает L-тироксин -125.
Гипотония. Дислипидемия. Хроническое заболевание вен ХЗВ- С2

Было принято решение провести реконструктивную операцию на правой стопе.

![img](/hallux_valgus_2.png)

**1-й луч- Остеотомия SLIDE DOWN 1-й плюсневой кости справа, остеосинтез
винтом SFS 34 мм** - Разрез по медиальной поверхности от середины основной фаланги 1-го
пальца до в плюсневой кости с иссечением избытка кожи и капсулы сустава. Головка
плюсневой кости выделена и вывихнута в рану. При помощи осцилляторной пилы удалены
экзостозы головки 1-ой плюсневой кости. Выполнена остеотомия slide down. Дистальный
фрагмент 1-ой плюсневой кости смещен в латеральном и проксимальном направлении.
Фрагменты фиксированы **винтом SFS 34 мм** Капсула 1-го плюснефалангового сустава
была мобилизована, сесамовидные кости подведены под головку 1-й плюсневой кости.
Вальгусное отклонение первого пальца устранено.

**Остеотомия типа Akin проксимальной фаланги 1 пальца**, остеосинтез винтом SFS 17
мм. Вальгусное отклонение первого пальца устранено.
Капсула ушита. Гемостаз по ходу операции, послойные швы на рану.
**2-й луч – Операция Weil с укорочением 2-ой плюсневой кости, остеосинтез винтом
SFS 13 мм**.
– доступ по тылу стопы между головками 2 и 3 плюсневых костей. Рассечена капсула 2-
го плюсне-фалангового сустава. С помощью осцилляторной пилы выполнена остеотомия с
укорочением 2 плюсневой кости, головка смещена проксимально, фиксирована винтом.
Молоткообразное кроссоверное положение 2 пальца устранено.
**3-й луч – Операция Weil с укорочением 3-ой плюсневой кости, остеосинтез винтом
SFS 12 мм**.
– доступ тот же по тылу стопы между головками 2 и 3 плюсневых костей. Рассечена
капсула 3-го плюсне-фалангового сустава. С помощью осцилляторной пилы выполнена
остеотомия с укорочением 3 плюсневой кости, головка смещена проксимально, фиксирована
винтом. Молоткообразное кроссоверное положение 3 пальца устранено. Гемостаз.
Послойные швы.
**4-й луч – Операция Weil с укорочением 4-ой плюсневой кости, остеосинтез винтом
SFS 12 мм**.

– доступ над 4 плюсне-фаланговым суставом. Рассечена капсула 4-го плюсне-
фалангового сустава. С помощью осцилляторной пилы выполнена остеотомия с
укорочением 4 плюсневой кости, головка смещена проксимально, фиксирована винтом.
Восстановлена дуга Левьера. Гемостаз. Послойные швы.
**5-й луч - Операция Wilson на 5-ой плюсневой кости без остеосинтеза** – Доступ по
наружной поверхности 5 го плюсне-фалангового сустава. Резекция экзостоза головки 5
плюсневой кости. Остеотомия косая с последующим смещением головки проксимально и
медиально. Жгут снят. Гемостаз. Послойные швы.

![img](/hallux_valgus_3.png)
![img](/hallux_valgus_4.png)
![img](/hallux_valgus_5.png)

Пациентка довольна результатом, боли и дискомфорта в правой стопе нет, идет
подготовка к операции на левой стопе.
`
      ,
    },
    {
      name: `Криохирургическое удаление гигантоклеточной опухоли редкой локализации`,
      operation: true,
      propsForCardDoctor: {
        // h: `Криохирургическое удаление`,
        p: "",
        visualContent: `${assetsLocation}криохирургическое_удаление_0.png`

      },
      description:
        `
**Больная Г., 56 лет**, в конце ноября 2014 года обратилась в
клинику травматологии и ортопедии РостГМУ с жалобами на боли в
среднем отделе правой стопы, усиливающиеся при физической нагрузке,
припухлость по тылу стопы в проекции ладьевидной кости.
**Из анамнеза:** 12 апреля 2014 года получила травму – подвернула
правую стопу. До травмы болей в стопе не было. Первоначально за
медпомощью не обращалась. Через 2 недели после травмы обратилась к
хирургу в поликлинику по месту жительства. На рентгенограммах
опухолевый процесс не увидели. Лечилась амбулаторно по поводу
«частичного повреждения связок стопы», получала анальгетики и
физиотерапевтическое лечение: УВЧ-терапию, магнитотерпию,
электрофорез с новокаином. В результате лечения боли в среднем отделе
стопы усилились, также увеличилась припухлость. В связи с этим 11
сентября 2014г. выполнили МРТ правой стопы.
**Заключение:** признаки деструкции ладьевидной кости, возможно,
аневризмальная костная киста, или другое патологическое образование.
Для уточнения диагноза рекомендована компьютерная томография,
которая подтвердила наличие деструктивного процесса в ладьевидной
кости.

![img](/криохирургическое_удаление_1.png)
*Предоперационные рентгенограммы в прямой (А) и боковой (В) проекции,
демонстрирующие литическое поражение ладьевидной кости стопы*

![img](/криохирургическое_удаление_2.png)
*Предоперационная 3D-реконструкция компьютерной томографии
подтверждает сохранность кортикальной пластины по тыльной поверхности
пораженной ладьевидной кости правой стопы.*


![img](/криохирургическое_удаление_3.png)
*Осевая (A), сагиттальная (B), фронтальная (C) проекции трехмерного КТ.
Определяется значительное разрушение ладьевидной кости, особенно по нижнему
контуру – перфорация кортикальной пластинки. При этом опухоль
распространилась на мягкие ткани. Не отмечается центральной оссификации или
кальцинирования в области опухоли.*

После амбулаторного обследования пациентка была
госпитализирована для оперативного лечения с диагнозом:
аневризмальная костная киста ладьевидной кости правой стопы.

### Было проведено четырехэтапное оперативное вмешательство:

#### 1) Субтотальная резекция ладьевидной кости:
Доступ тыльно-
медиальный. Длина разреза 6 см. Выделена тыльная поверхность
ладьевидной кости. Трепанация кости цилиндрической фрезой. Получена
полость, заполненная кровью. Резекция измененной кортикальной
пластинки по тылу ладьевидной кости.

![img](/криохирургическое_удаление_4.png)
Удалены оболочки кисты. В области таранно-ладьевидного и клино-
ладьевидного сустава кортикальная пластинка разрушена до хрящевой ткани.
Выполнена субтотальная резекция ладьевидной кости. Удалось сохранить
только бугристость ладьевидной кости.
![img](/криохирургическое_удаление_5.png)


#### 2) Адъювантная криодеструкция:
На образованную поверхность пострезекционного дефекта кости
осуществляют криовоздействие с помощью «Криоаппликатора
медицинского» - специально разработанного устройства для аккумуляции
холода и более равномерного промерзания патологического очага при
криодеструкции. Для этого «Криоаппликатор медицинский» помещают на
поверхность пострезекционного дефекта кости, затем его охлаждают жидким
азотом в течение 10-60 секунд.

![img](/криохирургическое_удаление_6.png)
![img](/криохирургическое_удаление_7.png)
![img](/криохирургическое_удаление_8.png)
![img](/криохирургическое_удаление_9.png)
![img](/криохирургическое_удаление_10.png)

После этого «Криоаппликатор медицинский» размораживают в
изотоническом растворе натрия хлорида, подогретом до плюс 38 0 С.
Криодеструкция выполнена 3 циклами последовательного замораживания по
1 мин. с оттаиванием по 2 мин между циклами.


После трехкратного криовоздействия: охлаждение - размораживание,
«Криоаппликатор медицинский» удаляют и по показаниям выполняют
пластику пострезекционного дефекта кости, после чего рану ушивают.
фото


#### 3) Забор костного аутотрансплантата.
Походу гребня подвздошной кости
справа сделан разрез 7 см. Выделен гребень подвздошной кости. При
помощи остеотома резецирован участок гребня размером 3 см на 2,5 см.
Гемостаз при помощи гемостатичексого воска. Послойные швы.
![img](/криохирургическое_удаление_11.png)
![img](/криохирургическое_удаление_12.png)

#### 4) Таранно-клиновидный артродез с костной аутопластикой резекционного дефекта:
При помощи кюретки удален хрящ с головки
таранной кости и 3-х клиновидных костей. Костный аутотрансплантат
помещен в резекционный дефект pressfit.

![img](/криохирургическое_удаление_13.png)
*Гемостаз. Послойные швы. Асептическая повязка, иммобилизация задней гипсовой
лонгетой. Макропрепарат отправлен на гистологическое исследование.*

![img](/криохирургическое_удаление_14.png)
Послеоперационный период протекал без осложнений.

![img](/криохирургическое_удаление_15.png)
*Результат гистологического исследования в Ростове - Результаты исследования
цитологических препаратов NN 74614-74615- от 6.01 2014 г. Клинический диагноз:
Аневризмальная киста ладьевидной кости.Гистологический диагноз: Гигантоклеточная
опухоль кости (остебластокластома) с нерезко выраженным костеобразованием. От
13.01.2015 г.*

Гистологический диагноз: №№ 74614-74615 от 06.01.2015г. –
гигантоклеточная опухоль кости со слабо выраженным костеобразованием.
Гистология стенки кисты от 30.12.2014 г. № 74613. Среди костной ткани
множество гигантских клеток. Опухоль подозрительна в отношении
гигантоклеточной опухоли.

Через 2 месяца после операции пациентке разрешили дозированную нагрузку
в гипсовой повязке. Иммобилизация голеностопного сустава и стопы
осуществлялась в течение 12-ти недель.


![img](/криохирургическое_удаление_16.png)
*Рентгенограмма в прямой (А) и боковой (В) проекции правой лодыжки через три
месяца после операции. Для фиксации использовались два губчатых винта.*

Полная нагрузка на оперированную конечность разрешена через 4 месяца с
момента операции.

![img](/криохирургическое_удаление_17.png)
*Рентгенограмма правой лодыжки в прямой (А) и боковой (В) проекции через 12 месяцев
после операции.*

При рентгенологическом исследовании через 3, 6, 9, 12 месяцев и 2 года
после операции данных за рецидив опухоли нет. Наблюдается перестройка
аутотрансплантата.

![img](/криохирургическое_удаление_18.png)
*Рентгенограмма правой лодыжки в прямой и боковой проекции через 6 лет после
операции.*

При осмотре через 6 лет после операции наблюдается полная перестройка
трансплантата, полное восстановление функций, признаков рецидива нет.
`
      ,
    },
    {
      name: `Подтаранный артроэрез в лечении плоксовальгусной деформации стоп у детей`,
      operation: true,
      propsForCardDoctor: {
        // h: `Подтаранный артроэрез в лечении плоксовальгусной деформации стоп у детей`,
        p: `
##### Стоимость операции:
Плосковальгусная деформация стоп у детей, от **30.000 ₽**
`,
        visualContent: `${assetsLocation}ploskovalgusnoy0.jpeg`

      },
      description:
        `
Лечение плосковальгусной деформации стоп является сложной проблемой детской ортопедии, имеющей социальное значение вследствие своей распространенности и дальнейшего прогрессирования [1]. Согласно статистике ведущих лечебных учреждений России (ФГУ ЦИТО им. Н. Н. Приорова, НИИДО им. Г. И. Турнера), плосковальгусная деформация стоп в структуре врожденных заболеваний опорно-двигательной системы составляет 23,7 %. [2]. 

К сожалению, консервативное лечение далеко не всегда эффективно, вынуждает больного постоянно использовать ортопедические пособия, не давая стойкого результата. Хирургические методы лечения дают более стойкий эффект. Выбор метода коррекции и сроки ее проведения варьируют в зависимости от тяжести деформации и эффективности консервативного лечения [3]. Предлагаемые для коррекции врожденного «вертикального тарана» у детей оперативные вмешательства очень разнообразны: от операций на мягких тканях, заключающихся в открытом исправлении и фиксации костей стопы в сочетании с сухожильно-мышечными пересадками и без них, до операций на костных структурах [4, 5]. В большинстве случаев оперативная коррекция позволяет восстановить нарушенные взаимоотношения и улучшить статическую функцию, в том числе за счет рубцевания капсульно-связочного аппарата [1]. Применение подтаранного артроэреза как метода хирургической коррекции плоско-вальгусной деформации стоп является наиболее малотравматичным и малоинвазивным, что отвечает современным требованиями в ортопедии [6,7]. 

#### Клинический случай.
Пациентка Т. 10 лет.,  обратилась с жалобами на нарушение формы  обеих стоп,  нарушение функции левой  и правой ноги  на фоне плосковальгусных стоп.  После сбора анамнеза, клинического осмотра и анализа рентгенограмм поставлен диагноз:
** плосковальгусные стопы: справа 2ст., слева 3 ст., поперечное плоскостопие, правая стопа в состоянии гиперпронации**, что являются показанием для оперативного лечения.
Сопутствующий диагноз: Патологическая антеторсия проксимальных отделов обеих бедренных костей.

#### *Оперативное лечение - подтаранный артроэрез обеих стоп.*

Операция на правой стопе: Положение больной на операционном столе лежа на спине. Обработка операционного поля. 

В проекции подтаранного синуса справа выполнен разрез 12 мм. После введения в синус направляющей спицы при помощи канюлированных разверток диаметром от 6 мм до 12 мм подготовлено ложе для установки подтаранного импланта SIF 12.  Имплант установлен в подтаранный синус. Швы послойно.
Аналогичная операция выполнена на левой стопе, анатомические особенности потребовали установки большего по размеру импланта (SIF 13).

За счет установки подтаранных имплантов гиперпронация устранена. 
Иммобилизация задней гипсовой лонгетой.

![img](/ploskovalgusnoy1.jpeg)

![img](/ploskovalgusnoy2.jpeg)

![img](/ploskovalgusnoy3.jpeg)


Послеопреационный период без осложнений, пациентка выписана на амбулаторное лечение.

##### Список использованных источников.
1. Кожевников О. В., Косов И. С., Иванов А. В., Болотов А. В. Современные подходы к лечению плоско-вальгусной деформации стоп у детей и подростков // Кубанский научный медицинский вестник. 2010. №6. 
2. Кузнечихин Е. П., Ульрих Э. В. Хирургическое лечение детей с заболеваниями и деформациями опорно-двигательной системы // Медицина. - М., 2004. - 298 с.
3. Конюхов М. П., Лапкин Ю. А., Клычкова И. Ю, Дрожжина Л. А. Врожденные и приобретенные деформации у детей и подростков: Пособие для врачей // НИИДО им. Г. И. Турнера. - СПб, 2000. - 36 с.
4. Needleman R. L. A surgical approach for flexible flatfeet in adults including a subtalar arthroereisis with the MBA sinus tarsi implant // Foot & Ankle International. - 2006. - Vol. 27. - P. 9-18.
5. Scher D. M., Bansal M., Handler-Matasar S. et al. Extensive implant reaction in failed subtalar joint arthroereisis: report of two cases // Hss Journal . - 2007. - Vol. 3. - P. 177-181.
6. Тамоев, С.К. Подтаранный артроэрез при дисфункции сухожилия задней большеберцовой мышцы / С.К. Тамоев [и др.] // Вестн. травматологии и ортопедии им. Н.Н. Приорова. — 2011. — № 1. — C. 54-58.
7. Тамоев, С.К. Анализ осложнений после подтаранного артроэреза у пациентов с плосковальгусной деформацией стоп / С.К. Тамоев [и др.] // Травматология и ортопедия России. 2011. №4. 
`,
    },
    {
      name: `Хирургическое лечение плоскостопия у взрослых`,
      operation: true,
      propsForCardDoctor: {
        p: "",
        visualContent: `${assetsLocation}ploskostopya0.jpg`

      },
      description:
        `
Плоскостопие – это серьезное ортопедическое заболевание, ведущее к прогрессивному нарушению функций стопы, снижению ее функции ходьбы и амортизирующих свойств, что может привести к заболеваниям позвоночника и нарушению работы всех систем органов. 

Деформация стоп может вызывать сильную боль и затруднение при ходьбе, доставляющие человеку немало страданий. 

В запущенных случаях только операция помогает устранить болезненные симптомы и вернуть стопам приятный внешний вид.

Статическая деформация стоп может включать в себя целый комплекс патологических изменений стопы: продольное или поперечное плоскостопие, Hallux valgus; вальгусная или варусная установка стоп; молоткообразная деформация вторых и третьих пальцев стоп, остеоартроз первых плюснефаланговых суставов, остеоартроз голеностопных суставов и т.д.

#### Основные принципы оперативного лечения плоскостопия:
 - Дифференцированный подход, учет степени деформации и вариабельности анатомо-функционального строения стопы.
 - Устранение всех компонентов плоскостопия и исключение их повторного появления в будущем.
 - Прочное соединение костных структур с целью ранней активизации пациентов и восстановления подвижности пальцев.
 - Обязательно сохранение всех точек опоры (пяточная кость, головки I и V плюсневой кости) при лечении разных видов плоскостопия.


### Клинический пример 1.
![img](/ploskostopya1.png)
Пациентка Б., 67 года. Обратилась с жалобами на деформацию стоп, боли при ходьбе, особенно в правой стопе, быструю утомляемость, невозможность подобрать удобную обувь. 

### Клинический пример 2. 
Пациентка Во, 54 г. Диагноз: Статическая деформация правой стопы: поперечное плоскостопие 2 ст., Hallux valgus 3 ст. Остеоартроз первого плюснефалангового сустава 1-2 ст.
![img](/ploskostopya2.png)

### Клинический пример 3. 
Пациентка В., 57 лет. Диагноз: Статическая деформация стоп: продольное плоскостопие справа -3 ст., слева - 3ст.; поперечное плоскостопие справа -3 ст., слева -3 ст.; Hallux valgus справа - 3ст., слева - 3ст.; вальгусная установка стоп справа -1 ст., слева - 1ст. Передне-латеральный импинджмент синдром голеностопных суставов.
![img](/ploskostopya3.png)

### Клинический пример 4. 
Пациентка З., 68 лет. Диагноз: Миофасциальные боли правой голени и области правого голеностопного сустава. Статическая деформация стоп: продольное плоскостопие справа - 2ст., слева - 2ст.; поперечное плоскостопие справа - 3ст., слева - 3ст.; Hallux valgus справа - 3ст., слева - 3ст.; вальгусная установка стоп справа - 1ст., слева -1 ст. 
![img](/ploskostopya4.png)

Пациенты довольны результатом, жалоб нет, процесс восстановления идет хорошо.
`,
    },
    {
      name: `Хирургическое лечение вальгусной деформации первого пальца стопы`,
      operation: true,
      propsForCardDoctor: {
        p: "",
        visualContent: `${assetsLocation}valgus0.jpg`

      },
      description:
        `
Статическая деформация стоп может включать в себя целый комплекс патологических изменений стопы: продольное или поперечное плоскостопие, Hallux valgus; вальгусная или варусная установка стоп; молоткообразная деформация вторых и третьих пальцев стоп, остеоартроз первых плюснефаланговых суставов, остеоартроз голеностопных суставов и т.д. 

**Халлюкс вальгус** (Hallux valgus) или вальгусная деформация первого плюснефалангового сустава – одно из наиболее распространенных проявлений деформации стопы,  особенно часто наблюдается у женщин, проявляясь в более зрелом возрасте. 

Деформация первого пальца стопы может вызывать сильную боль, затруднение при ходьбе, невозможность подобрать обувь, что создает дискомфорт и значительно ухудшает качество жизни пациента. Также подобная патология может вызывать ряд структурных изменений во всей стопе, голеностопном суставе, связках и сухожилиях голени, нарушая правильную ось всей конечности и негативно влияя на осанку и позвоночник. Нельзя также игнорировать психологический дискомфорт, который испытывают пациентки от внешнего вида деформированной стопы. 

В запущенных случаях правильно подобранное только хирургическое вмешательство помогает устранить болезненные симптомы и вернуть стопам приятный внешний вид.

### Клинический пример.
Пациентка Ш., 18 лет. Обратилась с жалобами на деформацию стоп, боли при ходьбе, боль в первых пальцах, боли в области первого плюснефалангового сустава слева и справа, боль в шейном отделе позвоночника, быструю утомляемость, невозможность подобрать удобную обувь. 

**При осмотре:** незначительный реберный горб справа. Боль при пальпации шейного отдела позвоночника и в области медиального угла лопаток. Лопаточный хруст больше справа. Рекурвация коленных суставов больше слева. Торсионная деформация правого коленного сустава. В положении стоя отмечается уменьшение продольных сводов стоп, вальгус стоп. Вальгусное отклонение первых пальцев стоп. Боли при пальпации ногтевых фаланг первых. 

**Диагноз основного заболевания:** Статическая деформация стоп: продольное плоскостопие справа - 2ст., слева - 2ст.; поперечное плоскостопие справа - 1ст., слева -1ст.; Hallux valgus справа - 2ст., слева - 3ст.; вальгусная установка стоп справа 1ст., слева - 1ст. Остеоартроз первого плюснефалангового сустава справа 1 ст., слева 1-2 ст. Контрактура икроножных мышц. 

Дорсопатия шейного отдела позвоночника. Цервикалгия. Синдром m. Levator scapulae слева и справа. Синдром лопаточного хруста больше справа. Левосторонний грудной сколиоз 1 ст. Рекурвация коленных суставов больше слева. Торсионная деформация правого коленного сустава (внутренняя ротация). 

Наличие укорочения задней поверхностной линии (мышечные поезда т. Майерса), привело к ограничению разгибания стоп. Компенсаторно сформировалась вальгусная установка стоп и плоскостопие 2 ст., появилось варусное отклонение первых плюсневых костей и вальгусное отклонение первых пальцев. Появились начальные признаки остеоартроза первых плюснефаланговых суставов. 

Для профилактики увеличения деформации стоп и стадии остеоартроза показано оперативное вмешательство.

Цель вмешательства – улучшить состояние стоп.

#### Задачи:
 - Устранить посредством операции Вульпиуса укорочение задней поверхностной линии (мышечные поезда т. Майерса)
 - Ограничить патологическую подвижность таранной кости посредством подтаранного артроэреза, тем самым уменьшить вальгус стоп и предотвратить прогрессирование плоскостопия
 - Посредством операции Scarf устранить варусное отклонение первых плюсневых костей и вальгусное отклонение первых пальцев, тем самым приостановить развитие остеоартроза первых плюсне-фаланговых суставов. 

![img](/valgus1.png)
Левая стопа до и через 6 недель после операции

![img](/valgus2.png)
Правая стопа до и через 6 недель после операции

Результат удовлетворительный, жалоб на боль и дискомфорт нет. Рекомендованы индивидуальные ортопедические стельки и удобная обувь.
`,
    },
    {
      name: `Хирургическое лечение эквиноварусной деформации стоп у детей`,
      operation: true,
      propsForCardDoctor: {
        p: "",
        visualContent: `${assetsLocation}equinus_deformation0.jpg`

      },
      description:
        `
Эквиноварусная деформация стоп, «синдром балерины», «конская стопа» – патология опорно-двигательного аппарата, представляющая собой нарушение положения свода ступни и оси нижней конечности. Внутренняя часть стопы приподнимается и подгибается внутрь, наружная опускается вниз, пальцы отклоняются  кнутри. Эквиноварусная установка стоп развивается вследствие внутренних нарушений строения мышц. 

Возникновение заболевания происходит на фоне следующих факторов: 
 - Дистония мышц-сгибателей стопы и голени; 
 - Пирамидная недостаточность у детей до первого года жизни; 
 - Травмы шейного отдела спинного мозга; 
 - Устойчивое нарушение кровообращения; 
 - Утолщение ствола головного мозга.

Это тяжелое полиэтиологичное патологическое состояние, которое бывает врожденным или приобретенным, встречается изолированно или сочетается с другими деформациями. Может развиваться в любом возрасте, чаще на фоне неврологической патологии. Провоцирует изменение длины сухожилий и связок стопы, в тяжелых случаях – деформации костей предплюсны и подвывихи суставов. Существенно нарушает биомеханику ходьбы, ограничивает трудоспособность и нормальную жизнедеятельность пациента. 
При прогрессировании заболевания изменяется распределение массы тела на суставы ног, страдает позвоночник. Нарушается осанка, возникают артрозы. Деформируются коленные суставы, голени отклоняется наружу, образуется О-образное искривление конечностей. В суставах стопы могут происходить подвывихи. Мышцы голени, не получающие нагрузку, атрофируются.
Требует обязательной коррекции консервативными, а чаще оперативными методами.

Иногда к нам обращаются пациенты с такой сложной патологией, не получив своевременной помощи в раннем периоде заболевания.

### Клинический пример:

Пациент К., 12 лет. 

**Жалоба:**  ходит на носочках с первых лет жизни. Бег и прыжки невозможны, наблюдаются частые падения и травмы. Не лечился. Наблюдается у невролога.

**При осмотре:** ребенок ходит «на носочках», стопы вывернуты, не может стать на пятки или полностью прижать стопы к полу, походка неустойчивая.  Принудительное сгибание стоп затруднено.   

**Диагноз:** Эквинизм. Сгибательная контрактура голеностопных суставов. Укорочение ЗББМ слева и справа, икроножных мышц и ахиллова сухожилия слева и справа на фоне пирамидной недостаточности.

Проведено хирургическое лечение на правой и левой голени: **Z-пластика ахиллова сухожилия**.  

#### Видео До операции 

![img](/equinus_deformation1.mp4)

#### Видео через 2 месяца после операции:
![img](/equinus_deformation2.mp4)

![img](/equinus_deformation3.mp4)

`,
    },
    {
      name: `Отдаленный результат хирургического лечения плоскостопия`,
      operation: true,
      propsForCardDoctor: {
        p: "",
        visualContent: `${assetsLocation}ploskostopya0.jpg`

      },
      description:
        `
Плоскостопие – это патологическое изменение формы стопы, вызванное опущением продольного или поперечного свода стопы, что приводит к прогрессивному нарушению функций стопы, снижению ее функции ходьбы и амортизирующих свойств, а в перспективе - к заболеваниям позвоночника и нарушению работы всех систем органов. 

![img](/ploskostopye_result1.png)

По данным медицинской статистики у взрослых данная патология диагностируется у 15-20% случаев.

Деформация стоп может вызывать сильную боль, затруднение при ходьбе, невозможность подобрать обувь, что создает дискомфорт, значительно ухудшает качество жизни и требует медицинской помощи. 

В запущенных случаях только правильно подобранное хирургическое вмешательство помогает устранить боль и дискомфорт. 

К сожалению, у многих пациентов существует предубеждение, что эффект от операции сохранится непродолжительное время и через год-два стопа опять «расползется», а болезненная симптоматика вернется. Однако при адекватной оперативной технике и правильно подобранной методике реабилитации вероятность подобного исхода очень низка.


### Клинический пример:

В декабре 2019 года к нам обратилась пациентка Д., 68 лет, с жалобами на сильные боли в левой стопе при ходьбе и в состоянии покоя, деформацию стоп,  неудобства при подборе и ношении обуви, нарушение функции  ноги  на фоне статической деформации левой стопы. 

На основании клинико-рентгенологических данных был поставлен диагноз:  

 - Статическая деформация стоп: 
продольное плоскостопие справа - 1ст., слева - 1ст.;
поперечное плоскостопие справа - 3ст., слева -3 ст.; 
 _ Hallux valgus справа -3 ст., слева - 3ст.; 
 - вальгусная установка стоп справа -1 ст., слева -1 ст. 
 - молоткообразная деформация вторых пальцев обеих стоп, 
 - остеоартроз первых плюснефаланговых суставов 2-3 ст.

**Сопутствующие заболевания:** Гипотония. ИБС. Стенокардия. 

Рекомендовано хирургическое лечение:

Операция на левой стопе:
1) Остеотомия SCARF  1й плюсневой кости, остеосинтез 2-мя винтами 
2) Операция Weil на 2-ой плюсневой кости 
3)Операция Homan на 2 пальце.

![img](/ploskostopye_result2.png)

При оценке результата через 3,5 года – состояние и форма стопы стабильные, боли отсутствуют,  пациентка очень довольна результатом.
`,
    },
    {
      name: `Хирургическое лечение синдрома Хаглунда`,
      operation: true,
      propsForCardDoctor: {
        p: "",
        visualContent: `${assetsLocation}syndrom_jaglunda.png`

      },
      description:
        `
**Синдром Хаглунда** или «болезнь Золушки» является одной из основных причин болей и функциональных нарушений в задней пяточной области. Он состоит из ретрокальканеального бурсита и импинджмент-тенопатии ахиллова сухожилия из-за имеющейся деформации Хаглунда-Шинца - остеохондропатии бугра пяточной кости.

![img](/syndrom_jaglunda1.png)

Болезнь развивается постепенно, возникая в результате хронической травматизации задне-верхнего отдела пяточной кости (в большинстве случаев при сдавливании неудобной или жесткой обувью). В результате развивается нарушение кровоснабжения с последующим развитием асептического некроза этого участка пяточной кости, разрастанием костной ткани и образованием остеофита – костного нароста, зачастую довольно крупного, мешающего подбору обуви и нормальной ходьбе, что значительно снижает качество жизни. В результате сдавления ретроахиллярной бурсы развивается воспаление и тендинит ахиллова сухожилия.

![img](/syndrom_jaglunda2.png)

Обычно встречается у женщин при регулярном ношении обуви на высоких каблуках с жестким задником, несколько реже - у спортсменов. Нередко поддается консервативному лечению на фоне своевременного прекращения пользования такой обувью, однако в большинстве случаев время упущено и консервативное лечение малоэффективно, в связи с чем возникают показания к оперативному лечению - краевой резекции пяточной кости. 

### Клинический пример:

Пациентка Е., 61 год. Обратилась с жалобами на боли и припухлость в области крепления ахиллова сухожилия к пяточной кости, опухолевидное образование по латеральному краю пяточного бугра, нарушение функции ноги, боли при ходьбе, невозможность подобрать удобную обувь. 

![img](/syndrom_jaglunda3.png)

На основании клинико-рентгенологических данных поставлен диагноз:  синдром Хаглунда справа.

Консервативное лечение оказалось неэффективным, заболевание прогрессировало, что явилось показанием к оперативному лечению. Пациентке выполнена операция: удаление экзостоза пяточного бугра, резекция измененного участка ахиллова сухожилия на протяжении 2 см. Возникшее укорочение ахиллова сухожилия устранено за счет возможностей операции Страйера. Затем при помощи якорного фиксатора ахиллово сухожилие фиксировано к пяточному бугру.


#### Планирование оперативного лечения:

![img](/syndrom_jaglunda4.png)


#### Оперативное лечение синдрома Хаглунда

![img](/syndrom_jaglunda5.png)

В удовлетворительном состоянии с рекомендациями выписана на амбулаторное лечение.


`,
    },
    {
      name: `Хирургическое лечение Hallux valgus  - отличный результат даже в сложных случаях`,
      operation: true,
      propsForCardDoctor: {
        p: "",
        visualContent: `${assetsLocation}hallux_valgus_perfect_results.png`

      },
      description:
        `
**Халлюкс вальгус** (Hallux valgus), «косточка на стопе» или вальгусная деформация первого плюснефалангового сустава – одно из наиболее распространенных проявлений деформации стопы,  особенно часто наблюдается у женщин, проявляясь в более зрелом возрасте. 

Заболевание может вызывать сильную боль, быструю утомляемость, затруднение при ходьбе, нарушение походки, невозможность подобрать обувь, что создает определенное неудобство и значительно ухудшает качество жизни. Также деформация первого плюснефалангового сустава может вызывать ряд структурных изменений во всей стопе, голеностопном суставе, связках и сухожилиях голени, нарушая биомеханику движения стопы, изменяя правильную ось всей конечности и негативно влияя на осанку и позвоночник. Также некоторые пациентки испытывают сильный психологический дискомфорт от внешнего вида деформированной стопы и невозможности носить красивую модельную обувь.

### Клинический пример 1

Пациентка Р., 51 год., обратилась с жалобами на боли в области первого плюснефалангового сустава слева и справа, вальгусную деформацию первых пальцев, дискомфорт от косметического дефекта на фоне отсутствия положительного эффекта от консервативной терапии, что является показанием для оперативного лечения.

В результате клинико-рентгенологического обследования был поставлен диагноз: Статическая деформация стоп: Hallux valgus справа-1ст., слева-2ст. 
Было проведено оперативное лечение: Scarf-остеотомия первых плюсневых костей. 

![img](/hallux_valgus_perfect_results1.png)

Через 4 недели на контрольном осмотре - результат удовлетворительный, жалоб на боль и дискомфорт нет. Рекомендованы индивидуальные ортопедические стельки и удобная обувь. 

### Клинический пример 2

Пациентка Ш., 18 лет. обратилась с жалобами на деформацию стоп, боли при ходьбе, боль в первых пальцах, боли в области первого плюснефалангового сустава слева и справа, боль в шейном отделе позвоночника, быструю утомляемость, невозможность подобрать удобную обувь. 

**При осмотре:** В положении стоя отмечается уменьшение продольных сводов стоп, вальгус стоп. Вальгусное отклонение первых пальцев стоп. Боли при пальпации ногтевых фаланг первых. 

##### Диагноз основного заболевания: 
Статическая деформация стоп: 

продольное плоскостопие справа - 2ст., слева - 2ст.; 

поперечное плоскостопие справа - 1ст., слева -1ст.; 

Hallux valgus справа - 2ст., слева - 3ст.; 

вальгусная установка стоп справа 1ст., слева - 1ст. 

Остеоартроз первого плюснефалангового сустава справа 1 ст., слева 1-2 ст. 

Контрактура икроножных мышц. Наличие укорочения задней поверхностной линии (мышечные поезда т. Майерса), привело к ограничению разгибания стоп. Компенсаторно сформировалась вальгусная установка стоп и плоскостопие 2 ст., появилось варусное отклонение первых плюсневых костей и вальгусное отклонение первых пальцев. 

Появились начальные признаки остеоартроза первых плюснефаланговых суставов. 

Для профилактики увеличения деформации стоп и стадии остеоартроза показано оперативное вмешательство.

Цель вмешательства – улучшить состояние стоп.

##### Задачи:
- Устранить посредством операции Вульпиуса укорочение задней поверхностной линии (мышечные поезда т. Майерса)
- Ограничить патологическую подвижность таранной кости посредством подтаранного артроэреза, тем самым уменьшить вальгус стоп и предотвратить прогрессирование плоскостопия
- Посредством операции Scarf устранить варусное отклонение первых плюсневых костей и вальгусное отклонение первых пальцев, тем самым приостановить развитие остеоартроза первых плюснефаланговых суставов. 

![img](/hallux_valgus_perfect_results2.png)
Левая стопа до операции, через 6 недель, через 9 месяцев после операции

![img](/hallux_valgus_perfect_results3.png)
Левая стопа до операции, через 6 недель, через 9 месяцев после операции

Пациентка довольна результатом, жалоб на боль и дискомфорт нет. Рекомендованы индивидуальные ортопедические стельки и удобная обувь. 

### Клинический пример 3

Пациентка М., 65 лет. Обратилась с жалобами на деформацию стоп, боли при ходьбе, особенно в правой стопе, быструю утомляемость, невозможность подобрать удобную обувь. 
После осмотра и рентгенографии был поставлен **диагноз**: 
- Статическая деформация стоп: 
- Продольное плоскостопие справа - 1ст., слева - 1ст.
- Поперечное плоскостопие справа - 3 ст., слева - 3ст.;
- Hallux valgus справа -3 ст., слева - 3ст.; 
- Вальгусная установка стоп справа - 1ст., слева - 1ст.  
- Молоткообразная деформация вторых пальцев обеих стоп. 

Было принято решение провести реконструктивную операцию на обеих стопах.
![img](/hallux_valgus_perfect_results4.png)
До операции

![img](/hallux_valgus_perfect_results5.png)
7 недель после операции

![img](/hallux_valgus_perfect_results6.png)
До и через 7 недель после операции

Пациентка очень довольна результатом, жалобы на боль и трудности при ходьбе отсутствуют. 

Рекомендованы индивидуальные ортопедические стельки, удобная обувь и ежедневная гимнастика для стоп.
`,
    },
  ],
};