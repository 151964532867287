<template>
  <button
    :class="{
      icon_button: buttonProps.icon,
      text_button: buttonProps.text,
      border: buttonProps.border,
      jump: buttonProps.jump,
      shadow: buttonProps.hasShadow,
      instagram: type === 'instagram',
    }"
    class="ghost"
    :style="[
      buttonProps.bgc && !buttonProps.border
        ? { backgroundColor: buttonProps.bgc }
        : { borderColor: buttonProps.borderColor },
      { color: buttonProps.bgc },
    ]"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <inline-svg
      v-if="buttonProps.icon && !buttonProps.text"
      :src="require(`@/assets/${buttonProps.icon}.svg`)"
      fill-opacity="1"
      :color="buttonProps.border ? buttonProps.border : buttonProps.iconColor"
      width=" 26px"
      height="26px"
      stroke="30px"
    />
    <p v-if="buttonProps.text && !buttonProps.icon">
      {{ buttonProps.text }}
    </p>
  </button>
</template>

<script>
export default {
  props: {
    borderColor: String,
    jump: {
      type: Boolean,
      default: true,
    },
    hasShadow: Boolean,
    bgc: String,
    bgcHover: {
      type: String,
      default: `#E9EDF0`,
    },
    icon: String,
    iconColor: String,
    text: String,
    iconTitle: String,
    type: String,
  },
  data() {
    return {
      buttonProps: {
        borderColor: this.border,
        borderFaded: `${this.border}80`,
        jump: this.jump,
        hasShadow: this.hasShadow,
        bgc: this.bgc,
        bgcHover: this.bgcHover,
        icon: this.icon,
        iconColor: this.iconColor,
        text: this.text,
        iconTitle: this.iconTitle,
        type: this.type,
      },
    };
  },
  mounted() {
    if (this.type != null) {
      if (this.type == "whatsapp") {
        this.buttonProps.bgc = "#26D266";
        this.buttonProps.bgcHover = "#26D266";
        this.buttonProps.jump = "true";
        this.buttonProps.hasShadow = "true";
        this.buttonProps.icon = "whatsapp";
      } else if (this.type == "facebook") {
        this.buttonProps.bgc = "#3B5999";
        this.buttonProps.bgcHover = "#3B5999";
        this.buttonProps.jump = "true";
        this.buttonProps.hasShadow = "true";
        this.buttonProps.icon = "facebook";
      } else if (this.type == "instagram") {
        this.buttonProps.bgc = "#A039AC";
        this.buttonProps.bgcHover = "#A039AC";
        this.buttonProps.jump = "true";
        this.buttonProps.hasShadow = "true";
        this.buttonProps.icon = "instagram";
      } else if (this.type == "telegram") {
        this.buttonProps.bgc = "#039BE5";
        this.buttonProps.bgcHover = "#039BE5";
        this.buttonProps.jump = "true";
        this.buttonProps.hasShadow = "true";
        this.buttonProps.icon = "telegram";
      }
    }
  },
  methods: {
    mouseEnter() {
      if (this.buttonProps.bgcHover != undefined && !this.buttonProps.type) {
        this.buttonProps.bgc = this.buttonProps.bgcHover;
      }
      // if (this.border) {
      //   this.border_copy = this.borderFaded_copy;
      // }
    },
    mouseLeave() {
      if (!this.buttonProps.type) {
        this.buttonProps.bgc = this.bgc;
      }
      //   if (this.border) {
      //     this.border_copy = this.border;
      //   }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../theme.scss";

// - Mobile
@media screen and (max-width: 1000px) {
  button {
    padding: 12px !important;
  }
}
button {
  border: none;
  min-width: 40px;
  height: fit-content;
  width: fit-content;
  padding: $dim-s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  border-radius: 12px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  transition: all 0.25s ease, box-shadow 0.25s ease;
  box-shadow: none;
  border: none;
  cursor: pointer;
  p {
    color: white;
    padding: 8px;
    font-weight: 300;
  }
}
.border {
  transition: border 0.25s ease-in-out;
  border: solid 2px;
  background-color: transparent;
  &:hover {
    transition: border 0.25s ease-in-out;
  }
}
.jump {
  transition: transform 0.25s ease-in-out;
  transform: translateY(0px);
  &:hover {
    transition: transform 0.25s ease-in-out;
    transform: translateY(-3px);
  }
}
.shadow {
  &:hover {
    box-shadow: 0 5px 20px 0 !important;
  }
}
.icon_button:hover {
  box-shadow: 0 6px 10px -5px rgb(0 0 0 / 10%);
}
.text_button:hover {
  box-shadow: 0 6px 10px -5px rgb(0 0 0 / 30%);
}
.instagram {
  background: radial-gradient(
      circle farthest-corner at 35% 90%,
      #fec564,
      transparent 50%
    ),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(
      #6559ca,
      #bc318f 30%,
      #e33f5f 50%,
      #f77638 70%,
      #fec66d 100%
    );
}
</style>
