export default {
  namespaced: true,
  state() {
    return {
      siteSections: {
        aboutMe: {
          sectionName: "Обо мне",
          sectionPath: "aboutMe",
          imageForCardOnMenu: "aboutMe/card.png",
          altForCardOnMenu: "Taganrog and Rostov",
        },
        diseases: {
          sectionName: "Болезни, которые я лечу",
          sectionPath: "diseases",
          imageForCardOnMenu: "diseases/zBodySmall.png",
          altForCardOnMenu: "Taganrog and Rostov",
        },
        rostov: {
          sectionName: "Прием в Ростове",
          sectionPath: "Rostov",
          imageForCardOnMenu: "rostov/card.jpg",
          altForCardOnMenu:
            "АртроЛига — медицинский ортопедический центр для всей семьи в Ростове-на-Дону",
        },
        taganrog: {
          sectionName: "Прием в Таганроге",
          sectionPath: "Taganrog",
          imageForCardOnMenu: "taganrog/card.jpg",
          altForCardOnMenu: "Медицинский Центр Реабилитации Медиана — Прием травматолога-ортопеда",
        },
      },
    };
  },
};
