/* eslint-disable */
export default {
  namespaced: true,
  state() {
    return {
      clinics: [
        {
          routerPath: 'Rostov',
          name: { fullName: "Реабилитационный центр «Артролига»", shortName: "Артролига" },
          email: "mc-artroliga@yandex.ru",
          weekdays: { days: "Пн – Пт: ", time: "8:00 до 20:00 " },
          weekend: { days: "Сб: ", time: "9:00-15:00" },
          address: { city: "Ростов-на-Дону", street: "ул. Малюгиной, д. 220/84", route: 'https://www.google.com/maps?ll=47.234404,39.724066&z=19&t=m&hl=en&gl=RU&mapclient=embed&saddr&daddr=Artroliga,+Ulitsa+Malyuginoy,+220/84,+Rostov-on-Don,+Rostov+Oblast,+344010&dirflg=d' },
          coordinates: { lat: 47.23440394854407, lng: 39.72406524057559 },
          number: {
            number: ["+79613015171"], //$ Add a "+" at the beginning
            whatsapp: "79613015171", // $ Remove the "+" at the beggining of the number, and write the international code, no "8"
            telegram: "artroligarostov", //! TODO change from mediana to artroliga
          },
          site: {
            url: "https://artroliga.ru/",
            sitePreview: "rostov/sitePreview.png",
          },
          logo: "rostov/logo.jpg",
          plane: "rostov/plane.png",
          pointCoord: [
            { img: "rostov/gallery/Стойка регистратуры.jpg", name: "Стойка регистратуры", description: "", left: "34%", top: "58%" },
            { img: "rostov/gallery/Зал ЛФК.jpg", name: "Зал ЛФК", description: "", left: "30%", top: "10%" },
            { img: "rostov/gallery/Кабинет физиотерапии.jpg", name: "Кабинет физиотерапии", description: "", left: "24%", top: "30%" },
            { img: "rostov/gallery/Кабинет врача Горбатенко А.И..jpg", name: "Кабинет врача Горбатенко А.И.", description: "", left: "72%", top: "45%" },
            { img: "rostov/gallery/Кабинет массажа.jpg", name: "Кабинет массажа", description: "", left: "65%", top: "10%" },
            { img: "rostov/gallery/Процедурный кабинет.jpg", name: "Процедурный кабинет", description: "", left: "68%", top: "70%" },
            // { img: "rostov/gallery/9. Зал ЛФК.jpg", name: "1", description: "", left: "37%", top: "78%" },
            // { img: "rostov/gallery/9. Зал ЛФК.jpg", name: "", description: "", left: "77.5%", top: "30%" },
          ],
        },
        {
          routerPath: 'Taganrog',
          name: { fullName: "Реабилитационный центр «Медиана»", shortName: "Медиана" },
          email: "medianamrc@gmail.com",
          weekdays: { days: "Пн – Пт:", time: "8:00 до 20:00 " },
          weekend: { days: "Сб:", time: "9:00-15:00" },
          address: { city: "Таганрог", street: "ул. Петровская, 116", route: 'https://www.google.com/maps/dir//Ooo+Mtsr+%22Mediana,+Petrovskaya+Ulitsa,+116%D0%9C,+Taganrog,+Rostov+Oblast,+347904/@47.221836,38.919918,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x40e3fd501764e425:0xfed4085927144ed8!2m2!1d38.919918!2d47.221836' },
          coordinates: { lat: 47.22206680166406, lng: 38.91991824211932 },
          number: {
            number: ["+79034025151", "+78634431234", "+79061816117"], //$ Add a "+" at the beginning
            whatsapp: "79034025151", // $ Remove the "+" at the beggining of the number, and write the international code, no "8"
            telegram: "medianataganrog",
          },
          site: {
            url: "https://medianamc.ru/",
            sitePreview: "taganrog/sitePreview.png",
          },
          logo: "taganrog/logo.jpg",
          plane: "taganrog/plane.png",
          pointCoord: [
            //!Images must be horizontally
            { img: "taganrog/gallery/top_medium.jpg", name: 'top_medium', description: '', left: "53.5%", top: "18%" },
            { img: "taganrog/gallery/medium_left.jpg", name: 'medium_left', description: '', left: "16%", top: "50%" },
            { img: "taganrog/gallery/top_left.jpg", name: 'top_left', description: '', left: "21%", top: "37%" },
            { img: "taganrog/gallery/reception.jpg", name: 'reception', description: '', left: "53%", top: "90%" },
            { img: "taganrog/gallery/top_right-last.jpg", name: 'top_right-last', description: '', left: "80%", top: "15%" },
            { img: "taganrog/gallery/gorbatenko_room.jpg", name: 'gorbatenko_room', description: '', left: "62%", top: "12%" },
            { img: "taganrog/gallery/training_room-left_bottom.jpg", name: 'bottom_left', description: '', left: "35%", top: "60.5%" },
            { img: "taganrog/gallery/training_room-right_bottom.jpg", name: 'bottom_left', description: '', left: "63%", top: "60.5%" },
            { img: "taganrog/gallery/training_room-right.jpg", name: 'training_room_right', description: '', left: "73.5%", top: "55%" },
            { img: "taganrog/gallery/training_room-left.jpg", name: 'training_room-left', description: '', left: "38%", top: "48%" },

            // { img: "taganrog/gallery/bottom_left.jpg", name: 'bottom_left', description: '', left: "21%", top: "66.5%" },
            // { img: "taganrog/gallery/top_right-beforeLast.jpg", name: 'top_right-beforeLast', description: '', left: "66.5%", top: "23%" },
            // { img: "taganrog/gallery/clothes_man.jpg", name: 'clothes_man', description: '', left: "38.5%", top: "19%" },
            // { img: "taganrog/gallery/clothes_woman.jpg", name: 'clothes_woman', description: '', left: "31.5%", top: "19%" },
            // { img: "taganrog/gallery/coffee.jpg", name: 'coffee', description: '', left: "18%", top: "15%" },
          ],
        },
      ],
    };
  },
  getters: {
    getClinic(state, getters, globalState, globalGetters) {
      return state.clinics.find(
        (clinic) =>
          clinic.routerPath === globalState.$routeFullPath[1]
      );
    },
  },
};
