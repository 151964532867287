import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { InlineSvgPlugin } from "vue-inline-svg";
import InlineSvg from "vue-inline-svg";
import "./theme.css";
import 'highlight.js/styles/monokai.css';
import Markdown from 'vue3-markdown-it';

createApp(App)
  .use(router)
  .use(store)
  .use(Markdown)
  .use(InlineSvgPlugin)
  .component("inline-svg", InlineSvg)
  .mount("#app");
