import store from "@/store"; // Import the store in the router module
import { createRouter, createWebHistory } from "vue-router";
import Main from "./views/Main.vue";
import Diseases from "./views/Diseases.vue";
import Clinic from "./views/Clinic.vue";
import AboutMe from "./views/AboutMe.vue";

const routes = [
  { path: "/", component: Main },
  {
    path: "/diseases/:generalDisease?/:aboutDisease?",
    component: Diseases,
    meta: {
      title: 'Горбатенко Андрей Иванович - Болезни, которые я лечу',
      metaTags: [
        {
          name: 'description',
          content: 'Я много лет специализируюсь на лечении травм и заболеваний опорно-двигательной системы как консервативным, так и оперативным путем. Цель моей работы – улучшить Ваше здоровье и повысить качество жизни даже в самом тяжелом случае.',
        },
        {
          property: 'og:description',
          content: 'ТЯ много лет специализируюсь на лечении травм и заболеваний опорно-двигательной системы как консервативным, так и оперативным путем. Цель моей работы – улучшить Ваше здоровье и повысить качество жизни даже в самом тяжелом случае.'
        }
      ]
    },
  },
  {
    path: "/Rostov", component: Clinic,
    meta: {
      title: 'АртроЛига — медицинский ортопедический центр для всей семьи в Ростове-на-Дону',
      metaTags: [
        {
          name: 'description',
          content: 'АртроЛига — лечение спины и суставов в Ростове-на-Дону. Лечим боль, восстанавливаем подвижность и симметрию тела.',
        },
        {
          property: 'og:description',
          content: 'АртроЛига — лечение спины и суставов в Ростове-на-Дону. Лечим боль, восстанавливаем подвижность и симметрию тела.'
        }
      ]
    },
  },
  {
    path: "/Taganrog", component: Clinic,
    meta: {
      title: 'Медицинский Центр Реабилитации Медиана — Прием травматолога-ортопеда',
      metaTags: [
        {
          name: 'description',
          content: 'Частный медицинский центр Медиана - Платные медицинские услуги - Цены',
        },
        {
          property: 'og:description',
          content: 'Частный медицинский центр Медиана - Платные медицинские услуги - Цены'
        }
      ]
    },
  },
  {
    path: "/aboutMe",
    component: AboutMe,
    meta: {
      title: 'Андрей Иванович Горбатенко - Taganrog',
      metaTags: [
        {
          name: 'description',
          content: 'Травматолог ортопед высшей категории, к.м.н., доцент кафедры травматологии и ортопедии Ростовского государственного медицинского университета'
        },
        {
          property: 'og:description',
          content: 'Травматолог ортопед высшей категории, к.м.н., доцент кафедры травматологии и ортопедии Ростовского государственного медицинского университета'
        }
      ]
    },
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  scrollBehavior() {
    window.scrollTo(0, 0);
  }
});
store.commit("GET_PATHS_FROM_ROUTER", routes);
//Access to $route from Vuex:
router.beforeEach((to, from, next) => {
  store.commit("SET_ROUTE", to);
  next();
});

// https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});



export default router;
