/* eslint-disable */
var assetsLocation = `diseases/колено/`;
export default {
  location: "Колено",
  group: "Болезни коленных суставов",
  imgPath: "knee.png",
  pointCoord: { left: "160px", top: "470px" },
  propsForCardDoctor: {
    h: 'Болезни коленных суставов',
    p: "Самая крупная, организованная, единая анатомическая опорно-двигательная структура в организме человека, является вместе с тем наиболее сложной из всех сочленений. Это обусловлено тем, что именно в этом месте сочленяются самые длинные рычаги нижней конечности (бедренная кость и кости голени), совершающие наибольший размах движений при ходьбе.",
    visualContent: `${assetsLocation}Болезни коленных суставов.jpg`

  },
  listOfDiseases: [
    {
      name: `Травмы коленного сустава`,
      propsForCardDoctor: {
        // h: `Травмы коленного сустава`,
        p: 
`
##### Травмы коленного сустава
*Стоимость операции, от 50 000 ₽*
`,
        visualContent: `${assetsLocation}Травмы коленного сустава.jpg`

      },
      description:
        `
Травмы коленного сустава, одни из самых часто встречаемых в области травматологии. Удар, падение или просто подвернули ногу или оступились – всё это может привести к травме коленного сустава. 
Травма – это повреждение мягких тканей и костных структур. Коленный сустав – это один из основных органов, у которого всегда большая нагрузка, задействован во многих движениях, поэтому среди повреждений самый распространённый орган.

- Ушиб – закрытая травма мягких тканей;
- Повреждение коленного сустава и связочного аппарат – надрыв, растяжение, разрыв связок (обычно происходит при прокручивании сустава);
- Повреждение мениска: трещины, надрывы и разрывы – серьезное повреждение, при котором чаще всего требуются оперативное вмешательство;
- Вывихи – травмы связанные с расхождением суставных поверхностей, что нарушает функции синовиальной сумки;
- Переломовывихи — сочетанные травмы различной патологии;
- Внутрисуставные переломы;
- Околосуставные переломы;

### Клиническая картина
- Болезненность;
- Отечность, возможно возникновение кровоподтеков
- Контрактура (ограниченное движение в суставе), исключение разрыв связок – появляется патологическая подвижность
- Происходит накопление крови в суставе и суставной сумке – гемартроз
- Нарушение контура сустава – при вывихах и переломовывихах

### Лечение
Лечение подбирается врачом в зависимости от тяжести травмы,  степени повреждения и наличия сопутствующих заболеваний.
#### *Консервативное* 
##### В первые минуты после травмы 
- обездвижить конечность, 
- наложить фиксирующую повязку, 
- холод к месту ушиба. 
##### Коррекция образа жизни 
- ограничение нагрузки на сустав, 
- использование бандажа или ортеза для коленного сустава, 
- витаминизированная диета с ограничением поваренной соли, 
- ограничение курения и алкоголя.
##### Медикаментозное лечение 
- анальгетики, 
- противовоспалительная терапия
- противоотечная терапия, 
- препараты, укрепляющие суставной хрящ. 
##### Физиотерапевтические методы 
- скэнар-терапию, 
- фонофорез, 
- ударно-волновую терапию. 
##### Ортобиологические методы  
- PRP-терапия, 
- SVF-терапия.
#### *Оперативное*
При наличии обширного повреждения мягких тканей, разрыва кровеносных сосудов и кровоизлияния в сустав, некроза поврежденных тканей и позднего обращения к врачу, может потребоваться пункция сустава, в более запущенных случаях – артроскопия или серъезное хирургическое вмешательство.

При несвоевременном обращении к врачу и слишком поздно начатом лечении после травмы может развиться гемартроз, бурсит, артроз коленного сустава, не поддающийся терапии.
      `
    },
    {
      name: `Повреждение менисков, связок, хряща`,
      propsForCardDoctor: {
        // h: `Повреждение менисков, связок, хряща`,
        p: `
##### Повреждение менисков
*Стоимость операции, от 50 000 ₽*
`,
        visualContent: `${assetsLocation}Повреждение менисков, связок, хряща.jpg`

      },
      description:
        `
**Повреждение менисков, связок, хряща** относится к наиболее часто встречающимся травмам нижних конечностей. Основополагающая причина травмирования – удар, падение, нетипичный поворот ноги, резкое разгибание.
Мениски коленного сустава представляют собой хрящевые образования, полулунной формы, которые увеличивают конгруентность суставных поверхностей, выполняют роль амортизаторов в суставе, участвуют в питании гиалинового хряща, а так же стабилизируют коленный сустав. При движениях в коленном суставе мениски сдвигаются в различных направлениях, скользят по плато большеберцовой кости, их форма и натяжении изменяется.

Менисков коленного сустава два:

*   внутренний (медиальный) мениск
*   наружный (латеральный) мениск

По локализации повреждения выделяют несколько типов повреждения менисков: повреждения тела мениска (разрыв по типу «ручки лейки», продольный разрыв, поперечный разрыв, горизонтальный разрыв, лоскутный разрыв, и т.д), повреждение переднего или заднего рога мениска, паракапсуярные повреждения.

Встречаются как изолированные повреждения внутреннего, либо наружного мениска, так и их сочетанные повреждения. Иногда повреждение мениска является частью более сложного повреждения структур коленного сустава.

При подозрении на повреждение связочного аппарата коленного сустава необходимо срочно показаться врачу травматологу-ортопеду или хирургу, чтобы точно определить степень повреждения и объем необходимой медицинской помощи.

### Клиническая картина

**При травме медиального хряща наблюдается**:

В момент травмы:

*   моментальная острая боль;
*   быстрый отек сустава, повышение его температуры;
*   блокада – «заклинивание» колена в слегка согнутом положении;
*   при тяжелых формах повреждений наблюдается гипотрофия мышц,
*   боли при пальпации суставной щели.

При отсутствии медицинской помощи, через 14 дней и позднее:

*   увеличение полости сустава;
*   звук щелчка при разгибании конечности;
*   тупая боль, усиливающаяся при ходьбе вниз;
*   повторяющиеся блокады;
*   воспаления околосуставной сумки.

**При повреждении латерального хряща**:

В первые часы после повреждения:

*   боли при разгибании ноги в колене;
*   воспаление сустава.

В течение 2-4 недель после травмирования:

*   постоянные боли;
*   воспаление капсулы сустава;
*   блокада;
*   усиление болей при разгибании, смещении, сдавливании.

### Лечение

Лечение подбирается врачом в зависимости от тяжести травмы, степени повреждения и наличия сопутствующих заболеваний.

При несвоевременном обращении к врачу или неправильном лечении могут развиться серьезные осложнения. Недолеченный разрыв мениска в 95% случаев ведет к быстрому изнашиванию суставного хряща, частым и сильным болям, развитию остеопороза, хронического синовита, нестабильности суставов, прогрессированию воспалений и появлению кист.

#### Консервативное (при наличии противопоказаний к операции)

##### Коррекция образа жизни

*   ограничение нагрузки на сустав,
*   использование бандажа или ортеза для сустава,
*   витаминизированная диета с ограничением поваренной соли,
*   ограничение курения и алкоголя.

##### Медикаментозное лечение

*   анальгетики,
*   противовоспалительная терапия
*   противоотечная терапия,
*   препараты, укрепляющие суставной хрящ.

##### Физиотерапевтические методы

*   СКЭНАР-терапия,
*   фонофорез с гидрокортизоном,
*   ударно-волновуя терапия.

##### Ортобиологические методы

*   PRP-терапия,
*   SVF-терапия.

#### Оперативное

Современный метод лечения повреждений связок коленного сустава и разрывов менисков – **артроскопия**. Эта малоинвазивная технология позволяет выполнить удаление поврежденных частей хряща, сшивание мениска путем введения инструментов в полость сустава через микроразрезы тканей.

Главные преимущества артроскопии: точное определение типа повреждения и устранение проблемы, быстрое заживление, малая вероятность осложнений, ускоренная реабилитация пациентов, низкое число противопоказаний.

В тяжелых и запущенных случаях показано эндопротезирование.
`
    },
    {
      name: `Остеоартроз (гонартроз)`,
      propsForCardDoctor: {
        // h: `Остеоартроз (гонартроз)`,
        p: 
`

`,
        visualContent: `${assetsLocation}Остеоартроз (гонартроз).jpg`

      },
      description:
        `
  **Остеоартроз коленного сустава -** артроз - это хроническое гетерогенное прогрессирующее заболевание, характеризующееся дегенеративно-дистрофическим поражением хряща, патологическими изменениями суставных тканей (кость, связки, капсула, мениски, синовиальная оболочка) и проявляющееся болевым синдромом, образованием костных шипов (остеофитов) с нарушением функции сустава и качества жизни пациентов.
  ### Клиническая картина
  
  *   боль в области сустава при движении, при надавливании, иногда в покое
  *   отек в области сустава
  *   изменение формы сустава
  *   покраснение кожи и повышение температуры в области сустава
  *   хруст и щелчки при сгибании или разгибании
  *   затруднение при движении
  *   изменение оси конечности (варус или вальгус)
  *   атрофия и слабость мышц бедра
  
  ### Лечение
  
  Лечение подбирается врачом в зависимости от стадии заболевания, степени вовлеченности окружающих тканей и наличия сопутствующих заболеваний.
  
  #### Консервативное
  
  ##### Коррекция образа жизни
  
  *   ограничение нагрузки на сустав
  *   использование бандажа или ортеза для сустава
  *   нормализация массы тела
  *   витаминизированная диета с ограничением поваренной соли
  *   ограничение курения и алкоголя
  
  ##### Медикаментозное лечение
  
  *   анальгетики при болях
  *   топические, пероральные и в/м НПВС
  *   «блокады» с глюкокортикостероидами
  *   хондропротекторы (SYSADOA)
  *   препараты, улучшающие кровоснабжение и питание хряща
  *   миорелаксанты
  *   препараты кальция, витамин D.
  
  ##### Физиотерапевтические методы
  
  *   СКЭНАР-терапия
  *   фонофорез с гидрокортизоном
  *   электрофорез
  *   массаж
  *   МЛТ
  *   фототерапия
  *   ударно-волновая терапия
  *   кинезиотерапия, ЛФК
  
  ##### Ортобиологические методы
  
  *   PRP-терапия
  *   SVF-терапия
  *   BMAC-терапия
  
  #### Оперативное
  
  Показаниями к применению хирургических методов являются: неэффективность консервативной терапии на протяжении 6-12 мес., выраженная деформация суставных поверхностей, 3-4 стадия артроза.
  
  Применяют следующие виды операций:
  
  *   артроскопия - показана при разрушении хряща (хондромаляции) 3-4 стадии, когда необходимо выполнить дебридмент суставных поверхностей с целью удаления нежизнеспособных участков, мешающих нормальной биомеханике сустава.
  *   корригирующая остеотомия - применяют при выраженном искривлении оси конечности и сохранности суставного хряща для перераспределения нагрузки на менее изношенные участки и исправление деформации большеберцовой кости.
  *   эндопротезирование (одномыщелковое, тотальное и др.) - операция по замене суставов на металлокерамический эндопротез.
  *   артродез - создание полностью неподвижного сочленения, конечности придают функционально выгодное положение, колено согнуто под углом в 10-15 градусов, все суставные элементы (хрящи, мениски, связки) удаляют и фиксируют металлическими стяжками, болтами. Это тяжелая, приводящая к инвалидизации пациента операция, в настоящее время применяется очень редко.
`
    },
    {
      name: `Асептический некроз мыщелков бедренной или большеберцовой кости`,
      propsForCardDoctor: {
        // h: `Асептический некроз мыщелков  бедренной или большеберцовой кости`,
        p: `
##### Асептический некроз мыщелков бедренной или большеберцовой кости.
*Стоимость операции, от 45 000 ₽*
`,
        visualContent: `${assetsLocation}Асептический некроз мыщелков  бедренной или большеберцовой кости.jpg`

      },
      description:
        `
**Асептический некроз коленного сустава -** это патологическое состояние, связанное с разрушением (деструкцией) костной ткани, обусловленное длительной перегрузкой суставных поверхностей, нарушением их кровоснабжения и дегенерацией суставного хряща. Болезнь поражает участки субхондральной кости, расположенные непосредственно под суставными хрящами (субхондральная кость). Аваскулярный (асептический) остеонекроз чаще поражает компактное вещество и губчатую кость метаэпифизарной области медиального мыщелка бедренной и большеберцовой костей коленного сустава.
### Клиническая картина

*   боль в области сустава при движении, при надавливании, иногда в покое
*   отек в области сустава
*   изменение формы сустава
*   хруст и щелчки при сгибании или разгибании
*   затруднение при движении
*   хромота, нарушение походки
*   изменение оси конечности (варус или вальгус)

### Лечение

Лечение подбирается врачом в зависимости от стадии заболевания, степени вовлеченности окружающих тканей и наличия сопутствующих заболеваний.

#### Консервативное

##### Коррекция образа жизни

*   ограничение нагрузки на сустав
*   использование бандажа или ортеза для сустава
*   использование трости, ходунков, костылей
*   нормализация массы тела
*   витаминизированная диета с ограничением поваренной соли
*   ограничение курения и алкоголя

##### Медикаментозное лечение

*   анальгетики при болях
*   топические, пероральные и в/м НПВС
*   препараты, улучшающие кровоснабжение и питание хряща
*   препараты кальция, витамин D
*   хондропротекторы
*   «блокады» с глюкокортикостероидами, анестетиками

##### Физиотерапевтические методы

*   СКЭНАР-терапия
*   фонофорез с гидрокортизоном
*   электрофорез
*   массаж бедра
*   МЛТ
*   фототерапия
*   ударно-волновая терапия
*   кинезиотерапия, ЛФК
*   аквагимнастика

##### Ортобиологические методы

*   PRP-терапия
*   SVF-терапия
*   BMAC-терапия

#### Оперативное

Показаниями к применению хирургических методов являются: неэффективность консервативной терапии на протяжении 6-12 мес.

Применяют следующие виды операций:

*   **артроскопия** - показана при разрушении хряща, когда необходимо выполнить дебридмент суставных поверхностей с целью удаления нежизнеспособных участков, мешающих нормальной биомеханике сустава
*   **микрофрактуринг** – в процессе артроскопии формируется несколько отверстий (микропереломов) в месте асептического некрооза, что позволяет запустить процессы репарации и, тем самым, нормализовать кровоснабжение на конкретном участке кости
*   **внутренняя декомпрессия -** формирование в кости нескольких небольших, либо одного крупного канала для уменьшения внутрикостного давления
*   **остеохондропластика -** замена частей головки большеберцовой или бедренной остеохондральным трансплантатом, взятым из здоровой кости, как самого пациента, так и донора или трансплантатом синтетического происхождения.
*   и**мплантация аутологичных хондроцитов -** введение взвеси клеток, содержащих аутологичные хондроциты или их клетки - предшественники, включает 2 этапа: **забор** специалистом небольшого объема хондроцитов (хрящевых клеток), с целью последующего увеличения их объема путем культивирования в лабораторных условиях и **имплантацию** полученного ортобиологического продукта в область асептического некроза
*   **корригирующая остеотомия** – удаление части кости для исправления искривлений оси конечности, перераспределения нагрузки на менее изношенные участки и исправление деформации большеберцовой кости
*   **эндопротезирование** (одномыщелковое, тотальное и др.) - операция по замене суставов на металлокерамический эндопротез
*   **артродез** – создание полностью неподвижного сочленения, конечности придают функционально выгодное положение, колено согнуто под углом в 10-15 градусов, все суставные элементы (хрящи, мениски, связки) удаляют и фиксируют металлическими стяжками, болтами.
`
    },
    {
      name: `Болезнь Осгуда-Шлаттера`,
      propsForCardDoctor: {
        // h: `Болезнь Осгуда-Шлаттера`,
        p: `
##### Болезнь Осгуда-Шлаттера.
*Стоимость операции, от 40 000 ₽*
`,
        visualContent: `${assetsLocation}Болезнь Осгуда-Шлаттера.jpg`

      },
      description:
        `
**Болезнь Осгуда-Шлаттера** - это остеохондропатия бугристости большеберцовой кости. Характеризуется формированием болезненной шишкой в области нижнего полюса коленной чашечки. Болезнь проявляет себя в возрасте полового созревания от 10-18 лет, и чаще всего возникает у детей, занимающиеся спортом.

### Клиническая картина

*   поражение чаще одностороннее
*   боль в области сустава после нагрузки
*   боль в области бугристости при надавливании
*   отек в области бугристости
*   затруднение при движении
*   утомляемость
*   атрофия мышц бедра.

### Лечение

Лечение подбирается врачом в зависимости от стадии заболевания, спортивной активности и возраста пациента.

#### Консервативное

##### Коррекция образа жизни

*   ограничение нагрузки на сустав
*   использование бандажа для надколенника
*   витаминизированная диета

##### Медикаментозное лечение

*   анальгетики при болях
*   топические НПВС
*   препараты, улучшающие кровоснабжение и питание хряща
*   препараты кальция, витамин D

##### Физиотерапевтические методы

*   СКЭНАР-терапия
*   фонофорез с гидрокортизоном
*   электрофорез
*   массаж бедра
*   МЛТ
*   фототерапия
*   ударно-волновая терапия
*   БОС-тренинг

##### Ортобиологические методы

*   PRP-терапия

#### Оперативное

Показаниями к применению хирургических методов являются неэффективность консервативной терапии на протяжении 6-12 мес, длительное волнообразное течение заболевания, значительный косметический дефект.

`
    },
    {
      name: `Болезнь Кёнига`,
      propsForCardDoctor: {
        // h: `Болезнь Кёнига`,
        p: `
##### Болезнь Кёнига.
*Стоимость операции, от 40 000 ₽*
`,
        visualContent: `${assetsLocation}Болезнь Кёнига.jpg`

      },
      description:
        `
**Болезнь Кёнига** **( рассекающий остеохондрит)** — патологическое отделение небольшого участка хряща от прилежащей кости со смещением его в полость сустава. Чаще всего заболевание диагностируется у больных 15—35 лет, как правило, характерно для мужчин.

### Клиническая картина

*	I стадия — дискомфорт в суставе, небольшие боли неопределённой локализации. 
*	II стадия — боли в суставе, появляется синовит 
*	III стадия — отмечается неполное отделение участка хрящя, что может привести к блокаде сустава. С помощью рентгена обнаруживается «суставная мышь».
*	IV стадия — омертвлённое тело полностью отделяется от здорового участка кости, блокада сустава отмечается реже, усиливается боль и нарастает синовит. Рентген даёт возможность выявить внутрисуставное тело
*	боль в области сустава после нагрузки, затем и в покое
*	отек сустава, затруднение при движении
*	утомляемость, атрофия мышц бедра


### Лечение

Лечение подбирается врачом в зависимости от стадии заболевания, спортивной активности и возраста пациента.

#### Консервативное

##### Коррекция образа жизни

*   ограничение нагрузки на сустав
*   исключение спортивных нагрузок
*   использование наколенника, трости, костылей
*   витаминизированная диета

##### Медикаментозное лечение

*   анальгетики при болях
*   топические НПВС
*   хондропротекторы
*   препараты гиалуроновой кислоты
*   препараты, улучшающие кровоснабжение и питание хряща
*   препараты кальция, витамин D

##### Физиотерапевтические методы

*   СКЭНАР-терапия
*   фонофорез с гидрокортизоном
*   электрофорез
*   массаж бедра
*   МЛТ
*   фототерапия
*   ударно-волновая терапия
*   БОС-тренинг

##### Ортобиологические методы

*   PRP-терапия

#### Оперативное

Показаниями к применению хирургических методов являются неэффективность консервативной терапии на протяжении 6-12 мес., прогрессирование заболевания.

При хирургическом лечении методика определяется в зависимости от фазы заболевания - либо отслоившийся кусочек хряща крепится к месту отслойки, либо он полностью удаляется с полным восстановлением хряща при помощи хондропластики, а именно метода туннелизации. Операция выполняется при помощи артроскопии через два разреза до 1 см. Если фрагмент полуподвижный, и дефект кости незначителен, фрагмент прикрепляют на место при помощи рассасывающихся гвоздей или канюлированных винтов (компрессионные типа Герберта).

При большом дефекте кости, его уменьшают с помощью специального аутрансплантата. Свободные внутрисуставные тела удаляют, но при более крупных размерах возможна их фиксация винтом с целью сращения, но если перспектива сращения не велика, то их удаляют с последующим проведением хондропластики.
`
    },
    {
      name: `Киста Бейкера`,
      propsForCardDoctor: {
        // h: `Киста Бейкера`,
        p: `
##### Киста Бейкера
Стоимость операции, от **40 000 ₽**\n
`,
        visualContent: `${assetsLocation}Киста Бейкера.jpg`

      },
      description:
        `
**Киста Бейкера, или бурсит подколенной ямки (грыжа)** – это патологическое жидкостное образование в области подколенной ямки, которое появляется из-за вытекания синовиальной жидкости. Киста встречается преимущественно у взрослых после 40 лет, а также бывает у детей в возрасте до 7 лет, и связана с травмами и заболеваниями коленного сустава, сопровождающимися хроническим воспалением синовиальной оболочки сустава.

### Клиническая картина

*   чувство дискомфорта, напряжения
*   болезненность и сдавливание в области подколенной чашечки;
*   наличие припухлости;
*   отечность коленного сустава
*   локальная гипертермия

### Лечение

Лечение подбирается врачом в зависимости от первичного заболевания и причины образования кисты Бейкера, а также наличия сопутствующих заболеваний.

#### Консервативное

##### Коррекция образа жизни

*   ограничение нагрузки на сустав
*   использование наколенника
*   витаминизированная диета с ограничением соли
*   отказ от курения и алкоголя

##### Медикаментозное лечение

*   топические, пероральные НПВС
*   лечебные «блокады» с глюкокортикостероидами
*   препараты, улучшающие кровоснабжение и питание хряща
*   препараты кальция, витамин D

##### Физиотерапевтические методы

*   СКЭНАР-терапия
*   фонофорез с гидрокортизоном
*   электрофорез
*   массаж бедра
*   МЛТ
*   фототерапия
*   ударно-волновая терапия

#### Оперативное

Пунктирование кисты и введение в ее полость противовоспалительного препарата в условиях процедурного кабинета.

Артроскопическое дренирование кисты и удаление капсулы.

`
    },
    {
      name: `Синовит`,
      propsForCardDoctor: {
        // h: `Синовит`,
        p: `

`,
        visualContent: `${assetsLocation}Синовит.jpg`

      },
      description:
        `
**Синовит** – это воспалительный процесс, сопровождающийся скоплением синовиальной жидкости в коленном суставе, отеком синовиальной оболочки сустава, что существенно снижает его функциональные возможности. Является признаком общей или местной патологии.

### Клиническая картина

*   чувство дискомфорта, напряжения, распирания сустава
*   болезненность при надавливании, движении, затем и в покое
*   отечность коленного сустава
*   припухлость,локальная гипертермия
*   затруднение движения

### Лечение

Лечение подбирается врачом в зависимости от первичного заболевания и причины синовита, а также стадии и наличия сопутствующих заболеваний.

#### Консервативное

##### Коррекция образа жизни

*   ограничение нагрузки на сустав
*   использование наколенника
*   нормализация веса
*   витаминизированная диета с ограничением соли
*   отказ от курения и алкоголя

##### Медикаментозное лечение

*   топические, пероральные НПВС
*   лечебные «блокады» с глюкокортикостероидами
*   антибиотики при наличии септического процесса

##### Физиотерапевтические методы

*   СКЭНАР-терапия
*   фонофорез с гидрокортизоном
*   электрофорез
*   фототерапия

#### Оперативное

Пунктирование сустава, удаление излишков синовиальной жидкости и введение в его полость противовоспалительного препарата в условиях процедурного кабинета.

Диагностическая и лечебная артроскопия, лаваж, при неэффективности - синовиоэктомия.
`
    },
    {
      name: `Вывих надколенника`,
      propsForCardDoctor: {
        // h: `Вывих надколенника`,
        p: "",
        visualContent: `${assetsLocation}Вывих надколенника.jpg`

      },
      description:
        `
**Вывих надколенника** – это патологическое состояние коленной чашечки, при котором происходит ее смещение по отношению к дистальной части бедренной кости, сопровождающееся неприятными и болезненными ощущениями, нарушением опорности и ограничением движений в коленном суставе.

### Клиническая картина

*   визуально определяемое смещение надколенника
*   сильная резкая боль в суставе
*   деформация сустава
*   чувство дискомфорта, напряжения в суставе
*   отечность коленного сустава
*   затруднение движения

### Лечение

Лечение включает в себя, в первую очередь вправление вывиха, фиксацию надколенника.

#### Консервативное

##### Коррекция образа жизни

*   иммобилизация надколенника гипсовой повязкой
*   использование наколенника
*   ограничение нагрузки до 6-ти недель
*   нормализация веса
*   витаминизированная диета с ограничением соли
*   отказ от курения и алкоголя

##### Медикаментозное лечение

*   анальгетики при болях
*   топические, пероральные НПВС
*   лечебные «блокады» с глюкокортикостероидами
*   миорелаксанты
*   препараты, улучшающие кровообращение

##### Физиотерапевтические методы

*   криотерапия
*   СКЭНАР-терапия
*   фонофорез с гидрокортизоном
*   электрофорез
*   кинезиотерапия, ЛФК
*   мануальная терапия

#### Оперативное

При неэффективности консервативного лечения, хронизации процесса, гемартрозе, обширном повреждении и разрыве связок - диагностическая и лечебная артроскопия, лаваж, дебридмент, шов связок.

`
    },
    {
      name: `Пункция кисты Бейкера`,
      minimallyInvasive: true,
      propsForCardDoctor: {
        // h: `Пункция кисты Бейкера`,
        p: "Киста Бейкера, или киста подколенной ямки - это мягкое плотное эластичное опухолевидное образование, которое располагается на задней поверхности коленного сустава, т.е. в подколенной ямке. Объем кисты увеличивается при разгибании коленного сустава, и появляется возможность ее пропальпировать. При сгибании коленного сустава, как правило, объем кисты уменьшается вплоть до полного исчезновения. \n Часто без адекватного лечения могут возникнуть осложнения кисты Бейкера",
        visualContent: `${assetsLocation}Пункция_кисты_Бейкера_0.png`

      },
      description:
`
### Осложнения
- разрыв кисты Бейкера вследствие высокого давления при увеличении опухоли.
- содержимое кисты изливается в межмышечное пространство верхней трети голени,
вызывая при этом боль, припухлость, а также красноту и повышение температуры.
- сдавливание большеберцового нерва, в результате чего возникает онемение,
покалывание или слабость мышц в области коленного сустава;
- сдавливание лимфатических и кровеносных сосудов, в результате чего появляется
боль и отек голени;
- возникновение тромбоза глубоких вен и тромбофлебита голени.
- появление варикозного расширения подкожных вен голени, как следствие
сдавливания вен.

### Диагностика
Надежными и высокоинформативными методами диагностики кисты Бейкера, которые
позволяют определить наличие и размер опухоли, являются ультразвуковое исследование
(УЗИ) и магнитно-резонансная томография (МРТ). Для подтверждения диагноза, в редких
случаях, производят пункцию кисты, после чего исследуют полученную жидкость.


### Лечение
Лечение кисты Бейкера может быть консервативным или оперативным.
Решение о выборе метода лечения принимает лечащий врач, исходя из состояния сустава
и полученных результатов обследований.

![img](/Пункция_кисты_Бейкера_1.png)

![img](/Пункция_кисты_Бейкера_2.png)

Одним из наиболее простых, безопасных и эффективных способов лечения является
выполнение **пункции кисты** при помощи толстой иглы, содержимое удаляется шприцем,
после чего в полость вводят стероидные противовоспалительные препараты. Для более
точного и успешного выполнения процедуры пункция проводится под контролем УЗИ.
`
    },
    {
      name: `Артроскопия при болезни Кёнига`,
      propsForCardDoctor: {
        // h: `Пункция кисты Бейкера`,
        p: "Рассекающий остеохондрит или болезнь Кёнига -  патология костно-хрящевого компонента коленного сустава, характеризующаяся образованием локального некротического очага на суставной поверхности эпифиза.",
        visualContent: `${assetsLocation}artrockopiya_kyoniga.png`

      },
      description:
`
Рассекающий остеохондрит или болезнь Кёнига -  патология костно-хрящевого компонента коленного сустава, характеризующаяся образованием локального некротического очага на суставной поверхности эпифиза. Это редкое заболевание проявляется у подростков, особенно тех, кто занимается активными игровыми видами спорта, часто получает травмы сустава. В результате травмы и последующего за ней некроза, участок поврежденного хряща может  оторваться, и в виде свободного хондромного тела, так называемой, «суставной мыши», перемещаться в полости сустава, препятствуя движениям и вызывая болезненные блокады сустава, тем самым причиняет значительный дискомфорт.

Блокада сустава –  состоянием, когда в определённом положении колено как бы заклинивает и не может разогнуться полностью.

Консервативное лечение неэффективно. Вследствие постоянной травматизации эпифизарных хрящей свободным хондромным телом, может развиться деформирующий остеоартроз. 

### Клинический пример
Пациент Г., 14 лет. Обратился с жалобами на боли в коленном суставе, нарушение функции сустава на фоне периодических блокад сустава,  отсутствие эффекта от консервативного лечения. На компьютерной томографии отмечается: КТ-картина рассекающего остеохондрита (болезнь Кенига 4 ст.), свободное суставное тело коленного сустава. 
Жалобы на боли в коленном суставе, блокады сустава, безуспешность  консервативного лечения  явились показанием к операции.
Проведена диагностическая и лечебная артроскопия коленного сустава, во время диагностического этапа в медиальном боковом завороте обнаружено свободное хондромное тело, по форме напоминающее фасоль. Без технических трудностей свободное хондромное тело удалено из полости сустава.

![img](/artrockopiya_kyoniga1.png)
_Диагностическая и лечебная артроскопия коленного сустава – малоинвазивная процедура продолжительностью в пределах 30 минут_

![img](/artrockopiya_kyoniga2.png)
_На экране монитора  - свободное суставное тело_

Хирург определяет расположение свободного суставного тела, фиксирует его иглой и захватывает инструментом для удаления. 

![img](/artrockopiya_kyoniga3.png)
_Удаленное из сустава хондромное тело отправлено на гистологическое исследование_

Через 2 дня после операции пациент в удовлетворительном состоянии, без осложнений выписан на амбулаторное лечение.

`
    },
    //     {
    //       name: ``,
    //       propsForCardDoctor: {
    //         h: ``,
    //         p: "",
    // visualContent: `${assetsLocation}.jpg`
    //       },
    //       description:
    // `
    // `
    //     },

  ],
};